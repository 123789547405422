import React, { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

// import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
// import SaveOutlined from "@mui/icons-material/SaveOutlined";
import SendOutlined from "@mui/icons-material/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { InputCustom } from "../../components";
import AlertDialog from "../../components/AlertDialog";
import isEmail from "validator/lib/isEmail";
import {
  cvsCollectionRef,
  firestore,
  functions,
  useFirebase,
} from "../../contexts/FirebaseProvider";
import {
  collection,
  doc,
  getDoc,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import formatDate from "../../utils/formatDate";
import { Checkbox, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFunctionsCall } from "@react-query-firebase/functions";
import isMobilePhone from "../../utils/isMobilePhone";
import isURL from "validator/lib/isURL";
import TypewriterComponent from "typewriter-effect";
import Replay from "@mui/icons-material/Replay";
import Check from "@mui/icons-material/Check";
import IcCredits from "../../assets/icon/credits";
import CircularProgress from "@mui/material/CircularProgress";
import replaceAll from "../../utils/replaceAll";
import axios from "axios";

// const greetings = [
//   "Yth. Bapak/Ibu HR",
//   "Yth. Agen Rekruiter",
// ]
const sendTypes = ["email", "wa", "url"];

const initialState = {
  sendType: "email",
  recipient: [],
  companyName: "",
  cv: '',
  // greeting: "Yth. Bapak/Ibu HR",
  message: `To Recruiter,

I know that with my extensive experience I can contribute to your position as a leader in the [enter industry] industry. 

I would welcome the opportunity to ensure [company] maintains its growth and position in the field.

In my previous role as [Job Role] at [Company], I was responsible for [areas of job responsibility]. Furthermore, my track record of [major accomplishment] resulted in [type improvement]. 

I am certain that my qualifications and history of success make me a great fit for your new [Job Role] at [Company].

Please call me to discuss the position and your needs. I am very interested in meeting face to face at your earliest convenience.

Sincerely, 
Thank you again for your interest,
[Your First Name] [Your Last Name]
  `,
};

function ApplicationCreate({ setBottomSheetOpen }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const extension = searchParams.get("extension") ?? false;
  const [open, setOpen] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [txt] = useState("");
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [recipientList, setRecipientList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  // query cv
  const { user, userData } = useFirebase();

  const creditDocRef = doc(firestore, "koin_user", user.uid);
  const creditData = useFirestoreDocumentData(
    ["koin_siswa", user.uid],
    creditDocRef,
    {
      subscribe: true,
    }
  );

  const { mutateAsync: generateCL, isLoading: loadingCL } = useFunctionsCall(
    functions,
    "cv-generateCL"
  );




  let q = query(
    cvsCollectionRef,
    where("tempUID", "==", user?.tempUID),
    where("userId", "==", user?.uid),
    orderBy("updatedAt", "desc")
  );
  const { data: cvList, cvListLoading } = useFirestoreQueryData(
    [
      "cvs",
      {
        tempUID: user?.tempUID,
        userId: user?.uid,
      },
    ],
    q,
    {
      subscribe: true,
      idField: "id"
    }
  );

  // load credential
  const credRef = doc(firestore, "credentials", user?.uid);
  const { data: credential, isLoading: credentialLoading } =
    useFirestoreDocumentData(["credentials", user?.uid], credRef, {
      subscribe: true,
      idField: "id",
    });

  // mutation

  // Mutate
  const applRef = collection(firestore, "users", user?.uid, "applications");
  const { mutate: addApplication, isLoading: addLoading } =
    useFirestoreCollectionMutation(applRef);

  const transformCvList =
    cvList?.map?.((cv, index) => ({
      id: cv.id,
      label: `${cv?.title ?? "Untitled CV "} (${formatDate(
        cv.createdAt?.toDate?.()
      )})`,
    })) ?? [];

  useEffect(() => {
    let query = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      query[param] = value;
    }

    let replaceInitialState = {
      ...initialState,
      subject: query?.email_subject ?? "",
      companyName: query?.company_name ?? "",
      jobTitle: query?.job_title ?? "",
      jobId: query?.jobId ?? ""
    };
    if (query.company_email) {
      replaceInitialState.sendType = "email";
      const company_email = query.company_email?.split?.(",");
      replaceInitialState.recipient = company_email.filter?.((email) =>
        isEmail(email)
      );
      setRecipientList(company_email);
    } else if (query.company_phone) {
      replaceInitialState.sendType = "wa";
      const company_phone = query.company_phone?.split?.(",");
      replaceInitialState.recipient = company_phone;
    } else if (query.company_link) {
      replaceInitialState.sendType = "url";
      const company_link = query.company_link?.split?.(",");
      replaceInitialState.recipient = company_link;
    }
    setForm(replaceInitialState);
  }, [searchParams]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };


  // Get Data
  const refAtt = collection(firestore, 'users', `${user?.uid}`, 'email_attachment')
  const { data: list } = useFirestoreQueryData('email_attachment', refAtt, {
    subscribe: true,
    idField: "id",
  });
  // console.log(list)
  // checkbox attchment
  const [checkAttach, setCheckAttach] = useState(true);

  const handleCheck = (e) => {
    setCheckAttach(e.target.checked)
  }

  const [checkList, setChcekList] = useState([])

  const handeCheckList = (value, i) => (e) => {
    const currentIndex = checkList.indexOf(value);
    const newChecked = [...checkList];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChcekList(newChecked);

  }

  useEffect(() => {
    if (list) {

      setChcekList(list)
    }
  }, [list]);

  // checkbox CL
  const [checkCL, setCheckCL] = useState(true);

  // get data email settings
  const settEmailDoc = doc(firestore, "users", `${user?.uid}`, "settings", "default")
  const { data: item } = useFirestoreDocumentData(
    ['settings', 'default'],
    settEmailDoc,
    {
      subscribe: true,
      idField: "id",
    }
  );

  const handleSubmit = async () => {
    if (credentialLoading || addLoading || cvListLoading) {
      return false;
    }

    if (!credential?.google?.refresh_token) {
      return setOpenAuth(true);
    }
    let findErrors = {};

    if (form.recipient?.length <= 0) {
      findErrors.recipient = "Penerima wajib diisi.";
    }

    if (!form.message) {
      findErrors.message = "Pesan wajib diisi.";
    }


    if (!form.cv?.id && !item?.CVManual) {
      findErrors.cv = "CV wajib diisi.";
    }

    if (form.sendType === "email") {
      if (!form.subject) {
        findErrors.subject = "Subjek wajib diisi.";
      }

      if (
        form.recipient?.length > 0 &&
        form?.recipient?.some((val) => !isEmail(val))
      ) {
        findErrors.recipient = "Email tidak valid.";
      }
    } else if (form.sendType === "wa") {

      if (
        form.recipient?.length > 0 &&
        form?.recipient?.some?.(val => !isMobilePhone(val))
      ) {
        findErrors.recipient = "No WA tidak valid.";
      }
    } else if (form.sendType === "url") {

      if (
        form?.recipient?.length > 0 &&
        form?.recipient?.some?.(val => !isURL(val))
      ) {
        findErrors.recipient = "URL tidak valid.";
      }
    }

    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      // pixel
      await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=karirhack_create&email=${userData?.email}`);

      const wordsToReplace = {
        '{nama_perusahaan}': form?.companyName || '',
        '{posisi_pekerjaan}': form?.jobTitle || ''
      }
      const replaceMess = replaceAll(form?.message, wordsToReplace)

      // console.log(replaceMess)
      addApplication(
        {
          ...form,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          status: 'readytosend',
          message: replaceMess,
          attachment: {
            default: checkList ? checkList : [],
            cl: checkCL
          }
        },
        {
          onSuccess() {

            if (form.sendType === "email") {
              enqueueSnackbar("Lamaran berhasil disimpan dan segera dikirim melalui email.", {
                variant: "success",
              });
            } else if (form.sendType === "wa") {

              enqueueSnackbar("Lamaran berhasil disimpan, silakan kirim manual lamaran melalui Whatsapp web.", {
                variant: "success",
              });
              for (const wa of form.recipient) {
                window.open(`https://wa.me/${wa}?text=${encodeURIComponent(form.message)}`, "_blank");
              }

            } else if (form.sendType === "url") {

              for (const url of form.recipient) {
                window.open(url, "_blank");
              }
            }
            setBottomSheetOpen(false)

            if (extension) {
              window?.parent?.postMessage?.("close:karirai:dialog", "*");
            } else {
              navigate("/application")
            }

          },
        }
      );

    }
    setBottomSheetOpen(false)

  };

  const [aiCL, setAiCL] = useState("");
  const onGenerate = async () => {
    try {
      const { data } = await generateCL({
        cvId: form?.cv?.id,
        jobTitle: form?.jobTitle,
        jobDesc: "",
        jobId: form?.jobId ?? "",
        companyName: form?.companyName,
      });

      // handleChange({
      //   target: {
      //     name: "message",
      //     value: data,
      //   },
      // });
      setAiCL(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAiCl = () => {
    setForm((form) => ({
      ...form,
      message: form.message.concat(form.message ? "\n" + aiCL : aiCL),
    }));
    setAiCL("");
  };








  // console.log(item)

  useEffect(() => {
    async function getDataSett() {
      if (item && form?.sendType === 'email') {
        const docRef = doc(firestore, "cvs", item?.cvId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log(docSnap.data())
          setForm({
            ...form,
            ...item,
            cv: {
              id: item?.cvId,
              label: `${docSnap.data()?.title ?? "Untitled CV "} (${formatDate(
                docSnap.data().createdAt?.toDate?.()
              )})`
            },
            // sendType: 'email',
            // recipient: [],
          })
        }

      }
    }
    getDataSett()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.sendType, item]);

  // const defaultValue = {
  //   id: form?.cv?.id?form?.cv?.id:item?.cvId,
  //   label: form?.cv?.label?form?.cv
  // }

  return (
    <>
      <Box>
        {/* <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          value={form.greeting}
          onChange={(e, value) => {
            handleChange({
              target: {
                name: 'greeting',
                value
              }
            })
          }}
          id="pilih-sapaan"
          options={greetings}
          renderInput={(params) => (
            <InputCustom
              containerRef={params.InputProps.ref}
              placeholder="Sapaan"
              title="Sapaan"
              inputProps={params.inputProps}
              fullWidth
              error={errors?.greeting ? true : false}
              helperText={errors?.greeting}
            />
          )}
        /> */}
        <InputCustom
          id="job-title"
          name="jobTitle"
          title="Posisi / Pekerjaan yang akan dilamar"
          placeholder="Contoh: Staff Admin"
          value={form.jobTitle}
          onChange={handleChange}
          variant="outlined"
          error={errors?.jobTitle ? true : false}
          helperText={errors?.jobTitle}
        />
        <Autocomplete
          fullWidth
          disablePortal
          value={form.sendType}
          onChange={(e, value) => {
            handleChange({
              target: {
                name: "sendType",
                value,
              },
            });
          }}
          id="pilih-send-type"
          options={sendTypes}
          renderInput={(params) => (
            <InputCustom
              containerRef={params.InputProps.ref}
              placeholder="Kirim Via"
              title="Kirim Via"
              inputProps={params.inputProps}
              fullWidth
              error={errors?.sendType ? true : false}
              helperText={errors?.sendType}
            />
          )}
        />

        <Autocomplete
          multiple
          fullWidth
          disablePortal
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          value={form.recipient}
          onChange={(e, value) => {
            handleChange({
              target: {
                name: "recipient",
                value,
              },
            });
          }}
          id="pilih-recipient"
          options={recipientList}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => {
            const { ref, ...restInputProps } = params.InputProps;
            return (
              <InputCustom
                containerRef={ref}
                placeholder={form.sendType === "email" ? "Contoh: hrd@karir.ai" : form.sendType === 'wa' ? "Contoh: 6289653xxxxxxx" : 'Url'}
                title={`${form.sendType === "email" ? "Email" : form.sendType === 'wa' ? "No Whatsapp" : 'Url'} Penerima (Tekan enter untuk menambahkan)`}
                inputProps={params.inputProps}
                fullWidth
                error={errors?.recipient ? true : false}
                helperText={errors?.recipient}
                InputProps={restInputProps}
              />
            );
          }}
        />

        {/* 
        <InputCustom
          id="name"
          placeholder="Nama"
          title="Nama"
          // value={page}
          // onChange={handleChange}
          variant="outlined"
        // SelectProps={{
        //   renderValue: (value) => {
        //     console.log(value)
        //     return value;
        //   }
        // }}
        /> 
        */}

        <InputCustom
          id="companyName"
          name="companyName"
          placeholder="Contoh: PT. Karir Indonesia"
          title="Nama Perusahaan"
          value={form.companyName}
          onChange={handleChange}
          variant="outlined"
          error={errors?.companyName ? true : false}
          helperText={errors?.companyName}
        />
        {
          form.sendType === "email"
          && <InputCustom
            id="subject"
            placeholder="Contoh: Lamaran Staff Admin"
            title="Subjek Email"
            name="subject"
            value={form.subject}
            onChange={handleChange}
            variant="outlined"
            error={errors?.subject ? true : false}
            helperText={errors?.subject}
          />
        }
        <Autocomplete
          fullWidth
          disablePortal
          loading={cvListLoading}
          value={form?.cv}
          // defaultValue={defaultValue}

          onChange={(e, value) => {
            // console.log(value);
            handleChange({
              target: {
                name: "cv",
                value,
              },
            });
          }}
          id="cv"
          options={transformCvList}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          noOptionsText={
            <Button
              onClick={() => { }}
              to="/"
              sx={{ textTransform: "none", padding: 0, color: "inherit" }}
            >
              Kamu belum punya CV, buat CV baru
              <span
                style={{ color: theme.palette.primary.main, marginLeft: 4 }}
              >
                disini
              </span>
            </Button>
          }
          renderInput={(params) => (
            <InputCustom
              containerRef={params.InputProps.ref}
              placeholder={form?.cv?.label ? form?.cv?.label : 'CV Keren'}
              title="Pilih CV"
              inputProps={params.inputProps}
              fullWidth
              error={errors?.cv ? true : false}
              helperText={errors?.cv}
            />
          )}
        />
        <FormControlLabel control={<Checkbox />} label="Gunakan CV manual attachment" />

        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={
              loadingCL ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                <Replay />
              )
            }
            disableElevation
            onClick={onGenerate}
            disabled={
              loadingCL || !(form?.cv?.id && form?.jobTitle && form?.companyName)
            }
            sx={{
              backgroundColor: "#993300",
              borderRadius: 0,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#782800",
              },
              // "&[disabled]": {
              //     color: "#FFF",
              //     backgroundColor: "#993300",
              // },
            }}
          // onClick={handleAi}
          >
            Asisten AI
          </Button>
          <Box
            sx={{
              gap: "5px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "10px",
              color: "#993300",
              fontWeight: "bold",
            }}
          >
            <IcCredits fontSize="10px" />
            <p style={{ fontSize: 14, fontWeight: "bold" }}>
              {creditData?.data?.coin || 0}
            </p>
          </Box>
          <Button
            size="small"
            startIcon={<Check />}
            color="success"
            disableElevation
            disabled={!aiCL}
            sx={{
              marginLeft: "30px",
              borderRadius: 0,
              fontWeight: "bold",
            }}
            onClick={handleAiCl}
          >
            Terapkan
          </Button>
        </Box>

        {aiCL && (
          <div
            style={{
              userSelect: "none",
              color: "#7C8493",
              fontSize: "15px",
            }}
          >
            <TypewriterComponent
              options={{
                strings: aiCL,
                autoStart: true,
                // loop: true,
                cursor: "",
                delay: 50,
              }}
            />
          </div>
        )}

        <InputCustom
          placeholder="Pesan"
          title="Isi Pesan Email / Whatsapp"
          id="message"
          name="message"
          multiline
          value={form.message}
          onChange={handleChange}
          variant="outlined"
          rows={8}
        // generateCoverLetter
        // onGenerate={onGenerate}
        // disableGenerate={
        //   loadingCL || !(form?.cv?.id && form?.jobTitle && form?.companyName)
        // }
        />
        {/* 
        <Autocomplete
          fullWidth
          disablePortal
          // value={lang}
          onChange={(e, value) => {
            // handleChange({
            //     target: {
            //         name: 'lang',
            //         value
            //     }
            // })
          }}
          id="pilih-surat-lamaran"
          options={top100Films}
          renderInput={(params) => (
            <InputCustom
              containerRef={params.InputProps.ref}
              placeholder="Pilih Surat Lamaran"
              title="Pilih Surat Lamaran"
              inputProps={params.inputProps}
              fullWidth
            // error={errors?.level ? true : false}
            // helperText={errors?.schoolName}
            />
          )}
        /> 
        */}

        <FormControlLabel control={<Checkbox defaultChecked checked={checkAttach} onChange={handleCheck} />} label="Attachment File" />
        {checkAttach &&
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginLeft: '20px' }}>
            <ListItem
              key='Cover-Letter'
              // secondaryAction={
              //   <IconButton edge="end" aria-label="comments">
              //     <CommentIcon />
              //   </IconButton>
              // }
              disablePadding
            >
              {/* <ListItemButton role={undefined}
                    onClick={handeCheckList(value?.nama)}
                    dense> */}
              <ListItemIcon>
                <Checkbox
                  size="small"
                  edge="end"
                  name='Cover Letter'
                  checked={checkCL}
                  onChange={(e) => {
                    setCheckCL(e.target.checked)
                  }}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'Cover-Letter' }}
                  defaultChecked
                />
              </ListItemIcon>
              <ListItemText id='Cover-Letter' primary='Cover Letter' />
              {/* </ListItemButton> */}
            </ListItem>
            {list?.map?.((value, i) => {
              // const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value?.id}
                  // secondaryAction={
                  //   <IconButton edge="end" aria-label="comments">
                  //     <CommentIcon />
                  //   </IconButton>
                  // }
                  disablePadding
                >
                  {/* <ListItemButton role={undefined}
                    onClick={handeCheckList(value?.nama)}
                    dense> */}
                  <ListItemIcon>
                    <Checkbox
                      size="small"
                      edge="end"
                      name={value?.nama}
                      checked={checkList.indexOf(value) !== -1}
                      onChange={handeCheckList(value)}
                      disableRipple
                      inputProps={{ 'aria-labelledby': value?.id }}
                      defaultChecked
                    />
                  </ListItemIcon>
                  <ListItemText id={value?.id} primary={value?.nama} />
                  {/* </ListItemButton> */}
                </ListItem>
              );
            })}
          </List>
        }

        <div style={{ display: "flex", gap: "20px" }}>
          {/* <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            startIcon={<SaveOutlined />}
            disableElevation
            sx={{
              textTransform: "none",
              marginTop: "30px",
              fontWeight: "bold",
            }}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              // window?.mixpanel?.track("Lowongan kerja home cover letter Button Clicked", {
              //   userId: user?.uid,
              //   tempUID: user?.tempUID,
              // })
              // history.push("/cover-letter");
              setTxt("Data Telah Disimpan");
              setOpen(true);
            }}
          >
            SIMPAN
          </Button> */}
          <LoadingButton
            loading={addLoading}
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            startIcon={<SendOutlined />}
            disableElevation
            sx={{
              textTransform: "none",
              marginTop: "30px",
              fontWeight: "bold",
              marginBottom: '80px'
            }}
            onClick={handleSubmit}
          >
            SIMPAN DAN KIRIM
          </LoadingButton>
        </div>
      </Box>

      <AlertDialog
        title={txt}
        type="ok"
        open={open}
        handleClose={() => setOpen(false)}
        handleYes={() => {
          setOpen(false);
          navigate("/application");
        }}
      />
      <AlertDialog
        title="Untuk mengirim email atas nama kamu, karir.ai memerlukan autentikasi ke akun email kamu."
        type="ask"
        open={openAuth && !credential?.google?.refresh_token}
        handleClose={() => setOpenAuth(false)}
        handleYes={() => {
          if (extension) {
            window.open(`${window.location.origin}/settings/gmail`, "_blank");
          } else {
            navigate("/settings/gmail");
          }
        }}
      />
    </>
  );
}

export default ApplicationCreate;
