// React
import React from "react";

// MUI
import { Box, FormHelperText, InputBase, Typography, useTheme } from "@mui/material";

// MUI X
import { MobileDatePicker } from "@mui/x-date-pickers";

function DateRangeCustom({
  title,
  onChangeStart,
  valueStart,
  onChangeEnd,
  valueEnd,
  disabledStart,
  disabledEnd,
  disabledFutureStart,
  disabledFutureEnd,
  disabledPastStart,
  disabledPastEnd,
  placeholderStart = 'Start Date',
  placeholderEnd = 'End Date',
  errorStart,
  errorEnd,
  helperTextStart,
  helperTextEnd,
  ...restProps
}) {
  // theme
  const theme = useTheme();
  const error = errorStart || errorEnd;
  return (
    <Box sx={{ marginTop: "20px" }}>
      {/* Title */}
      <Typography
        sx={{
          color: error ? theme.error.red : "textColor.neutral80",
          fontWeight: "600",
          fontSize: "14px",
        }}
      >
        {title}
      </Typography>

      {/* Input */}
      <Box sx={{ marginTop: "6px", display: "flex", flexDirection: "row" }}>
        <MobileDatePicker
          DialogProps={{ hideBackdrop: true }}
          views={['year', 'month']}
          openTo="year"
          inputFormat="MM/yyyy"
          onChange={onChangeStart}
          value={valueStart}
          disabled={disabledStart}
          disableFuture={disabledFutureStart}
          disablePast={disabledPastStart}
          renderInput={({ inputProps }, params) => (
            <Box>
              <Box
                sx={{
                  width: "100%",
                  border: `1px solid ${errorStart ? theme.error.red : theme.neutral.n20}`,
                  padding: "12px 16px",
                }}
              >
                <InputBase
                  inputProps={inputProps}
                  {...params}
                  placeholder={placeholderStart}
                  fullWidth
                  disabled={disabledStart}
                />

              </Box>
              {/* Error Message */}
              {errorStart ? <FormHelperText error>{helperTextStart}</FormHelperText> : null}
            </Box>
          )}

          {...restProps}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: "textColor.neutral80",
              fontWeight: "700",
            }}
          >
            -
          </Typography>
        </Box>
        <MobileDatePicker
          disableFuture={disabledFutureEnd}
          views={['year', 'month']}
          openTo="year"
          disabled={disabledEnd}
          disabledPast={disabledPastEnd}
          inputFormat="MM/yyyy"
          onChange={onChangeEnd}
          value={valueEnd}
          renderInput={({ inputProps }, params) => (
            <Box>
              <Box
                sx={{
                  width: "100%",
                  border: `1px solid ${errorEnd ? theme.error.red : theme.neutral.n20}`,
                  padding: "12px 16px",
                }}
              >
                <InputBase
                  inputProps={inputProps}
                  {...params}
                  placeholder={placeholderEnd}
                  fullWidth
                  disabled={disabledEnd}
                />

              </Box>
              {/* Error Message */}
              {errorEnd ? <FormHelperText error>{helperTextEnd}</FormHelperText> : null}
            </Box>
          )}
          {...restProps}
        />
      </Box>

    </Box>
  );
}

export default DateRangeCustom;
