import { useState } from "react";
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'; import { IconButton } from "@mui/material"
import GuideDialog from "./GuideDialog";

export default function GuideComponent({ content, value }) {
    const [dialogGuide, setDialogGuide] = useState(false);




    return (<>
        <IconButton
            onClick={() => {
                setDialogGuide(true)
            }}
            sx={{
                padding: 0,
                // marginBottom: '5px',
                // float: 'right'
                marginLeft: '5px'
            }}
        >
            <SmartDisplayIcon color="primary" />
            {/* <Lottie animationData={ideaIcon} loop={true} style={{
                    height: 50,
                    width: 40
                }} /> */}

            {/* {View} */}
            {/* <Lottie
                    options={defaultOptions}
                    height={50}
                    width={40}
                /> */}
        </IconButton>
        <GuideDialog
            open={dialogGuide}
            handleClose={() => {
                setDialogGuide(false)
            }}
            content={content}
            idTutorial={value}
        />
    </>)
}