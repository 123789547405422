import { Navigate, useLocation } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseProvider";

export default function RequireAuth({ children }) {

    const { user } = useFirebase();
    const location = useLocation();

    if (!user.uid) {

        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children;
}