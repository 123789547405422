const firebaseConfig = process.env.REACT_APP_ENV === 'dev' ? {
    apiKey: "AIzaSyBbCihMaSG08HJGitr6VJJAd5MBjxAU4yc",
    authDomain: "karirai-dev.firebaseapp.com",
    projectId: "karirai-dev",
    storageBucket: "karirai-dev.appspot.com",
    messagingSenderId: "934338373927",
    appId: "1:934338373927:web:89450c17cd7bcb087c2102",
    measurementId: "G-CW7FEDC74D"
} : {
    apiKey: "AIzaSyCfWPv9PvB8fgxdes2pMiiDvR8l4EZJJXs",
    authDomain: "karirai-prod.firebaseapp.com",
    projectId: "karirai-prod",
    storageBucket: "karirai-prod.appspot.com",
    messagingSenderId: "498302587148",
    appId: "1:498302587148:web:8c7f705e5544fdca15f929",
    measurementId: "G-RFR5VG2B4G"
};;

export default firebaseConfig;