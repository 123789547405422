import { createContext, useContext, useState } from "react";
import BuyDialog from "../components/BuyDialog";
import TopupDialog from "../components/TopUpDialog";

const PaywallContext = createContext();


export function usePaywall() {
    return useContext(PaywallContext);
}


export default function PaywallProvider(props) {
    const [buyDialog, setBuyDialog] = useState({
        open: false,
        name: "",
        price: {},
    });
    const [topupDialog, setTopUpDialog] = useState(false);

    return (
        <PaywallContext.Provider
            value={{
                setBuyDialog,
                setTopUpDialog
            }}
        >
            {props.children}
            <BuyDialog
                {...buyDialog}
                // {...okDialog}
                handleClose={() => {
                    setBuyDialog(() => {
                        return {
                            ...buyDialog,
                            open: false,
                        };
                    });
                }} />
            <TopupDialog
                open={topupDialog}
                handleClose={() => {
                    setTopUpDialog(false)
                }}
            />
        </PaywallContext.Provider>
    );
}