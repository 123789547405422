import React from "react";

import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Search from "@mui/icons-material/Search";

import { InputCustom } from "../../components";

function JobSearch({ history }) {
  const theme = useTheme();

  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
  ];

  return (
    <>
      <Box>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "22px",
            textAlign: "center",
          }}
        >
          Pencari Pekerjaan
        </p>

        <div>
          <Autocomplete
            fullWidth
            disablePortal
            // value={lang}
            onChange={(e, value) => {
              // handleChange({
              //     target: {
              //         name: 'lang',
              //         value
              //     }
              // })
            }}
            id="pilih-level-education"
            options={top100Films}
            renderInput={(params) => (
              <InputCustom
                containerRef={params.InputProps.ref}
                placeholder="Programmer"
                title="Pekerjaan"
                inputProps={params.inputProps}
                fullWidth
                // error={errors?.level ? true : false}
                // helperText={errors?.schoolName}
              />
            )}
          />
          <Autocomplete
            fullWidth
            disablePortal
            // value={lang}
            onChange={(e, value) => {
              // handleChange({
              //     target: {
              //         name: 'lang',
              //         value
              //     }
              // })
            }}
            id="pilih-level-education"
            options={top100Films}
            renderInput={(params) => (
              <InputCustom
                containerRef={params.InputProps.ref}
                placeholder="Bandung"
                title="Lokasi"
                inputProps={params.inputProps}
                fullWidth
                // error={errors?.level ? true : false}
                // helperText={errors?.schoolName}
              />
            )}
          />
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            startIcon={<Search />}
            component={Link}
            to="/application/create"
            disableElevation
            style={{
              textTransform: "none",
              fontWeight: "bold",
              marginTop: "30px",
            }}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              // window?.mixpanel?.track("Lowongan kerja home cover letter Button Clicked", {
              //   userId: user?.uid,
              //   tempUID: user?.tempUID,
              // })
            }}
          >
            Cari
          </Button>
        </div>

        {/* NO DATA */}
        {/* <div
          style={{
            margin: "100px 0px",
          }}
        >
          <p
            style={{
              fontSize: "26px",
              color: theme.palette.neutrals.n60,
              textAlign: "center",
            }}
          >
            Belum ada Data!
          </p>
        </div> */}

        {/* DATA */}
        <div>
          {/* ITEM */}
          <Box
            sx={{
              // "@media(max-width: 630px)": {
              //   backgroundColor: theme.palette.neutrals.nf,
              // },
              backgroundColor: theme.palette.neutrals.n0,
              borderRadius: "8px",
              padding: "16px",
              margin: "30px 0px",
            }}
          >
            <p
              style={{
                marginTop: 0,
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              Bootcamp IT consultant / Programmer (Fresh Graduate) - Bandung
            </p>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              component={Link}
              // to="/application/create"
              disableElevation
              style={{
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "100px",
              }}
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                // window?.mixpanel?.track("Lowongan kerja home cover letter Button Clicked", {
                //   userId: user?.uid,
                //   tempUID: user?.tempUID,
                // })
              }}
            >
              Klik untuk lamar lowongan ini
            </Button>
            <p
              style={{
                fontSize: "15px",
                textAlign: "left",
                color: theme.palette.neutrals.n60,
              }}
            >
              Memahami database SQL dan basic programming. Bersedia untuk
              melakukan training untuk 4 bukan sebelum diterjunkan ke project.
            </p>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              disableElevation
              disableRipple
              style={{
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "100px",
                backgroundColor: theme.palette.primary.main,
                cursor: "default",
              }}
            >
              Sumber: Facebook Group “Lowongan Kerja Bandung”
            </Button>
            <p
              style={{
                marginTop: "20px",
                fontSize: "15px",
                textAlign: "left",
                color: theme.palette.error.main,
              }}
            >
              Status : Email belum dibaca
            </p>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default JobSearch;
