// React
import React, { useEffect, useState } from "react";

// MUI
import {
  Box,
  TextField,
  Menu,
  MenuItem,
  Grid,
  useTheme,
  styled,
  Typography,
  ListItemIcon,
  IconButton,
  Button,
  ListItemText,
} from "@mui/material";

// MUI Icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

// React Router Dom
import { useNavigate, useParams } from "react-router-dom";
import { Article, Download, PictureAsPdf } from "@mui/icons-material";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { functions, useFirebase } from "../contexts/FirebaseProvider";
import { saveAs } from "file-saver";
import trackClickEvent from "../utils/trackClickEvent";
import { usePaywall } from "../contexts/PaywallProvider";
import formatDate from "../utils/formatDate";
// import useStyles from './style';

const StyledTextField = styled(TextField)({
  "& .MuiSelect-select": {
    display: "flex",
    gap: 4,
  },
});

function PageJumper({
  pages,
  basicInfo,
  page,
  nextPage,
  prevPage,
  access,
  setBackdropLoading
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const params = useParams();
  // const classes = useStyles();
  const navigate = useNavigate();
  // firebase and firestore things
  const { user, openSignInDialog } = useFirebase();
  const { mutate: cvDownload, isLoading: downloading } = useFunctionsCall(functions, 'cv-download');
  const { mutateAsync: purchaseCvAccess, isLoading: purchasing } = useFunctionsCall(functions, 'cv-purchaseCvAccess');
  // paywall
  const { setBuyDialog, setTopUpDialog } = usePaywall();

  useEffect(() => {
    setBuyDialog(curr => ({ ...curr, loading: purchasing }))
  }, [setBuyDialog, purchasing])

  useEffect(() => {

    setBackdropLoading(downloading);

  }, [downloading, setBackdropLoading])

  const handleChange = (event) => {
    navigate(`${params.cvId}/${event.target.value}`);
    trackClickEvent("CV Bottom Navigation Option Clicked", { to: `${params.cvId}/${event.target.value}` })
  };

  const handlePrev = () => {
    navigate(`${params.cvId}/${prevPage}`)
    trackClickEvent("CV Prev Navigation Clicked", { to: `${params.cvId}/${prevPage}` })

  }
  const handleNext = () => {
    navigate(`${params.cvId}/${nextPage}`);
    trackClickEvent("CV Next Navigation Clicked", { to: `${params.cvId}/${nextPage}` })
  }
  const handleCloseDownload = () => {
    setAnchorEl(null);
  }
  const handleDownload = (event) => {
    if (!user?.uid) {
      return openSignInDialog();
    }
    setAnchorEl(event.currentTarget);

  }
  const handleDownloadPDF = () => {
    cvDownload({
      cvId: params.cvId,
      type: 'pdf'
    },
      {
        onSuccess(data) {
          fetch(`data:application/pdf;base64,${data.base64Design}`)
            .then(res => res.blob())
            .then((blob) => {
              saveAs(blob, `${basicInfo?.title ?? "cv-download"}.pdf`);
            })

        }
      }
    )

    handleCloseDownload()
    trackClickEvent("Download CV PDF Clicked", {
      cvId: params.cvId,
      type: 'pdf'
    })
  }
  const handleDownloadDocx = () => {
    cvDownload({
      cvId: params.cvId,
      type: 'docx'
    },
      {
        onSuccess(data) {
          fetch(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${data.base64Design}`)
            .then(res => res.blob())
            .then((blob) => {
              saveAs(blob, `${basicInfo?.title ?? "cv-download"}.docx`);
            })
        }
      }
    )


    trackClickEvent("Download CV DOCX Clicked", {
      cvId: params.cvId,
      type: 'docx'
    })
  }

  const handleBuyDownloadDocx = async () => {
    try {
      const purchaseReq = await purchaseCvAccess({
        cvId: params.cvId,
        type: 'docx'
      })

      if (purchaseReq.status === 'success') {
        handleDownloadDocx();
        setBuyDialog({
          open: false,
          name: "",
          price: 0,
        });
      }
    } catch (e) {
      setBuyDialog({
        open: false,
        name: "",
        price: 0,
      });
      setTopUpDialog(true);
    }
  }

  const handleClickDownloadDocx = () => {
    if (!access?.docx) {
      setBuyDialog({ open: true, name: `Akses download file DOCX untuk ${basicInfo?.title ?? "Untitled CV "} (${formatDate(basicInfo.createdAt?.toDate?.())}) selamanya.`, price: 10, callFunction: handleBuyDownloadDocx, loading: purchasing, enable: access?.docx })
    } else {
      handleDownloadDocx();
    }
    handleCloseDownload();
  }


  return (
    <Box
      sx={{
        width: "100%",
        background: "#fff",
        margin: "15px 0",
        padding: "10px",
        position: "fixed",
        left: 0,
        bottom: "-15px",
        borderTop: "solid 1px #ddd",
      }}
    >
      <Grid justifyContent="center" alignItems="center" container maxWidth={680} marginLeft="auto" marginRight="auto">
        <Grid justifyContent="center" item xs sx={{ display: "flex", }}>
          <IconButton disabled={!prevPage} onClick={handlePrev} ><ChevronLeftRoundedIcon /></IconButton>
        </Grid>
        <Grid item xs={8}>

          {page === 'download' ?
            <>
              <Button
                onClick={handleDownload}
                variant="contained"
                fullWidth
                startIcon={<Download />}
                disabled={downloading}
              // endIcon={<KeyboardArrowUp />}
              >
                Download
              </Button>
              <Menu
                id="download-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseDownload}
                MenuListProps={{
                  'aria-labelledby': 'download-button',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleClickDownloadDocx}>
                  <ListItemIcon>
                    <Article />
                  </ListItemIcon>
                  <ListItemText>
                    DOCX Document ({access?.docx ? 'Purchased' : `10 Kredit`})
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDownloadPDF} >
                  <ListItemIcon>
                    <PictureAsPdf />
                  </ListItemIcon>
                  <ListItemText>
                    PDF Document
                  </ListItemText>

                </MenuItem>
              </Menu>
              {/* <Backdrop
                sx={{ color: '#fff', zIndex: 9999 }}
                open={downloading}
              >
                <CircularProgress color="inherit" />
              </Backdrop> */}
            </>
            : <StyledTextField
              id="select-page"
              select
              value={page}
              onChange={handleChange}
              variant="outlined"
              // SelectProps={{
              //   renderValue: (value) => {
              //     console.log(value)
              //     return value;
              //   }
              // }}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "0",
              }}
            >
              {pages.map((option) => {

                let counter = basicInfo?.[option?.counterField] ?? 0;
                if (option?.counterField === 'fullName' && basicInfo?.[option?.counterField]) {
                  counter = 1;
                } else if (option?.counterField === 'summary' && basicInfo?.[option?.counterField]) {
                  counter = 1;
                }
                if (counter > 0) {

                  return <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      display: "flex",
                      // justifyContent: "space-between",
                      color: theme.accent.green,
                    }}
                  >
                    <Typography sx={{ flex: 1 }}>
                      {option.label}
                    </Typography>
                    <CheckCircleOutlineIcon />
                  </MenuItem>
                }

                return (
                  <MenuItem key={option.value} value={option.value} sx={{ display: "flex", justifyContent: "space-between", }}>
                    {option.label}
                  </MenuItem>
                )
              })}
            </StyledTextField>}

        </Grid>
        <Grid justifyContent="center" item xs sx={{ display: "flex", }}>
          <IconButton disabled={!nextPage} onClick={handleNext}><ChevronRightRoundedIcon /></IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageJumper;
