
/* eslint-disable max-len */
export const phones = {
    'am-AM': /^(\+?374)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
    'ar-AE': /^((\+?971))?5[024568]\d{7}$/,
    'ar-BH': /^(\+?973)?(3|6)\d{7}$/,
    'ar-DZ': /^(\+?213)(5|6|7)\d{8}$/,
    'ar-LB': /^(\+?961)?((3|81)\d{6}|7\d{7})$/,
    'ar-EG': /^((\+?20))?1[0125]\d{8}$/,
    'ar-IQ': /^(\+?964)?7[0-9]\d{8}$/,
    'ar-JO': /^(\+?962)?7[789]\d{7}$/,
    'ar-KW': /^(\+?965)[569]\d{7}$/,
    'ar-LY': /^((\+?218))?(9[1-6]\d{7}|[1-8]\d{7,9})$/,
    'ar-MA': /^(?:(?:\+)212)[5-7]\d{8}$/,
    'ar-OM': /^((\+)968)?(9[1-9])\d{6}$/,
    'ar-SA': /^(!?(\+?966))?5\d{8}$/,
    'ar-SY': /^(!?(\+?963))?9\d{8}$/,
    'ar-TN': /^(\+?216)?[2459]\d{7}$/,
    'az-AZ': /^(\+994)(5[015]|7[07]|99)\d{7}$/,
    'bs-BA': /^((((\+)3876)|06))((([0-3]|[5-6])\d{6})|(4\d{7}))$/,
    'be-BY': /^(\+?375)?(24|25|29|33|44)\d{7}$/,
    'bg-BG': /^(\+?359)?8[789]\d{7}$/,
    'bn-BD': /^(\+?880)1[13456789][0-9]{8}$/,
    'ca-AD': /^(\+376)?[346]\d{5}$/,
    'cs-CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'da-DK': /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'de-DE': /^(\+49)?0?[1|3]([0|5][0-45-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
    'de-AT': /^(\+43)\d{1,4}\d{3,12}$/,
    'de-CH': /^(\+41)([1-9])\d{1,9}$/,
    'de-LU': /^(\+352)?((6\d1)\d{6})$/,
    'el-GR': /^(\+?30)?(69\d{8})$/,
    'en-AU': /^(\+?61)4\d{8}$/,
    'en-GB': /^(\+?44)7\d{9}$/,
    'en-GG': /^(\+?44)1481\d{6}$/,
    'en-GH': /^(\+233)(20|50|24|54|27|57|26|56|23|28|55|59)\d{7}$/,
    'en-HK': /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
    'en-MO': /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
    'en-IE': /^(\+?353)8[356789]\d{7}$/,
    'en-IN': /^(\+?91)?[6789]\d{9}$/,
    'en-KE': /^(\+?254)(7|1)\d{8}$/,
    'en-MT': /^(\+?356)?(99|79|77|21|27|22|25)[0-9]{6}$/,
    'en-MU': /^(\+?230)?\d{8}$/,
    'en-NG': /^(\+?234)?[789]\d{9}$/,
    'en-NZ': /^(\+?64)[28]\d{7,9}$/,
    'en-PK': /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
    'en-PH': /^(\+639)\d{9}$/,
    'en-RW': /^(\+?250)?[7]\d{8}$/,
    'en-SG': /^(\+65)?[3689]\d{7}$/,
    'en-SL': /^(\+?232)\d{8}$/,
    'en-TZ': /^(\+?255)?[67]\d{8}$/,
    'en-UG': /^(\+?256)?[7]\d{8}$/,
    'en-US': /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
    'en-ZA': /^(\+?27)\d{9}$/,
    'en-ZM': /^(\+?26)?09[567]\d{7}$/,
    'en-ZW': /^(\+263)[0-9]{9}$/,
    'es-AR': /^\+?549(11|[2368]\d)\d{8}$/,
    'es-BO': /^(\+?591)?(6|7)\d{7}$/,
    'es-CO': /^(\+?57)?3(0(0|1|2|4|5)|1\d|2[0-4]|5(0|1))\d{7}$/,
    'es-CL': /^(\+?56)[2-9]\d{1}\d{7}$/,
    'es-CR': /^(\+506)?[2-8]\d{7}$/,
    'es-DO': /^(\+?1)?8[024]9\d{7}$/,
    'es-HN': /^(\+?504)?[9|8]\d{7}$/,
    'es-EC': /^(\+?593)([2-7]|9[2-9])\d{7}$/,
    'es-ES': /^(\+?34)?[6|7]\d{8}$/,
    'es-PE': /^(\+?51)?9\d{8}$/,
    'es-MX': /^(\+?52)?(1|01)?\d{10,11}$/,
    'es-PA': /^(\+?507)\d{7,8}$/,
    'es-PY': /^(\+?595)9[9876]\d{7}$/,
    'es-UY': /^(\+598)9[1-9][\d]{6}$/,
    'et-EE': /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
    'fa-IR': /^(\+?98[-\s]?|0)9[0-39]\d[-\s]?\d{3}[-\s]?\d{4}$/,
    'fi-FI': /^(\+?358)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
    'fj-FJ': /^(\+?679)?\s?\d{3}\s?\d{4}$/,
    'fo-FO': /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'fr-FR': /^(\+?33)[67]\d{8}$/,
    'fr-GF': /^(\+?594)[67]\d{8}$/,
    'fr-GP': /^(\+?590)[67]\d{8}$/,
    'fr-MQ': /^(\+?596)[67]\d{8}$/,
    'fr-RE': /^(\+?262)[67]\d{8}$/,
    'he-IL': /^(\+972)([23489]|5[012345689]|77)[1-9]\d{6}$/,
    'hu-HU': /^(\+?36)(20|30|70)\d{7}$/,
    'id-ID': /^(\+?62)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
    'it-IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
    'it-SM': /^((\+378)|(0549)|(\+390549)|(\+3780549))?6\d{5,9}$/,
    'ja-JP': /^(\+81[ -]?(\(0\))?|0)[6789]0[ -]?\d{4}[ -]?\d{4}$/,
    'ka-GE': /^(\+?995)?(5|79)\d{7}$/,
    'kk-KZ': /^(\+?7|8)?7\d{9}$/,
    'kl-GL': /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'ko-KR': /^((\+?82)[ -]?)?0?1([0|1|6|7|8|9]{1})[ -]?\d{3,4}[ -]?\d{4}$/,
    'lt-LT': /^(\+370|8)\d{8}$/,
    'lv-LV': /^(\+?371)2\d{7}$/,
    'ms-MY': /^(\+?6?01){1}(([0145]{1}(-|\s)?\d{7,8})|([236789]{1}(\s|-)?\d{7}))$/,
    'mz-MZ': /^(\+?258)?8[234567]\d{7}$/,
    'nb-NO': /^(\+?47)?[49]\d{7}$/,
    'ne-NP': /^(\+?977)?9[78]\d{8}$/,
    'nl-BE': /^(\+?32)4?\d{8}$/,
    'nl-NL': /^(((\+)?31\(0\))|((\+|00)?31)|0)6{1}\d{8}$/,
    'nn-NO': /^(\+?47)?[49]\d{7}$/,
    'pl-PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
    'pt-BR': /^((\+?55 ?[1-9]{2} ?)|(\+?55 ?\([1-9]{2}\) ?)|(0[1-9]{2} ?)|(\([1-9]{2}\) ?)|([1-9]{2} ?))((\d{4}-?\d{4})|(9[2-9]{1}\d{3}-?\d{4}))$/,
    'pt-PT': /^(\+?351)?9[1236]\d{7}$/,
    'pt-AO': /^(\+244)\d{9}$/,
    'ro-RO': /^(\+?4)\s?7\d{2}(\/|\s|\.|-)?\d{3}(\s|\.|-)?\d{3}$/,
    'ru-RU': /^(\+?7|8)?9\d{9}$/,
    'si-LK': /^(?:\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
    'sl-SI': /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
    'sk-SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'sq-AL': /^(\+355)6[789]\d{6}$/,
    'sr-RS': /^(\+3816|06)[- \d]{5,9}$/,
    'sv-SE': /^(\+?46)[\s-]?7[\s-]?[02369]([\s-]?\d){7}$/,
    'th-TH': /^(\+66|66)\d{9}$/,
    'tr-TR': /^(\+?90)?5\d{9}$/,
    'uk-UA': /^(\+?38|8)?0\d{9}$/,
    'uz-UZ': /^(\+?998)?(6[125-79]|7[1-69]|88|9\d)\d{7}$/,
    'vi-VN': /^(\+?84)((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-9]))|(9([0-9])))([0-9]{7})$/,
    'zh-CN': /^((\+)86)?1([3456789][0-9]|4[579]|6[67]|7[01235678]|9[012356789])[0-9]{8}$/,
    'zh-TW': /^(\+?886-?|0)?9\d{8}$/,
};
/* eslint-enable max-len */

// aliases
phones['en-CA'] = phones['en-US'];
phones['fr-CA'] = phones['en-CA'];
phones['fr-BE'] = phones['nl-BE'];
phones['zh-HK'] = phones['en-HK'];
phones['zh-MO'] = phones['en-MO'];
phones['ga-IE'] = phones['en-IE'];
phones['fr-CH'] = phones['de-CH'];
phones['it-CH'] = phones['fr-CH'];

export default function isMobilePhone(str, locale, options) {

    if (options && options.strictMode && !str.startsWith('+')) {
        return false;
    }
    if (Array.isArray(locale)) {
        return locale.some((key) => {
            // https://github.com/gotwarlost/istanbul/blob/master/ignoring-code-for-coverage.md#ignoring-code-for-coverage-purposes
            // istanbul ignore else
            if (phones.hasOwnProperty(key)) {
                const phone = phones[key];
                if (phone.test(str)) {
                    return true;
                }
            }
            return false;
        });
    } else if (locale in phones) {
        return phones[locale].test(str);
        // alias falsey locale as 'any'
    } else if (!locale || locale === 'any') {
        for (const key in phones) {
            // istanbul ignore else
            if (phones.hasOwnProperty(key)) {
                const phone = phones[key];
                if (phone.test(str)) {
                    return true;
                }
            }
        }
        return false;
    }
    throw new Error(`Invalid locale '${locale}'`);
}

export const locales = Object.keys(phones);