import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function IcCredits(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M8.97647 6.82927C9.46427 6.82927 9.85452 7.02439 10.2448 7.21952C10.4399 7.41464 10.7326 7.41464 10.9277 7.41464C11.2204 7.41464 11.4155 7.21952 11.6106 7.02439C11.8057 6.82927 11.8057 6.53659 11.8057 6.34147C11.8057 6.14634 11.6106 5.85366 11.4155 5.65854C10.7326 5.2683 9.85452 4.87805 8.97647 4.87805C6.73256 4.87805 4.87891 6.82927 4.87891 9.17073C4.87891 11.5122 6.73256 13.4634 8.97647 13.4634C9.85452 13.4634 10.7326 13.1707 11.4155 12.5854C11.6106 12.3902 11.7082 12.1951 11.8057 11.9024C11.8057 11.6098 11.8057 11.4146 11.6106 11.2195C11.3179 10.8293 10.635 10.7317 10.2448 11.0244C9.85452 11.3171 9.46427 11.5122 8.97647 11.5122C7.80574 11.5122 6.83013 10.439 6.83013 9.17073C6.83013 7.90244 7.80574 6.82927 8.97647 6.82927Z"
      />
      <path
        d="M18.6341 4.29268C17.8537 3.12195 16.7805 2.14634 15.4146 1.46341C13.8537 0.585366 11.8049 0.097561 9.36585 0C9.17073 0 8.97561 0 8.78049 0H8.58537H8.39024H8.09756C3.5122 0.390244 0 4.39024 0 9.17073C0 13.9512 3.5122 17.9512 8.09756 18.3415H8.58537H8.78049C8.97561 18.3415 9.17073 18.3415 9.36585 18.3415C11.8049 18.2439 13.7561 17.7561 15.4146 16.878C16.7805 16.1951 17.8537 15.2195 18.6341 14.0488C19.5122 12.6829 20 11.0244 20 9.17073C20 7.31707 19.5122 5.56098 18.6341 4.29268ZM17.561 9.17073C17.561 7.41463 17.0732 5.7561 16.1951 4.29268C16.4878 4.58537 16.7805 4.97561 17.0732 5.26829C17.7561 6.34146 18.0488 7.60976 18.0488 9.17073C18.0488 10.7317 17.7561 12 17.0732 13.0732C16.878 13.4634 16.5854 13.7561 16.1951 14.0488C17.0732 12.5854 17.561 10.9268 17.561 9.17073ZM15.6098 9.17073C15.6098 12.9756 12.878 16.0976 9.26829 16.3902C9.07317 16.3902 8.78049 16.3902 8.58537 16.3902C4.97561 16.2927 1.95122 13.0732 1.95122 9.17073C1.95122 5.26829 4.97561 2.04878 8.58537 1.95122C8.78049 1.95122 9.07317 1.95122 9.26829 1.95122C12.7805 2.2439 15.6098 5.36585 15.6098 9.17073Z"
      />
    </SvgIcon>
  );
}
