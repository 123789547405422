import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
// import { MailOutline, Search, SendOutlined } from "@mui/icons-material";
import { SendOutlined } from "@mui/icons-material";
// import { useFunctionsCall } from "@react-query-firebase/functions";

// Styled Component
const MenuBtn = styled(Button)(() => ({
  padding: "25px",
  justifyContent: "flex-start",
  borderRadius: "8px",
}));
const Name = styled("p")(({ theme }) => ({
  margin: 0,
  fontSize: "23px",
  textAlign: "left",
  textTransform: "none",
  color: theme.palette.neutrals.nf,
  fontWeight: "bold",
}));

export default function HomePage() {
  return (
    <>
      <Paper elevation={0}>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} sx={{ padding: 0 }}>
              {/* <Grid item xs={12}>
                <MenuBtn
                  fullWidth
                  color="primary"
                  component={Link}
                  to="/job-search"
                  variant="contained"
                  disableElevation
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <Search fontSize="large" />
                    <Name>Cari Pekerjaan</Name>
                  </div>
                </MenuBtn>
              </Grid>
              <Grid item xs={12}>
                <MenuBtn
                  fullWidth
                  color="primary"
                  component={Link}
                  to="/cover-letter"
                  variant="contained"
                  disableElevation
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <MailOutline fontSize="large" />
                    <Name>Cover Letter</Name>
                  </div>
                </MenuBtn>
              </Grid> */}
              <Grid item xs={12}>
                <MenuBtn
                  fullWidth
                  color="primary"
                  component={Link}
                  to="/application"
                  variant="contained"
                  disableElevation
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <SendOutlined fontSize="large" />
                    <Name>Kirim Lamaran</Name>
                  </div>
                </MenuBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
