import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { TourProvider, useTour } from "@reactour/tour";
import { RouterProvider, } from "react-router-dom";
import router from "./router";
import { firestore, useFirebase } from "./contexts/FirebaseProvider";

export default function TourGuide() {
    const { user, userData, } = useFirebase();
    // const location = useLocation();
    // const path = window.location.pathname.split('/')
    // const pathFixed = path.join('')
    const [step, setStep] = useState(0);
    // get steps
    const tourRef = doc(firestore, "tour", `karirhack`);

    const { data: steps, isLoading: loading, } = useFirestoreDocumentData(
        ["tour", `karirhack`],
        tourRef,
        {
            subscribe: true,
            idField: 'id'
        }
    );

    const { setIsOpen } = useTour()

    // const field = `tutor_karirhack_${pathFixed}`


    useEffect(() => {
        if (userData?.karirhack_tour_step) {
            console.log('true')
            setIsOpen(true)

            setTimeout(() => {
                setStep(userData?.karirhack_tour_step)

            }, 500)

        }
    }, [userData?.karirhack_tour_step, setIsOpen,])
    const styles = {
        maskWrapper: (base) => ({
            ...base,
            color: '#F26722',
        }),
        highlightedArea: (base, { x, y }) => ({
            ...base,
            x: x + 10,
            y: y + 10,
        }),
        badge: (base) => ({ ...base, color: 'blue' }),

    }

    // const redirect = useNavigate();

    const handleNextStep = async (step, path) => {
        try {
            console.log(step)
            await setDoc(doc(firestore, 'users', user?.uid), {
                karirhack_tour_step: step
            }, { merge: true });

            if (path && path !== window.location.pathname) {
                window.location.pathname = path;
            }
        } catch (e) {
            console.log(e)
        }
    }

    const setCurrentSteps = (step) => {
        console.log(step)
        switch (step) {
            case 0:
                handleNextStep(step, '/')
                break;
            case 1:
                handleNextStep(step, '/');
                break;
            case 2:
                handleNextStep(step, "/settings/gmail");
                break;
            case 3:
                handleNextStep(step, "/settings/gmail");
                break;
            case 4:
                handleNextStep(step, "/settings/gmail");
                break;
            case 5:
                handleNextStep(step, "/settings/gmail");
                break;
            case 6:
                handleNextStep(step, "/settings/gmail");
                break;
            case 7:
                handleNextStep(step, "/settings/gmail");
                break;
            case 8:
                handleNextStep(step, "/settings/gmail");
                break;
            default:
                break;
        }
        setStep(step);
    };
    // console.log(setIsOpen)
    // if (loading) {
    //     return 'loading...'
    // }

    return (
        !loading && steps?.steps && <TourProvider
            styles={styles}
            steps={steps?.steps}
            currentStep={step}
            setCurrentStep={setCurrentSteps}
            disableKeyboardNavigation
            // isOpen={isTourOpen}
            // onClickClose={() => {
            //     console.log('close')
            //     setIsOpen(false)
            // }}
            // accentColor="#F26722"
            scrollSmooth
            // disableInteraction
            // onClickHighlighted={(e) => {
            //     console.log('No interaction at all')
            //     e.stopPropagation()
            // }}
            onClickMask={() => {
                console.log('No interaction at all')

            }}
            disableDotsNavigation
            showNavigation={step > 1 ? true : false}
            showDots={false}
            // showPrevNextButtons={step > 2 ? true : false}
            prevButton={({ Button }) => {
                // if (step  3) {
                return <Button hideArrow={true}></Button>
                // }
                // return 
            }}
            nextButton={({ Button, currentStep, stepsLength, setCurrentStep }) => {
                const last = currentStep === stepsLength - 1

                return <Button hideArrow={last ? true : false} onClick={async () => {

                    if (last) {
                        await setDoc(doc(firestore, 'users', user?.uid), {
                            karirhack_tour_step: 'completed'
                        }, { merge: true });
                    } else {
                        setCurrentStep(currentStep + 1)
                    }
                }}>
                    {last ? 'Tutup' : null}
                </Button>
            }}
            showCloseButton={false}
        // startAt={step}
        // nextButton={({
        //     Button,
        //     currentStep,
        //     stepsLength,
        //     setIsOpen,
        //     setCurrentStep,
        //     steps,
        // }) => {
        //     const last = currentStep === stepsLength - 1
        //     return (
        //         <Button
        //             onClick={() => {
        //                 if (last) {
        //                     setIsOpen(false)
        //                 } else {
        //                     setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
        //                 }
        //             }}
        //         >
        //             {last ? 'Close!' : null}
        //         </Button>
        //     )
        // }}

        // disableInteraction={true} 
        >
            <RouterProvider router={router} />
        </TourProvider>
    )
}