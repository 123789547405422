import { useAuthSignInWithCredential } from "@react-query-firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppLoading from "../../components/AppLoading";
import { auth, firestore, functions, useFirebase } from "../../contexts/FirebaseProvider";

export default function AuthHandler() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { mutate } = useAuthSignInWithCredential(auth);
    const { user } = useFirebase();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        let query = {}
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            query[param] = value;
        }

        if (query.code) {
            const validateCode = httpsCallable(functions, "google-validateCode");
            validateCode({
                code: query.code,
                redirect_uri: `${window.location.origin}/__/auth/handler`
            }).then((res) => {
                return setDoc(doc(firestore, `credentials/${user?.uid}`), {
                    google: {
                        ...res.data,
                        // email: data.user.email,
                        is_extension: false,
                        redirect_uri: `${window.location.origin}/__/auth/handler`
                    }
                }, { merge: true })
            }).then(() => {
                enqueueSnackbar("Akses kirim email berhasil ditambahkan.", { variant: 'success' })
                navigate(query.state || "/");
            }).catch(e => {
                console.log(e)
            })
        }
    }, [searchParams, user?.uid, mutate, navigate, enqueueSnackbar]);

    return <AppLoading />


}