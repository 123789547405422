import localforage from "localforage";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseProvider";

export default function usePersistQueryString() {
    const [searchParams] = useSearchParams();
    const { setLockedParamsToFirestore } = useFirebase();
    useEffect(() => {
        let params = {}
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            if (param.startsWith("utm_") || param.startsWith("ref")) {
                params[param] = value;
            }
        }

        localforage.getItem('lockedParams').then((currVal) => {
            if (!currVal) {
                localforage.setItem('lockedParams', params).then(() => {
                    setLockedParamsToFirestore?.();
                });
            } else {
                const { ref_id, utm_source, ...restParams } = params;
                let updatedParams = { ...currVal, ...restParams };

                // dont update ref_id if already there
                if (!currVal.ref_id && ref_id) {
                    updatedParams['ref_id'] = ref_id;
                }
                // dont update utm_source if already there
                if (!currVal.utm_source && utm_source) {
                    updatedParams['utm_source'] = utm_source;
                }
                localforage.setItem('lockedParams', updatedParams).then(() => {
                    setLockedParamsToFirestore?.();
                });

            }

        })

    }, [searchParams, setLockedParamsToFirestore])

}