// React Router Dom
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";

// Pages
import // EducationPage,
  // HomePage,
  // ProjectPage,
  // WorkExperiencePage,
  // CVLayout,
  // BasicInfoPage,
  // SkillPage,
  // OrganizationPage,
  // AwardsPage,
  "./pages";

// App
import App from "./App";
// import CoursePage from "./pages/course";
// import CertificationPage from "./pages/certification";
import LoginByToken from "./pages/login/token";
// import SummaryPage from "./pages/summary";
// import DownloadPage from "./pages/download";
// import OptimizerPage from "./pages/optimizer";
import CoverLetterList from "./pages/cover-letter";
import CoverLetterCreate from "./pages/cover-letter/create";
import ApplicationList from "./pages/application";
import ApplicationCreate from "./pages/application/create";
import LoginByCredentials from "./pages/login/credentials";
import AuthHandler from "./pages/auth/handler";
import GmailSetting from "./pages/settings/gmail";
import RequireAuth from "./components/RequireAuth";
import JobSearch from "./pages/job-search";
import LoginPage from "./pages/login";
import ApplicationEdit from "./pages/application/edit";
import ApplicationCreateAuto from "./pages/application/createAuto";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      {/* <Route index element={<HomePage />} /> */}
      <Route index element={<Navigate to={"/application"} />} />
      <Route path="login-by-token/:token" element={<LoginByToken />} />
      <Route path="login-by-credential" element={<LoginByCredentials />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="__/auth/handler" element={<AuthHandler />} />
      {/* <Route path="optimizer" element={<OptimizerPage />} /> */}
      <Route path="cover-letter" element={<CoverLetterList />} />
      <Route path="cover-letter/create" element={<CoverLetterCreate />} />
      <Route
        path="application"
        element={
          <RequireAuth>
            <ApplicationList />
          </RequireAuth>
        }
      />
      <Route
        path="application/create"
        element={
          <RequireAuth>
            <ApplicationCreate />
          </RequireAuth>
        }
      />
      <Route
        path="application/createAuto"
        element={
          <RequireAuth>
            <ApplicationCreateAuto />
          </RequireAuth>
        }
      />
      <Route
        path="application/edit/:id"
        element={
          <RequireAuth>
            <ApplicationEdit />
          </RequireAuth>
        }
      />
      <Route
        path="settings/gmail"
        element={
          <RequireAuth>
            <GmailSetting />
          </RequireAuth>
        }
      />
      <Route path="job-search" element={<JobSearch />} />
      {/* <Route path="cv" element={<CVLayout />}>
        <Route
          path=":cvId/basic-info"
          element={<BasicInfoPage />}
          handle={{ getJumperValue: () => "basic-info", next: () => 'work-experience' }}
        />
        <Route
          path=":cvId/work-experience"
          element={<WorkExperiencePage />}
          handle={{ getJumperValue: () => "work-experience", next: () => 'project', prev: () => 'basic-info' }}
        />
        <Route
          path=":cvId/project"
          element={<ProjectPage />}
          handle={{ getJumperValue: () => "project", next: () => 'education', prev: () => 'work-experience' }}
        />
        <Route
          path=":cvId/education"
          element={<EducationPage />}
          handle={{ getJumperValue: () => "education", next: () => 'organization', prev: () => 'project' }}
        />
        <Route
          path=":cvId/certification"
          element={<CertificationPage />}
          handle={{ getJumperValue: () => "certification", next: () => 'course', prev: () => 'education' }}
        />
        <Route
          path=":cvId/course"
          element={<CoursePage />}
          handle={{ getJumperValue: () => "course", next: () => 'organization', prev: () => 'certification' }}
        />

        <Route
          path=":cvId/organization"
          element={<OrganizationPage />}
          handle={{ getJumperValue: () => "organization", next: () => 'skill', prev: () => 'course' }}
        />
        <Route
          path=":cvId/skill"
          element={<SkillPage />}
          handle={{ getJumperValue: () => "skill", next: () => 'award', prev: () => 'organization' }}
        />

        <Route
          path=":cvId/award"
          element={<AwardsPage />}
          handle={{ getJumperValue: () => "award", next: () => 'summary', prev: () => 'skill' }}
        />

        <Route
          path=":cvId/summary"
          element={<SummaryPage />}
          handle={{ getJumperValue: () => "summary", next: () => 'download', prev: () => 'award' }}
        />

        <Route
          path=":cvId/download"
          element={<DownloadPage />}
          handle={{ getJumperValue: () => "download", prev: () => 'summary' }}
        />
      </Route> */}
    </Route>
  )
);

export default router;
