import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogContent, TextField } from "@mui/material";
import GoogleLogo from "../assets/icon/google";
import { Box } from "@mui/system";
import { Email } from "@mui/icons-material";

function SignInDialog(props) {
  const {
    handleSignInWithGoogle,
    isEmailLogin,
    form,
    errors,
    handleChange,
    handleSignInWithEmailPassword,
    loading,
    onLoginWithEmail,
    ...restProps
  } = props;

  return (
    <Dialog maxWidth="xs" {...restProps}>
      <DialogTitle fontWeight="bold">Sign in to Karir.ai</DialogTitle>
      <DialogContent>
        {isEmailLogin ? (
          <Box>
            <TextField
              name="email"
              label="Email"
              size="small"
              variant="outlined"
              fullWidth
              value={form.email}
              onChange={handleChange}
              error={errors?.email ? true : false}
              helperText={errors?.email}
              disabled={loading}
            />
            <TextField
              name="password"
              label="Password"
              size="small"
              variant="outlined"
              fullWidth
              sx={{ marginTop: "8px" }}
              value={form.password}
              onChange={handleChange}
              error={errors?.password ? true : false}
              helperText={errors?.password}
              type="password"
              disabled={loading}
            />
            <Button
              variant="contained"
              size="small"
              fullWidth
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                marginTop: "12px",
              }}
              onClick={handleSignInWithEmailPassword}
              disabled={loading}
            >
              Sign In
            </Button>
          </Box>
        ) : (
          <>
            <Box>
              <Button
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  color: "#fff",
                  marginTop: "10px",
                  "&: hover": {
                    backgroundColor: "#eee",
                  },
                  "& .MuiButton-startIcon": {
                    marginLeft: "-15px",
                    marginRight: "20px",
                  },
                }}
                size="large"
                variant="contained"
                color="primary"
                startIcon={<Email sx={{ fontSize: "18px !important" }} />}
                onClick={onLoginWithEmail}
                fullWidth
              >
                Sign in with Email
              </Button>
            </Box>
            <Box>
              <Button
                sx={{
                  backgroundColor: "#fff",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  color: "rgba(0,0,0,0.54)",
                  marginTop: "10px",
                  "&: hover": {
                    backgroundColor: "#eee",
                  },
                  "& .MuiButton-startIcon": {
                    marginLeft: "0px",
                    marginRight: "20px",
                  },
                }}
                size="large"
                variant="contained"
                startIcon={<GoogleLogo sx={{ fontSize: "18px !important" }} />}
                onClick={handleSignInWithGoogle}
                fullWidth
              >
                Sign in with Google
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

SignInDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SignInDialog;
