import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Visibility from "@mui/icons-material/Visibility";

import {
  applicationCollection,
  firestore,
  functions,
  useFirebase,
} from "../../contexts/FirebaseProvider";


import { useFirestoreDocumentData, useFirestoreQueryData } from "@react-query-firebase/firestore";
import formatDate, { formatFromNow, TimestampToDate } from "../../utils/formatDate";
import { TabContext, TabList, } from "@mui/lab";
import { Card, CardActionArea, IconButton, Tab, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { deleteDoc, doc, query, where } from "firebase/firestore";
import { AttachFile, Delete, Edit, Refresh, UploadFile } from "@mui/icons-material";
import { useFunctionsCall } from "@react-query-firebase/functions";
import Grafik from "./grafik";
import { useSnackbar } from "notistack";
// import tutorialBanner from '../../assets/icon/banner-tutorial-Karirhack.png'
import GuideDialog from "../../components/GuideDialog";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { BottomSheet } from "react-spring-bottom-sheet";
import ApplicationCreate from "./create";
import { Container } from "@mui/system";
import ApplicationCreateAuto from "./createAuto";
import Upload from "./upload";
// icon
import MoneyIcon from "../../assets/icon/Money.svg"
import GlobalIcon from "../../assets/icon/Global-Business.svg"
import LikeIcon from "../../assets/icon/Like.svg"
// import HistoryIcon from "../../assets/icon/history.svg"
import LamaranManualIcon from "../../assets/icon/lamaran-manual.svg"
import LamaranAutoIcon from "../../assets/icon/lamaran-auto.svg"
import UploadLamarannIcon from "../../assets/icon/upload-lamaran.svg"
import AppLoading from "../../components/AppLoading";


// let subCollection = "applications";

// accordion
const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0
  // },
  "&:before": {
    display: "none"
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}))





function ApplicationList({ history }) {

  const theme = useTheme();

  const { user, userData, userDataSett } = useFirebase();
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // modal
  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);
  const [modalNow, setModalNow] = useState('manual')

  const bottomsheetRef = useRef();
  const handleDismissButtomsheet = () => {
    setBottomSheetOpen(false)
  };
  const handleOpenModal = (type) => {
    // console.log(type)
    setModalNow(type)
    setBottomSheetOpen(true)
  }
  // const [isTourOpen, setIsTourOpen] = useState(true);

  // const disableBody = (target) => disableBodyScroll(target)
  // const enableBody = (target) => enableBodyScroll(target)

  const [expanded, setExpanded] = React.useState("panel1")

  const handleChange2 = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  // credential
  const credRef = doc(firestore, "credentials", user?.uid);

  const { data: credential, isLoading: credentialLoading } = useFirestoreDocumentData(
    ["credentials", user?.uid],
    credRef,
    {
      subscribe: true,
      idField: 'id'
    }
  );
  console.log(credential)

  // tabs
  const [tab, setTab] = useState('sent');
  const [filter, setFilter] = useState(['sent', '<blank>'])

  const handleChangeTab = (e, newValue) => {
    // console.log(newValue)
    setTab(newValue)
  }

  useEffect(() => {
    const tab1 = ['sent', '<blank>']
    const tab2 = ['pending', 'readytosend', 'scheduled']
    if (tab === 'sent') {
      setFilter(tab1)
    } else if (tab === 'readytosend') {
      setFilter(tab2)
    } else {
      setFilter(['scheduled'])
    }
  }, [tab,])
  // Ref
  const ref = applicationCollection(user?.uid);

  // Get Data
  let q = filter && query(
    ref,
    where("status", "in", filter),
    // orderBy("createdAt", "desc"),
  );
  const { data: listEmail, isLoading } = useFirestoreQueryData(
    [
      "applications",
      {
        status: filter,
      },
    ],
    q,
    { subscribe: true, idField: "id" }
  );
  // console.log(tab)

  // call cf history
  const { mutateAsync: statistic, isLoading: loading } = useFunctionsCall(
    functions,
    "statistic-getApplicationsStat"
  );
  const [stats, setStats] = useState()
  useEffect(() => {
    async function getStatistic() {
      try {
        const response = await statistic({
          user_id: user?.uid
        })
        // console.log(response)
        setStats(response)
      } catch (e) {

      }
    }
    getStatistic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const terkirimToday = stats?.filter?.((stat) => {
    return stat?.createdAt === TimestampToDate(Date.now()) && stat.status === 'sent'
  })

  const allDataPending = stats?.filter?.((stat) => {
    return stat.status === 'pending' || stat.status === 'scheduled'
  })

  const jumlahPending = allDataPending?.reduce?.((a, b) => {
    return a + b.counter
  }, 0)
  const allDataSent = stats?.filter?.((stat) => {
    return stat.status === 'sent'
  })

  const jumlahSent = allDataSent?.reduce?.((a, b) => {
    return a + b.counter
  }, 0)

  const last30dasy = stats?.filter?.((stat) => {
    const date = new Date();

    date.setDate(date.getDate() - 30);
    const lastMonth = date.toISOString().split('T')[0]
    // New date: August 30, 2022
    // console.log(date.toISOString().split('T')[0]);
    return stat.createdAt > lastMonth
  })

  const filterBy = tab === 'sent' ? 'sentAt' : tab === 'scheduled' ? 'schedule' : 'createdAt'
  const sortedList = []?.concat?.(listEmail)?.sort?.((a, b) => {
    return a?.[filterBy] < b?.[filterBy] ? 1 : -1
  });
  // console.log(tab)

  // dialog grafik

  // dialog vidio
  const [dialogGuide, setDialogGuide] = useState(false);





  const desktop = useMediaQuery('(min-width:600px)');

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      backgroundColor: '#CFCFCF',
      padding: '0px',
      '&.Mui-selected': {
        backgroundColor: '#fff',
        color: '#F26722',
        // borderBottom: '5px solid',
        // borderColor: 'black'
      },
      color: '#7C8493',
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
  );


  useEffect(() => {
    if (!userDataSett?.jobTitle || !userDataSett?.message || !userDataSett?.subject || !credential?.id) {
      const action = () => {
        return <React.Fragment>
          <Button color="secondary" size="small" sx={{ textTransform: 'none', color: '#fff' }} onClick={() => {
            // console.log(audioBlob)
            // handleUploadAudio(audioBlob)
            // snackbar.Close()

            navigate('/settings/gmail')
          }}>
            Klik Disini Untuk Melengkapi
          </Button>
          {/* <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                // onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton> */}
        </React.Fragment>
      };
      enqueueSnackbar(`Kamu Belum Melengkapi Setting Pengiriman Email, `, {
        action: action, autoHideDuration: null, preventDuplicate: false, variant: 'warning', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        style: {
          top: '50px'
        }
      })
    } else {
      closeSnackbar()
    }
  }, [enqueueSnackbar, navigate, userDataSett?.jobTitle, userDataSett?.message, userDataSett?.subject, credential?.id, closeSnackbar])


  if (isLoading || loading || credentialLoading) {
    return <AppLoading />;
  }








  return (
    <>
      <Container maxWidth="">

        <Grid container sx={{ margin: '20px 0' }}>
          {
            desktop ?
              <>
                <Grid item lg={8} xs={12}>
                  <Grid sx={{ marginTop: '10px' }} container spacing={1}>
                    <Grid item lg={4} xs={4}>
                      <Tooltip title="Kirim lamaran dengan input alamat email, nama perusahaan, dsb secara manual satu per satu">
                        <Card
                          className="totalReadySend"
                          sx={{
                            padding: '10px',
                            // marginRight: '10px'
                            backgroundColor: '#F26722',
                          }}
                          onClick={() => {
                            handleOpenModal('manual')
                          }}
                        >
                          <CardActionArea>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center', marginTop: '8px'
                              }}
                            >
                              <img
                                alt=""
                                src={LamaranManualIcon}
                                height={50}

                              />
                              {/* <ForwardToInboxIcon color='primary' /> */}

                            </div>
                            <Typography color='#fff' sx={{ textAlign: 'center', fontSize: '15px', marginTop: '8px', fontWeight: 'bold' }}>Kirim Lamaran Manual</Typography>

                          </CardActionArea>
                        </Card>
                      </Tooltip>

                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <Tooltip title="Kirim lamaran menggunakan database email perusahaan yang ada di karir.ai">
                        <Card
                          className="totalReadySend"
                          sx={{
                            padding: '10px',
                            // marginRight: '10px'
                            backgroundColor: '#F26722'
                          }}
                          onClick={() => {
                            handleOpenModal('auto')
                          }}
                        >
                          <CardActionArea>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center', marginTop: '8px'
                              }}
                            >
                              <img
                                alt=""
                                src={LamaranAutoIcon}
                                height={50}

                              />
                              {/* <ForwardToInboxIcon color='primary' /> */}

                            </div>
                            <Typography color='#fff' sx={{ textAlign: 'center', fontSize: '15px', marginTop: '8px', fontWeight: 'bold' }}>Kirim Auto-Lamaran</Typography>

                          </CardActionArea>
                        </Card>
                      </Tooltip>

                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <Tooltip title="Kirim lamaran dari daftar target email yang tersimpan dalam file excel (template terlampir)">
                        <Card
                          className="totalReadySend"
                          sx={{
                            padding: '10px',
                            // marginRight: '10px'
                            backgroundColor: '#F26722'
                          }}
                          onClick={() => {
                            handleOpenModal('upload')
                          }}
                        >
                          <CardActionArea>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center', marginTop: '8px'
                              }}
                            >
                              <img
                                alt=""
                                src={UploadLamarannIcon}
                                height={50}

                              />
                              {/* <ForwardToInboxIcon color='primary' /> */}

                            </div>
                            <Typography color='#fff' sx={{ textAlign: 'center', fontSize: '15px', marginTop: '8px', fontWeight: 'bold' }}>Upload Lamaran</Typography>

                          </CardActionArea>
                        </Card>
                      </Tooltip>

                    </Grid>


                  </Grid>
                  <Grid item xs={1}>
                    <img
                      src={`https://auto.karir.ai/mtracking.gif?page_title=karirhack_home&email=${userData?.email}`} alt=''
                    />
                  </Grid>
                  <Grid style={{
                    display: 'flex'
                  }}>
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginTop: "0px",
                        // textAlign: "center",
                      }}
                    >
                      Lamaran Saya
                    </p>
                    <IconButton
                      sx={{ marginTop: '-20px' }}
                      onClick={async () => {
                        try {
                          const response = await statistic({
                            user_id: user?.uid
                          })
                          setStats(response)

                        } catch (e) {

                        }
                      }}
                    >
                      <Refresh className="refreshPage" color="primary" />
                    </IconButton>
                  </Grid>

                  <div style={{ border: '1px solid ', borderColor: '#DDD', }}>
                    <TabContext value={tab}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant={desktop ? "fullWidth" : "scrollable"} onChange={handleChangeTab} aria-label="lab API tabs example" style={{
                          backgroundColor: '#CFCFCF',

                          // color: '#7C8493'
                        }}>
                          <StyledTab className="sentList" label="Terkirim" value='sent' />
                          <StyledTab className="readySendList" label="Siap Dikirim" value='readytosend' />
                          {/* <StyledTab className="scheduledtoSend" label="Dijadwalkan" value='scheduled' />
                    <StyledTab className="scheduledtoSend" label="Upload" value='upload' /> */}

                        </TabList>
                      </Box>
                    </TabContext>
                    {listEmail?.length === 0 ? (
                      // No Data
                      <div
                        styles={{
                          margin: "150px 0px",
                        }}
                        className="listStats"
                      >
                        <p
                          style={{
                            fontSize: "26px",
                            color: theme.palette.neutrals.n60,
                          }}
                        >
                          Belum ada Lamaran!
                        </p>
                      </div>
                    ) : (
                      // Data
                      sortedList?.map((item, idx) => {
                        return (
                          <div key={idx} className='listStats'>
                            <Box
                              sx={{
                                // "@media(max-width: 630px)": {
                                //   backgroundColor: theme.palette.neutrals.nf,
                                // },
                                backgroundColor: theme.palette.neutrals.n0,
                                borderRadius: "8px",
                                padding: "16px",
                                marginBottom: "30px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  textAlign: "left",
                                }}
                              >
                                Lamaran: {item?.jobTitle ?? "-"} di {item?.companyName}
                              </p>
                              {
                                item?.status === 'pending' || item?.status === 'scheduled' ? <>
                                  <IconButton
                                    sx={{
                                      float: 'right'
                                    }}
                                    onClick={() => {
                                      navigate(`/application/edit/${item?.id}`)
                                    }}
                                  >
                                    <Edit
                                      color="primary"
                                    />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      float: 'right'
                                    }}
                                    onClick={async () => {
                                      try {
                                        if (window.confirm('Yakin ingin menghapus data ini?')) {
                                          await deleteDoc(doc(firestore, "users", user?.uid, "applications", item?.id));
                                          enqueueSnackbar('Berhasil dihapus', { variant: 'success' })
                                        }

                                      } catch (e) {
                                        enqueueSnackbar(`Gagal dihapus, ${e.message}`, { variant: 'error' })
                                      }
                                    }}
                                  >
                                    <Delete
                                      color="primary"
                                    />
                                  </IconButton>
                                </> : false
                              }
                              <p
                                style={{
                                  marginTop: 0,
                                  fontSize: "15px",
                                  textAlign: "left",
                                  fontWeight: "normal",
                                  color: theme.palette.neutrals.n60,
                                }}
                              >
                                Kepada: {item?.recipient ?? "-"}<br />
                                Dibuat: {formatFromNow(item?.createdAt?.toDate?.()) ?? "-"}<br />
                                Media: {item?.sendType ?? "-"}<br />
                                {
                                  item?.status === 'sent' &&
                                  `Terkirim: ${formatFromNow(item?.sentAt?.toDate())}`
                                }
                                {
                                  item?.status === 'scheduled' &&
                                  `Dijadwalkan: ${formatFromNow(item?.schedule?.toDate())}`
                                }
                              </p>

                              <Grid container justifyContent="space-between">
                                <Grid item>
                                  <p
                                    style={{
                                      marginTop: 0,
                                      fontSize: "15px",
                                      textAlign: "left",
                                      fontWeight: "normal",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    Status : `{item?.sent ? 'Terkirim' : item?.status === 'scheduled' ? 'Scheduled' : 'Pending'} - {item?.read ? 'Dibaca pada ' + formatDate(item?.createdAt?.toDate?.()) : 'Belum dibaca'} {item?.error ? ' - Error : ' + item?.error : ''}`
                                  </p>
                                </Grid>
                                <Grid item>
                                  {/*  */}
                                  {item?.status === 'sent' &&

                                    <IconButton color="primary" onClick={() => {
                                      navigate(`/application/edit/${item?.id}`)
                                    }}>
                                      <Visibility color="primary" /></IconButton>
                                  }
                                  {/* </IconButton> */}

                                </Grid>

                              </Grid>
                            </Box>
                          </div>
                        );
                      })
                    )}
                  </div>

                  <div
                    style={{
                      position: "fixed",
                      width: desktop ? "60%" : "100%",
                      bottom: "72px",
                      left: desktop ? 60 : 0,
                      // right: 0,
                      padding: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >


                    <BottomSheet
                      initialFocusRef={false}
                      open={bottomsheetOpen}
                      ref={bottomsheetRef}
                      onDismiss={handleDismissButtomsheet}
                      snapPoints={({ maxHeight }) => [maxHeight * 0.9]}

                    >
                      <Container maxWidth="md">
                        {
                          modalNow === 'auto' ?
                            <ApplicationCreateAuto />
                            :
                            modalNow === 'upload' ?
                              <Upload />
                              :
                              <ApplicationCreate />


                        }
                      </Container>
                    </BottomSheet>
                  </div>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <div style={{
                    marginLeft: '30px',
                    marginTop: '20px'
                  }}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange2('panel1')}>
                      <AccordionSummary className="vidTutor" aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Video Tutorial</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ width: '100%' }}>
                          <iframe width="100%" height="200" src="https://www.youtube.com/embed/WQhxDj5MIkA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Grid sx={{ marginBottom: '20px', marginTop: '30px' }} container spacing={1}>
                      <Grid item xs={12}>
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            marginTop: "0px",
                            // textAlign: "center",
                            marginBottom: '5px'

                          }}
                        >
                          Statistik
                        </p>
                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <Card
                          className="sentToday"
                          sx={{
                            padding: '10px',

                            // marginRight: '10px'
                          }}
                        > <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '8px'
                          }}
                        >
                            <img
                              alt=""
                              src={MoneyIcon}
                            />
                            {/* <MarkEmailReadIcon color='primary' /> */}
                          </div>
                          <Typography color='GrayText' sx={{ textAlign: 'center', fontSize: '10px', marginTop: '8px' }}>Terkirim hari ini</Typography>
                          <Typography color='GrayText' sx={{ textAlign: 'center' }}>{terkirimToday?.[0] ? terkirimToday?.[0]?.counter : 0}</Typography>

                        </Card>


                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <Card
                          className="totalReadySend"
                          sx={{
                            padding: '10px',
                            // marginRight: '10px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center', marginTop: '8px'
                            }}
                          >
                            <img
                              alt=""
                              src={GlobalIcon}
                            />
                            {/* <ForwardToInboxIcon color='primary' /> */}

                          </div>
                          <Typography color='GrayText' sx={{ textAlign: 'center', fontSize: '10px', marginTop: '8px' }}>Total siap kirim</Typography>
                          <Typography color='GrayText' sx={{ textAlign: 'center' }}>{jumlahPending ? jumlahPending : 0}</Typography>

                        </Card>


                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <Card
                          className="totalSent"
                          sx={{
                            padding: '10px',
                            // marginRight: '10px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center', marginTop: '8px'
                            }}
                          >
                            <img
                              alt=""
                              src={LikeIcon}
                            // height={84}

                            />
                            {/* <DoneAllIcon color='primary' /> */}
                          </div>
                          <Typography color='GrayText' sx={{ textAlign: 'center', fontSize: '10px', marginTop: '8px' }}>Total terkirim</Typography>
                          <Typography color='GrayText' sx={{ textAlign: 'center' }}>{jumlahSent ? jumlahSent : 0}</Typography>

                        </Card>


                      </Grid>
                      <Grid item lg={12} xs={12} >
                        <Card
                          // onClick={() => {
                          //   setDialog(true)
                          // }}
                          className="historySent"
                          sx={{
                            padding: '10px',
                            cursor: 'pointer',
                            // marginRight: '10px',
                            "&:hover": {
                              backgroundColor: '#fafafa'
                            },

                          }}>
                          <Typography color='GrayText' sx={{ textAlign: 'center' }}>History 30 hari</Typography>
                          <Grafik
                            data30={last30dasy}
                          />
                        </Card>
                      </Grid>
                    </Grid>
                  </div>

                </Grid>
              </> :
              <>
                <Grid sx={{ marginTop: '10px' }} >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOpenModal('manual')
                    }}
                    sx={{
                      marginBottom: '10px'
                    }}
                  >
                    <AttachFile fontSize="small" sx={{ marginRight: '7px' }} />
                    Kirim Lamaran Manual
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOpenModal('auto')
                    }}
                    sx={{
                      marginBottom: '10px'
                    }}
                  >
                    <Refresh fontSize="small" sx={{ marginRight: '7px' }} />
                    Kirim Auto-Lamaran
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOpenModal('upload')
                    }}
                    sx={{
                      marginBottom: '10px'
                    }}
                  >
                    <UploadFile fontSize="small" sx={{ marginRight: '7px' }} />
                    Upload Lamaran
                  </Button>



                </Grid>
                <Grid item lg={4} xs={12}>
                  <div style={{
                    // marginLeft: '30px',
                    marginTop: '20px'
                  }}>

                    <Grid sx={{ marginBottom: '20px' }} container spacing={1}>
                      <Grid item xs={12}>
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            marginTop: "0px",
                            // textAlign: "center",
                            marginBottom: '5px',
                            textAlign: 'center'

                          }}
                        >
                          Statistik
                        </p>
                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <Card
                          className="sentToday"
                          sx={{
                            padding: '10px',

                            // marginRight: '10px'
                          }}
                        > <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '8px'
                          }}
                        >
                            {/* <img
                              alt=""
                              src={MoneyIcon}
                            /> */}
                            {/* <MarkEmailReadIcon color='primary' /> */}
                          </div>
                          <Typography color='GrayText' sx={{ textAlign: 'center', fontSize: '10px', marginTop: '8px' }}>Terkirim hari ini</Typography>
                          <Typography color='#F26722' sx={{ textAlign: 'center' }}>{terkirimToday?.[0] ? terkirimToday?.[0]?.counter : 0}</Typography>

                        </Card>


                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <Card
                          className="totalReadySend"
                          sx={{
                            padding: '10px',
                            // marginRight: '10px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center', marginTop: '8px'
                            }}
                          >
                            {/* <img
                              alt=""
                              src={GlobalIcon}
                            /> */}
                            {/* <ForwardToInboxIcon color='primary' /> */}

                          </div>
                          <Typography color='GrayText' sx={{ textAlign: 'center', fontSize: '10px', marginTop: '8px' }}>Total siap kirim</Typography>
                          <Typography color='#F26722' sx={{ textAlign: 'center' }}>{jumlahPending ? jumlahPending : 0}</Typography>

                        </Card>


                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <Card
                          className="totalSent"
                          sx={{
                            padding: '10px',
                            // marginRight: '10px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center', marginTop: '8px'
                            }}
                          >
                            {/* <img
                              alt=""
                              src={LikeIcon} */}

                            {/* /> */}
                            {/* <DoneAllIcon color='primary' /> */}
                          </div>
                          <Typography color='GrayText' sx={{ textAlign: 'center', fontSize: '10px', marginTop: '8px' }}>Total terkirim</Typography>
                          <Typography color='#F26722' sx={{ textAlign: 'center' }}>{jumlahSent ? jumlahSent : 0}</Typography>

                        </Card>


                      </Grid>

                      <Grid item lg={12} xs={12} >
                        <Card
                          // onClick={() => {
                          //   setDialog(true)
                          // }}
                          className="historySent"
                          sx={{
                            padding: '10px',
                            cursor: 'pointer',
                            // marginRight: '10px',
                            "&:hover": {
                              backgroundColor: '#fafafa'
                            },

                          }}>
                          <Typography color='GrayText' sx={{ textAlign: 'center' }}>History 30 hari</Typography>
                          <Grafik
                            data30={last30dasy}
                          />
                        </Card>
                      </Grid>
                    </Grid>
                    <Accordion >
                      <AccordionSummary className="vidTutor" aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Video Tutorial</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ width: '100%' }}>
                          <iframe width="100%" height="200" src="https://www.youtube.com/embed/WQhxDj5MIkA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                </Grid>
                <Grid item lg={8} xs={12}>

                  <Grid item xs={1}>
                    <img
                      src={`https://auto.karir.ai/mtracking.gif?page_title=karirhack_home&email=${userData?.email}`} alt=''
                    />
                  </Grid>
                  <Grid style={{
                    display: 'flex'
                  }}>
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginTop: "0px",
                        // textAlign: "center",
                      }}
                    >
                      Lamaran Saya
                    </p>
                    <IconButton
                      sx={{ marginTop: '-20px' }}
                      onClick={async () => {
                        try {
                          const response = await statistic({
                            user_id: user?.uid
                          })
                          setStats(response)

                        } catch (e) {

                        }
                      }}
                    >
                      <Refresh className="refreshPage" color="primary" />
                    </IconButton>
                  </Grid>

                  <div style={{ border: '1px solid ', borderColor: '#DDD', }}>
                    <TabContext value={tab}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant={"fullWidth"} onChange={handleChangeTab} aria-label="lab API tabs example" style={{
                          backgroundColor: '#CFCFCF',

                          // color: '#7C8493'
                        }}>
                          <StyledTab className="sentList" label="Terkirim" value='sent' />
                          <StyledTab className="readySendList" label="Siap Dikirim" value='readytosend' />
                          {/* <StyledTab className="scheduledtoSend" label="Dijadwalkan" value='scheduled' />
                    <StyledTab className="scheduledtoSend" label="Upload" value='upload' /> */}

                        </TabList>
                      </Box>
                      {/* <TabPanel value="terkirim">Item One</TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
          <TabPanel value="3">Item Three</TabPanel> */}
                    </TabContext>
                    {listEmail?.length === 0 ? (
                      // No Data
                      <div
                        styles={{
                          margin: "150px 0px",
                        }}
                        className="listStats"
                      >
                        <p
                          style={{
                            fontSize: "26px",
                            color: theme.palette.neutrals.n60,
                          }}
                        >
                          Belum ada Lamaran!
                        </p>
                      </div>
                    ) : (
                      // Data
                      sortedList?.map((item, idx) => {
                        return (
                          <div key={idx} className='listStats'>
                            <Box
                              sx={{
                                // "@media(max-width: 630px)": {
                                //   backgroundColor: theme.palette.neutrals.nf,
                                // },
                                backgroundColor: theme.palette.neutrals.n0,
                                borderRadius: "8px",
                                padding: "16px",
                                marginBottom: "30px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  textAlign: "left",
                                }}
                              >
                                Lamaran: {item?.jobTitle ?? "-"} di {item?.companyName}
                              </p>
                              {
                                item?.status === 'pending' || item?.status === 'scheduled' ? <>
                                  <IconButton
                                    sx={{
                                      float: 'right'
                                    }}
                                    onClick={() => {
                                      navigate(`/application/edit/${item?.id}`)
                                    }}
                                  >
                                    <Edit
                                      color="primary"
                                    />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      float: 'right'
                                    }}
                                    onClick={async () => {
                                      try {
                                        if (window.confirm('Yakin ingin menghapus data ini?')) {
                                          await deleteDoc(doc(firestore, "users", user?.uid, "applications", item?.id));
                                          enqueueSnackbar('Berhasil dihapus', { variant: 'success' })
                                        }

                                      } catch (e) {
                                        enqueueSnackbar(`Gagal dihapus, ${e.message}`, { variant: 'error' })
                                      }
                                    }}
                                  >
                                    <Delete
                                      color="primary"
                                    />
                                  </IconButton>
                                </> : false
                              }
                              <p
                                style={{
                                  marginTop: 0,
                                  fontSize: "15px",
                                  textAlign: "left",
                                  fontWeight: "normal",
                                  color: theme.palette.neutrals.n60,
                                }}
                              >
                                Kepada: {item?.recipient ?? "-"}<br />
                                Dibuat: {formatFromNow(item?.createdAt?.toDate?.()) ?? "-"}<br />
                                Media: {item?.sendType ?? "-"}<br />
                                {
                                  item?.status === 'sent' &&
                                  `Terkirim: ${formatFromNow(item?.sentAt?.toDate())}`
                                }
                                {
                                  item?.status === 'scheduled' &&
                                  `Dijadwalkan: ${formatFromNow(item?.schedule?.toDate())}`
                                }
                              </p>

                              <Grid container justifyContent="space-between">
                                <Grid item>
                                  <p
                                    style={{
                                      marginTop: 0,
                                      fontSize: "15px",
                                      textAlign: "left",
                                      fontWeight: "normal",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    Status : `{item?.sent ? 'Terkirim' : item?.status === 'scheduled' ? 'Scheduled' : 'Pending'} - {item?.read ? 'Dibaca pada ' + formatDate(item?.createdAt?.toDate?.()) : 'Belum dibaca'} {item?.error ? ' - Error : ' + item?.error : ''}`
                                  </p>
                                </Grid>
                                <Grid item>
                                  {/*  */}
                                  {item?.status === 'sent' &&

                                    <IconButton color="primary" onClick={() => {
                                      navigate(`/application/edit/${item?.id}`)
                                    }}>
                                      <Visibility color="primary" /></IconButton>
                                  }
                                  {/* </IconButton> */}

                                </Grid>

                              </Grid>
                            </Box>
                          </div>
                        );
                      })
                    )}
                  </div>

                  <div
                    style={{
                      position: "fixed",
                      width: desktop ? "60%" : "100%",
                      bottom: "72px",
                      left: desktop ? 60 : 0,
                      // right: 0,
                      padding: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >


                    <BottomSheet
                      initialFocusRef={false}
                      open={bottomsheetOpen}
                      ref={bottomsheetRef}
                      onDismiss={handleDismissButtomsheet}
                      snapPoints={({ maxHeight }) => [maxHeight * 0.9]}

                    >
                      <Container maxWidth="md">
                        {
                          modalNow === 'auto' ?
                            <ApplicationCreateAuto setBottomSheetOpen={setBottomSheetOpen} />
                            :
                            modalNow === 'upload' ?
                              <Upload />
                              :
                              <ApplicationCreate setBottomSheetOpen={setBottomSheetOpen} />


                        }
                      </Container>
                    </BottomSheet>
                  </div>
                </Grid>

              </>
          }
        </Grid>
      </Container>



      <GuideDialog
        open={dialogGuide}
        handleClose={() => {
          setDialogGuide(false)
        }}
        content='KarirHack Tutorial'
        idTutorial='extension'
      />

    </>
  );
}

export default ApplicationList;
