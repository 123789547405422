// MUI
import { Container, Box, Backdrop, CircularProgress, Fab } from "@mui/material";
import { useEffect, useState } from "react";

// React Router Dom
import {
  Outlet,
  useSearchParams,
  // useLocation,
} from "react-router-dom";
import AppHeader from "./components/appHeader";

import { ScrollRestoration } from "react-router-dom";
import BottomNav from "./components/BottomNav";
import { useFirebase } from "./contexts/FirebaseProvider";
import usePersistQueryString from "./hooks/usePersistQueryString";
import { useTour } from "@reactour/tour";
import { Help } from "@mui/icons-material";
function App() {
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const extension = searchParams.get("extension") ?? false;
  const { user, userData } = useFirebase();

  const { setIsOpen } = useTour()

  useEffect(() => {
    if (!userData?.karirhack_tour_step && user?.uid) {
      setIsOpen(true)
    }
  }, [setIsOpen, user?.uid, userData?.karirhack_tour_step])

  usePersistQueryString();
  return (
    <Container sx={{
      padding: 0, maxWidth: 'inherit !important'
    }}>
      <ScrollRestoration />
      <AppHeader extension={extension} />
      <Box
        sx={{
          // height: "100vh",
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          position: "relative",
          zIndex: 1,
        }}
      >
        {/* <TourGuide steps={steps?.steps} path={pathFixed} /> */}
        <Fab className="helpBtn" sx={{ position: 'fixed', bottom: '140px', right: '20px' }} onClick={() => setIsOpen(true)} color="primary" aria-label="add">
          <Help />
        </Fab>
        <Outlet context={{ backdropLoading, setBackdropLoading }} />
      </Box>
      {user?.uid && !extension ? (
        <Box mt="100px">
          <BottomNav setBackdropLoading={setBackdropLoading} />
        </Box>
      ) : (
        <Box mt="20px" />
      )}

      <Backdrop style={{ zIndex: 1000 }} open={backdropLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Container>

  );
}

export default App;
