// React
import React from "react";

// MUI
import {
  Box,
  useTheme,
  Typography,
  styled,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";

function StepperCustom({ step = 0, totalSteps = 0 }) {
  // theme
  const theme = useTheme();

  const progress = step * 10;

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.surface.background,
        padding: "16px",
      }}
    >
      {/* Title */}
      <Typography
        sx={{
          fontSize: "16px",
          color: theme.palette.textColor.neutral60,
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            color: theme.palette.textColor.brands,
          }}
          component="b"
        >
          {step}
        </Typography>{" "}
        dari{" "}
        <Typography
          sx={{
            fontWeight: "700",
            color: theme.palette.textColor.brands,
          }}
          component="b"
        >
          {totalSteps}
        </Typography>{" "}
        form yang telah diisi
      </Typography>

      {/* Progress */}
      <BorderLineProgress variant="determinate" value={progress} />
    </Box>
  );
}

const BorderLineProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: 8,
  height: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.neutral.n20,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.green.main,
  },
}));

export default StepperCustom;
