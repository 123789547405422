import { Email } from "@mui/icons-material";
import GoogleLogo from "../../assets/icon/google";
import { Box, Button } from "@mui/material";
import { Navigate, useLocation } from "react-router-dom";
import { useFirebase } from "../../contexts/FirebaseProvider";

function LoginPage() {
  const location = useLocation();
  const { handleSignInWithGoogle, handleSignInWIthEmail, user } = useFirebase();
  const from = location.state?.from?.pathname || "/";

  if (user.uid) {
    return <Navigate to={from} replace />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px",
      }}
    >
      <Box sx={{ width: "300px" }}>
        <Button
          sx={{
            fontFamily: "Roboto",
            fontSize: "14px",
            color: "#fff",
            marginTop: "10px",
            "&: hover": {
              backgroundColor: "#eee",
            },
            "& .MuiButton-startIcon": {
              marginLeft: "-15px",
              marginRight: "20px",
            },
          }}
          size="large"
          variant="contained"
          color="primary"
          startIcon={<Email sx={{ fontSize: "18px !important" }} />}
          onClick={handleSignInWIthEmail}
          fullWidth
        >
          Sign in with Email
        </Button>
        <Button
          sx={{
            backgroundColor: "#fff",
            fontFamily: "Roboto",
            fontSize: "14px",
            color: "rgba(0,0,0,0.54)",
            marginTop: "10px",
            "&: hover": {
              backgroundColor: "#eee",
            },
            "& .MuiButton-startIcon": {
              marginLeft: "0px",
              marginRight: "20px",
            },
          }}
          size="large"
          variant="contained"
          startIcon={<GoogleLogo sx={{ fontSize: "18px !important" }} />}
          onClick={handleSignInWithGoogle}
          fullWidth
        >
          Sign in with Google
        </Button>
      </Box>
    </Box>
  );
}

export default LoginPage;
