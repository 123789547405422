// React
import React from "react";

// MUI
import {
  Box,
  Chip,
  FormHelperText,
  InputBase,
  Typography,
  useTheme,
  Stack,
  Button,
} from "@mui/material";
import SwitchCustom from "./SwitchCustom";

function InputCustom({
  title,
  placeholder,
  name,
  onChange,
  value,
  error,
  helperText,
  containerRef,
  switchEnabled,
  switchLabel,
  switchProps,
  listChip = [],
  isChip = false,
  onDeleteChip,
  InputProps,
  generateCoverLetter,
  onGenerate,
  disableGenerate,
  ...restProps
}) {
  const theme = useTheme();
  return (
    <Box ref={containerRef} sx={{ marginTop: "20px" }}>
      {/* Title */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: error ? theme.error.red : theme.palette.textColor.neutral80,
            fontWeight: "600",
            fontSize: "14px",
            flex: 1,
          }}
        >
          {title}
        </Typography>
        {switchEnabled && (
          <>
            <Typography
              sx={{
                fontFamily: "glacial_indifferenceregular",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "160%",
                textAlign: "right",
                color: "#515B6F",
                marginRight: 1,
              }}
            >
              {switchLabel}
            </Typography>
            <SwitchCustom {...switchProps} />
          </>
        )}
        {generateCoverLetter && (
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "12px",
            }}
            onClick={onGenerate}
            disabled={disableGenerate}
          >
            Generate Cover Letter
          </Button>
        )}
      </Box>
      {isChip ? (
        <Stack
          direction="row"
          sx={{
            flexWrap: "wrap",
            gap: 1,
            my: 1,
          }}
        >
          {listChip.map((item, idx) => {
            return (
              <Chip
                key={idx}
                label={item}
                variant="fill"
                color="primary"
                size="small"
                onDelete={() => onDeleteChip(item)}
              />
            );
          })}
        </Stack>
      ) : null}
      {/* Input */}
      <Box
        sx={{
          marginTop: "4px",
          width: "100%",
          border: `1px solid ${error ? theme.error.red : theme.neutral.n20}`,
          padding: "12px 16px",
        }}
      >
        <InputBase
          name={name}
          placeholder={placeholder}
          fullWidth
          onChange={onChange}
          value={value}
          {...restProps}
          {...InputProps}
        />
      </Box>

      {/* Error Message */}
      {error ? <FormHelperText error>{helperText}</FormHelperText> : null}
    </Box>
  );
}

export default InputCustom;
