import { Autocomplete, Typography } from "@mui/material";
import { useState } from "react";
import { InstantSearch, useHits, useSearchBox } from "react-instantsearch-hooks-web";
import createTypesenseISAdapter from "../config/typesense";
import InputCustom from "./InputCustom";
const typsesenseAdapter = createTypesenseISAdapter({
    query_by: "subdistrict_name",
    typo_tokens_threshold: 3,
    per_page: 250
})

const searchClient = typsesenseAdapter.searchClient;

const transformItems = (items, { results }) => {
    return items.map(({ objectID, id, subdistrict_name, district_name, city_name, province_name }) => ({
        objectID, id, subdistrict_name, district_name, city_name, province_name
    }));
};

function CustomSearch({ value, onChange, inputCustomProps, switchProps, ...restProps }) {
    const [inputValue, setInputValue] = useState("");
    const { refine } = useSearchBox();
    const { hits } = useHits({ transformItems });
    return <Autocomplete

        options={hits}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        // filterSelectedOptions
        disableClearable
        freeSolo
        value={value}
        onChange={(e, newValue) => {
            onChange(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue)
            refine(newInputValue)
        }}
        isOptionEqualToValue={(option, value) => option.objectID === value.objectID}
        renderInput={(params) => {
            return <InputCustom
                containerRef={params.InputProps.ref}
                switchEnabled={false}
                switchLabel="Tampilkan dalam CV"
                switchProps={switchProps}
                placeholder="Jahiang"
                title="Cari Nama Kelurahan/Desa"
                inputProps={params.inputProps}
                {...inputCustomProps}
            />

        }}
        getOptionLabel={(option) => option.subdistrict_name ?? ''}
        renderOption={(props, option, state) => {

            return <li {...props} key={option.objectID}>
                <Typography sx={!state.selected ? { fontFamily: 'glacial_indifferenceregular' } : {}}>{option.subdistrict_name}, {option.district_name}, {option.city_name}, {option.province_name}</Typography>
            </li>
        }}
        {...restProps}
    />
}

export default function InputLocationSearch({ onChange, value, ...restProps }) {

    return <InstantSearch
        indexName="location"
        searchClient={searchClient}

    >
        <CustomSearch onChange={onChange} value={value} {...restProps} />
    </InstantSearch>
}