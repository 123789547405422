// React
import React, { useEffect, useRef, useState } from "react";

// Components
import { InputCustom, SavedItem } from "../../components";

// React Router Dom
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

// Context
import { subCollectionRef } from "../../contexts/FirebaseProvider";

// React Query
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
  useFirestoreDocumentData,
} from "@react-query-firebase/firestore";

// Firebase
import { deleteDoc, serverTimestamp } from "firebase/firestore";

// Bottom Sheet
import { BottomSheet } from "react-spring-bottom-sheet";

// MUI
import { Button, Container, Typography } from "@mui/material";

// Notistack
import { useSnackbar } from "notistack";

// Initial State
let initialState = {
  title: "",
  issuer: "",
  issuerDate: "",
};

let subCollection = "awards";
const title = "Prestasi";
function AwardsPage() {
  // Context
  const { docRef } = useOutletContext();

  // Params
  const params = useParams();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") ?? "default";

  // Navigate
  const navigate = useNavigate();

  // Ref
  const ref = subCollectionRef(params.cvId, subCollection);

  // Mutate
  const { mutate: addAwards, isLoading: addLoading } =
    useFirestoreCollectionMutation(ref);

  const { mutate: updateAwards, isLoading: updateLoading } =
    useFirestoreDocumentMutation(docRef(ref, id), {
      merge: true,
    });

  // Get Data
  const { data: list } = useFirestoreQueryData(subCollection, ref, {
    subscribe: true,
    idField: "id",
  });

  const { data: item } = useFirestoreDocumentData(
    [subCollection, id],
    docRef(ref, id),
    {
      idField: "id",
    }
  );

  // State
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("create");

  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);

  // Ref
  const bottomsheetRef = useRef();

  // snack
  const { enqueueSnackbar } = useSnackbar();

  // Handler
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
    setErrors(errors => ({
      ...errors,
      [name]: ''
    }))
  };

  const handleDismissButtomsheet = () => {
    navigate({
      search: "",
    });
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }
    navigate({
      search: `?id=new`,
    });

  };

  const handleUpdate = () => {
    if (updateLoading) {
      return;
    }

    const { title, issuer, issuerDate } = form;
    let findErrors = {}
    if (!title) {
      findErrors.title = "Nama prestasi wajib diisi."
    }

    if (!issuer) {
      findErrors.issuer = "Pemberi penghargaan wajib diisi."
    }

    if (!issuerDate) {
      findErrors.issuerDate = "Tahun penghargaan wajib diisi."
    }

    if (Object.values(findErrors).some(message => message !== "")) {
      setErrors(findErrors);
    } else {

      if (mode === "create") {

        addAwards(
          {
            title,
            issuer,
            issuerDate,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess(data) {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, { variant: "success" });

            },
          }
        );
      } else {
        updateAwards(
          {
            title,
            issuer,
            issuerDate,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, { variant: "success" });
            },
          }
        );
      }
    }
  };

  // UseEffect
  useEffect(() => {
    if (item) {
      setForm({
        ...initialState,
        ...item,
      });
    }
  }, [item]);

  useEffect(() => {
    if (id === 'new') {
      setMode('create');
    } else if (id !== 'default') {
      setMode('update');
    }
  }, [id]);

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  const handleDelete = async (selectedId) => {
    return await deleteDoc(docRef(ref, selectedId));
  };
  return (
    <>
      {/* Saved Item */}
      <SavedItem
        type={title}
        list={list}
        titleField="title"
        subtitleField="issuer"
        activeId={id}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        addLoading={addLoading}
      />

      {/* Bottom Sheet */}
      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs">
          {/* Input Nama Prestasi */}
          <InputCustom
            title="Apakah Nama Prestasi yang diraih?"
            placeholder="1st place of Hackathon in Bandung"
            name="title"
            onChange={handleChange}
            value={form.title}
            required
            error={errors?.title ? true : false}
            helperText={errors?.title}
          />

          {/* Input Dimana Meraih Prestasi */}
          <InputCustom
            title="Dimana anda meraih prestasi ini?"
            placeholder="Hackathon 2022"
            name="issuer"
            onChange={handleChange}
            value={form.issuer}
            required
            error={errors?.issuer ? true : false}
            helperText={errors?.issuer}
          />

          {/* Input Kapan Meraih Prestasi */}
          <InputCustom
            title="Kapan anda meraih prestasi ini?"
            placeholder="2022"
            name="issuerDate"
            onChange={handleChange}
            value={form.issuerDate}
            error={errors?.issuerDate ? true : false}
            helperText={errors?.issuerDate}
          />
        </Container>
      </BottomSheet>
    </>
  );
}

export default AwardsPage;
