// import "./styles.css";
import { useMediaQuery } from "@mui/material";
import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";



export default function Grafik({ data30, }) {

    const pure = data30?.reduce?.((group, product) => {
        const { createdAt } = product;
        group[createdAt] = group[createdAt] ?? [];
        group[createdAt].push(product);
        return group;
    }, {});

    const lengthData = pure && Object.values(pure)?.length;
    let all = []
    for (var i = 0; i < lengthData; i++) {
        const a = Object.values(pure);
        const b = Object.keys(pure)
        const allDataPending = a?.[i]?.filter?.((stat) => {
            return stat.status === 'pending'
        })

        const jumlahPending = allDataPending?.reduce?.((a, b) => {
            return a + b.counter
        }, 0)

        const allDatasent = a?.[i]?.filter?.((stat) => {
            return stat.status === 'sent'
        })

        const jumlahsent = allDatasent?.reduce?.((a, b) => {
            return a + b.counter
        }, 0)

        const allDatareadytosent = a?.[i]?.filter?.((stat) => {
            return stat.status === 'readytosend'
        })

        const jumlahreadytosent = allDatareadytosent?.reduce?.((a, b) => {
            return a + b.counter
        }, 0)
        all.push({
            name: b?.[i],
            pending: jumlahPending,
            sent: jumlahsent,
            readytosend: jumlahreadytosent
        })
    }

    const allSort = all.sort((a, b) => {
        a = a.name.split('-').reverse().join('');
        b = b.name.split('-').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0;
    })

    const desktop = useMediaQuery('(min-width:600px)');

    // console.log()
    return (
        // <Dialog
        //     open={open}
        //     onClose={handleClose}
        // >
        //     <DialogTitle>Grafik riwayat 30 hari terakhir</DialogTitle>
        //     <DialogContent>
        <LineChart
            layout="vertical"
            width={500}
            height={300}
            data={allSort}
            margin={{
                top: 20,
                right: desktop ? 150 : 270,
                left: 50,
                bottom: 5
            }}
            style={{

            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" />
            <Tooltip />
            <Legend />
            <Line
                // style={{ fontSize: '10px' }}
                type="monotone"
                dataKey="sent"
                stroke="#8884d8"
                activeDot={true}
            />
            <Line type="monotone" dataKey="pending" stroke="#82ca9d" />
            <Line type="monotone" dataKey="readytosend" stroke="#7C8493" />

        </LineChart>
        //     </DialogContent>
        //     <DialogActions>
        //         <Button
        //             onClick={handleClose}
        //         >Keluar</Button>
        //     </DialogActions>
        // </Dialog>
    );
}
