import { useAuthSignInWithCredential } from "@react-query-firebase/auth"
import { GoogleAuthProvider } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppLoading from "../../components/AppLoading";
import { auth } from "../../contexts/FirebaseProvider"

export default function LoginByCredentials() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirect = searchParams.get("redirect") ?? '/';
    const token = searchParams.get("token");
    const { mutate } = useAuthSignInWithCredential(auth)

    useEffect(() => {

        if (token) {

            const credentials = GoogleAuthProvider.credential(null, token);
            mutate(credentials, {
                onSuccess() {
                    navigate(redirect);
                },
                onError(e) {
                    console.log("error", e, token)
                    navigate("/");
                }
            })
        }
    }, [mutate, navigate, redirect, token]);

    return <AppLoading />


}