import { MobileDatePicker } from "@mui/x-date-pickers"
import InputCustom from "./InputCustom"


export default function DatePickerCustom({ InputCustomProps, ...props }) {

    return <MobileDatePicker
        inputFormat="dd/MM/yyyy"
        {...props}
        renderInput={({ inputRef, inputProps }) => {
            return <InputCustom
                // containerRef={params.InputProps.ref}
                // placeholder="Sarjana Ilmu Komputer"
                // title="Apakah Gelar atau kualifikasi anda?"
                inputProps={inputProps}
                inputRef={inputRef}
                {...InputCustomProps}
            />
        }
        }
    />
}