import { Container, Grid, IconButton, Button } from "@mui/material";

// Pages
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

function PageNav({ onSave, loading }) {
  // theme

  return (
    <Container
      sx={{ padding: "0px !important", position: "sticky", left: 0, bottom: 0, width: "100%", height: "50px", background: "#fafafa", zIndex: 2, flexGrow: 1, display: "flex", alignItems: "center", margin: "auto" }}>
      <Grid alignItems="center" container >
        <Grid item xs={3} sx={{ paddingLeft: "0 !important", textAlign: "center" }}>
          <IconButton disabled={loading}><ChevronLeftRoundedIcon /></IconButton>
        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: "0 !important", }} >
          <Button sx={{ width: "100%" }} variant="contained" size="medium" onClick={onSave} disabled={loading}>Simpan</Button>
        </Grid>
        <Grid item xs={3} sx={{ paddingLeft: "0 !important", textAlign: "center" }}>
          <IconButton disabled={loading}><ChevronRightRoundedIcon /></IconButton>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PageNav;