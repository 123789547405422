import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { InfoOutlined } from "@mui/icons-material";

export default function AlertDialog({
  title,
  description,
  open,
  handleClose,
  handleYes,
  loading,
  type,
}) {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      {type === "ok" ? (
        <>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CheckCircleOutline
              color="success"
              fontSize="large"
              sx={{ fontSize: "50px" }}
            />
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                color: theme.palette.neutrals.n80,
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              {title}
            </DialogTitle>
          </DialogContent>
          <DialogActions sx={{ margin: "0px 16px 16px" }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              disabled={loading}
              onClick={handleYes}
              sx={{ fontWeight: "bold" }}
              autoFocus
            >
              Kembali
            </Button>
          </DialogActions>
        </>
      ) : type === "ask" ? (
        <>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <InfoOutlined
              color="info"
              fontSize="large"
              sx={{ fontSize: "50px" }}
            />
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                color: theme.palette.neutrals.n80,
                fontWeight: "bold",
                textAlign: "center",
                fontSize: 15,
              }}
            >
              {title}
            </DialogTitle>
          </DialogContent>
          <DialogActions sx={{ margin: "0px 16px 16px" }}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              disableElevation
              disabled={loading}
              onClick={handleClose}
              sx={{
                fontWeight: "bold",
                border: "1.4px solid",
                borderColor: "inherit",
                "&:hover": { border: "1.4px solid", borderColor: "inherit" },
              }}
              autoFocus
            >
              Batal
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              disabled={loading}
              onClick={handleYes}
              sx={{ fontWeight: "bold" }}
              autoFocus
            >
              Ya
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={handleClose}>
              Batal
            </Button>
            <Button disabled={loading} onClick={handleYes} autoFocus>
              Ya
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
