// Raect
import React, { useContext, useState } from "react";

// Material UI
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";

// Firebase
import { useFunctionsCall } from "@react-query-firebase/functions";
import { functions } from "./FirebaseProvider";

// Create Context
const FeedbackContext = React.createContext();

// Use Context
export function useFeedback() {
  return useContext(FeedbackContext);
}

// Provider
export default function FeedbackProvider(props) {
  const { mutateAsync: sendFeedback, isLoading: isLoadingFeedback } =
    useFunctionsCall(functions, "feedback-createTask");

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");

  const [form, setForm] = useState({
    satisfied: "",
    description: "",
    type: "feedback",
  });

  const [errors, setErrors] = useState({
    satisfied: "",
    description: "",
    type: "",
  });

  const openFeedback = (value) => () => {
    // Set Dialog Open
    setOpen(true);

    // Set Title Dialog
    setTitle(value);
  };

  const handleSubmit = async () => {
    let findErrors = {};

    if (!form.satisfied) {
      findErrors.satisfied = "Jawaban wajib diisi.";
    }

    if (!form.description) {
      findErrors.description = "Masukkan wajib diisi.";
    }

    if (!form.type) {
      findErrors.type = "Tipe wajib diisi.";
    }

    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      try {
        await sendFeedback(form);

        setOpen(false);
        setForm({ satisfied: "", description: "", type: "feedback" });
        setErrors({ satisfied: "", description: "" });
        setTitle("");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const desc = {
    feedback: "Ketikkan masukkanmu dibawah.",
    suggestion: "Ketikkan saranmu dibawah.",
    bugs: "Ketikkan kendalamu dibawah. Jelaskan dengan detail sebisamu agar dapat membantu kami untuk memahami kendala yang kamu alami.",
  };

  return (
    <FeedbackContext.Provider value={{ openFeedback }}>
      {props.children}
      <Dialog open={open} maxWidth="sm">
        <DialogTitle>Masukkan Untuk {title}</DialogTitle>
        <DialogContent dividers>
          {/* Title */}
          <Typography>
            Terimakasih sudah meluangkan waktu untuk memberikan masukkan.
          </Typography>

          <Box sx={{ marginTop: "16px" }}>
            <Typography
              sx={{ marginBottom: "10px", color: "textColor.neutral80" }}
            >
              Pilih tipe masukkan yang akan kamu berikan.
            </Typography>

            <FormControl error={errors.type ? true : false} fullWidth>
              <Select
                name="type"
                size="small"
                value={form.type}
                onChange={(e) => {
                  setForm((old) => ({
                    ...old,
                    [e.target.name]: e.target.value,
                  }));

                  setErrors((error) => ({
                    ...error,
                    [e.target.name]: "",
                  }));
                }}
                error={errors.type ? true : false}
              >
                <MenuItem value="feedback">Masukkan</MenuItem>
                <MenuItem value="suggestion">Saran</MenuItem>
                <MenuItem value="bugs">Laporkan Masalah</MenuItem>
              </Select>

              {errors?.type ? (
                <FormHelperText error>{errors?.type}</FormHelperText>
              ) : null}
            </FormControl>
          </Box>

          {/* Input Desc */}
          <Box sx={{ marginTop: "16px" }}>
            <Typography
              sx={{ marginBottom: "10px", color: "textColor.neutral80" }}
            >
              {desc[form?.type]}
            </Typography>
            <TextField
              name="description"
              fullWidth
              minRows={4}
              maxRows={6}
              size="small"
              multiline
              value={form.description}
              onChange={(e) => {
                setForm((old) => ({
                  ...old,
                  [e.target.name]: e.target.value,
                }));

                setErrors((error) => ({
                  ...error,
                  [e.target.name]: "",
                }));
              }}
              disabled={isLoadingFeedback}
              helperText={errors.description}
              error={errors.description ? true : false}
            />
          </Box>

          {/* Input Satisfied | Not Satisfied */}
          <Box sx={{ marginTop: "16px" }}>
            <Typography
              sx={{ marginBottom: "10px", color: "textColor.neutral80" }}
            >
              Apakah kamu puas dengan pengalamanmu bersama karir.ai?
            </Typography>
            <FormControl disabled={isLoadingFeedback}>
              <RadioGroup
                aria-labelledby="choice-feedback"
                name="satisfied"
                value={form.satisfied}
                onChange={(e) => {
                  setForm((old) => ({
                    ...old,
                    [e.target.name]: e.target.value,
                  }));

                  setErrors((error) => ({
                    ...error,
                    [e.target.name]: "",
                  }));
                }}
                disabled={isLoadingFeedback}
              >
                <FormControlLabel
                  value="satisfied"
                  control={<Radio size="small" />}
                  label="Ya"
                />
                <FormControlLabel
                  value="notSatisfied"
                  control={<Radio size="small" />}
                  label="Tidak"
                />
              </RadioGroup>
              {errors.satisfied ? (
                <FormHelperText error={errors.satisfied ? true : false}>
                  {errors.satisfied}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "capitalize" }}
            size="small"
            color="inherit"
            onClick={() => {
              setOpen(false);
              setForm({ satisfied: "", description: "", type: "feedback" });
              setErrors({ satisfied: "", description: "" });
              setTitle("");
            }}
            disabled={isLoadingFeedback}
          >
            Batal
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            size="small"
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoadingFeedback}
          >
            Kirim
          </Button>
        </DialogActions>
      </Dialog>
    </FeedbackContext.Provider>
  );
}
