// React
import React from "react";

// Styles
// import useStyles from "./styles/dialog";

// MUI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Warning from "@mui/icons-material/Warning";
import { CircularProgress, useTheme } from "@mui/material";
// import CheckCircle from "@material-ui/icons/CheckCircle";

export default function BuyDialog({
  open,
  handleClose,
  name,
  price,
  callFunction,
  loading,
  enable,
}) {
  const theme = useTheme();
  // console.log(loading)
  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
        open={open}
        maxWidth="xs"
        fullWidth
        disableEscapeKeyDown
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Warning color="primary" fontSize="large" />
          <p
            style={{
              fontSize: 16,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Anda yakin akan membeli <br />{" "}
            <span style={{ color: theme.palette.success.main }}>{name}</span>
            <br />
            seharga{" "}
            <span style={{ color: theme.palette.secondary.main }}>
              {price} Kredit
            </span>{" "}
            ?
          </p>
        </DialogContent>
        {loading ? (
          <DialogActions
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={24} sx={{ marginRight: "6px" }} />
            Loading...
          </DialogActions>
        ) : (
          <DialogActions sx={{ padding: "20px" }}>
            <Button
              sx={{
                fontSize: 14,
                fontWeight: "bold",
                textTransform: "capitalize",
                border: (theme) => "1px solid" + theme.palette.primary.main,
                "&:hover": {
                  border: (theme) => "1px solid" + theme.palette.primary.main,
                },
              }}
              onClick={handleClose}
              color="primary"
              variant="outlined"
              disableElevation
              fullWidth
              disabled={loading}
            >
              Tidak
            </Button>
            <Button
              sx={{
                fontSize: 14,
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
              onClick={callFunction && callFunction}
              color="primary"
              variant="contained"
              disableElevation
              fullWidth
              disabled={loading}
            >
              {enable ? "Kerjakan" : "Beli"}
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {/* Dialog Setelah Pembelian Sukses */}
      {/* <Dialog
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
        open={openOk}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CheckCircle color="primary" fontSize="large" />
          <p className={classes.text}>
            Anda telah membeli <br />{" "}
            <span className={classes.name}>{name}</span>
            <br />
            <br />
            Selamat Mengerjakan
          </p>
        </DialogContent>
        <DialogActions className={classes.btc}>
          <Button
            className={classes.btn2}
            onClick={handleCloseOk}
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
          >
            Kembali
          </Button>
        </DialogActions>
      </Dialog> */}
    </React.Fragment>
  );
}
