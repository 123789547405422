import React, { useCallback, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

// import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import SaveOutlined from "@mui/icons-material/SaveOutlined";
import SendOutlined from "@mui/icons-material/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { InputCustom } from "../../components";
import AlertDialog from "../../components/AlertDialog";
import {
    firestore,
    functions,
    useFirebase,
} from "../../contexts/FirebaseProvider";
import {
    doc,
} from "firebase/firestore";
import {
    useFirestoreDocumentData,
} from "@react-query-firebase/firestore";
import { Card, Checkbox, Collapse, Grid, Link, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { Search } from "@mui/icons-material";
import axios from "axios";

// const greetings = [
//   "Yth. Bapak/Ibu HR",
//   "Yth. Agen Rekruiter",
// ]
// const sendTypes = ["email", "wa", "url"];

const initialState = {
    jobTitle: "",
    jumlah: "",

};

function ApplicationCreateAuto() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const extension = searchParams.get("extension") ?? false;
    const posisi = searchParams.get('query') ?? false;
    const [open, setOpen] = useState(false);
    const [openAuth, setOpenAuth] = useState(false);
    const [txt] = useState("");
    const [form, setForm] = useState(initialState);
    const [errors, setErrors] = useState({});
    // const [recipientList, setRecipientList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    // query cv
    const { user, userData } = useFirebase();



    const { mutateAsync: searchJob, isLoading: loadingSearch } = useFunctionsCall(
        functions,
        "job-getEmailCollection"
    );

    // job-scheduleAutoLamar
    const { mutateAsync: scheduleAutoLamar, isLoading: loadScheduleAutoLamar } = useFunctionsCall(
        functions,
        "job-scheduleAutoLamar"
    );



    // load credential
    const credRef = doc(firestore, "credentials", user?.uid);
    const { data: credential, isLoading: credentialLoading } =
        useFirestoreDocumentData(["credentials", user?.uid], credRef, {
            subscribe: true,
            idField: "id",
        });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setForm((form) => ({
            ...form,
            [name]: value,
        }));

        setErrors((errors) => ({
            ...errors,
            [name]: "",
        }));
    };

    // chseck
    const [checkAll, setCheckAll] = useState(false)
    const [checkList, setChcekList] = useState([]);

    const handleCheckAll = (e) => {
        // console.log(e.target.checked)
        if (e.target.checked) {
            setChcekList(dataSearch)
            setCheckAll(e.target.checked)
        } else {
            setChcekList([])
            setCheckAll(e.target.checked)
        }

    }

    const handeCheckList = (value, i) => (e) => {
        const currentIndex = checkList.indexOf(value);
        const newChecked = [...checkList];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChcekList(newChecked);

    }

    console.log(checkList)


    // search
    const [dataSearch, setDataSeacrh] = useState();
    const handleSubmit = async () => {
        try {
            await scheduleAutoLamar({
                jobs: [...checkList]
            });

            // pixel
            await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=karirhack_createAuto&email=${userData?.email}`);
            // handleChange({
            //   target: {
            //     name: "message",
            //     value: data,
            //   },
            // });
            // console.log(data)
            enqueueSnackbar('autolamar berhasil dijadwalkan', { variant: 'success' })
            // setDataSeacrh(data);
            navigate("/application")
        } catch (error) {
            enqueueSnackbar(`autolamar gagal, ${error.message}`, { variant: 'error' })
            console.log(error);
        }
    };

    const onSearch = useCallback(async () => {
        if (credentialLoading || loadingSearch) {
            return false;
        }

        if (!credential?.google?.refresh_token) {
            return setOpenAuth(true);
        }
        let findErrors = {};



        if (!form.jobTitle) {
            findErrors.jobTitle = "Job title wajib diisi.";
        }


        if (!form.jumlah) {
            findErrors.jumlah = "jumlah wajib diisi.";
        }



        if (Object.values(findErrors).some((message) => message !== "")) {
            setErrors(findErrors);
        } else {
            try {
                const { data } = await searchJob({
                    jobTitle: form?.jobTitle,
                    topK: parseInt(form?.jumlah),
                });
                // pixel
                await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=karirhack_searchJob&email=${userData?.email}`);

                // handleChange({
                //   target: {
                //     name: "message",
                //     value: data,
                //   },
                // });
                // console.log(data)
                setDataSeacrh(data);
            } catch (error) {
                console.log(error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.jobTitle, form.jumlah, userData?.email]);


    const [showMore, setShowMore] = useState([]);

    const handleShow = (value, i) => (e) => {
        const currentIndex = showMore.indexOf(value);
        const newChecked = [...showMore];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setShowMore(newChecked);

    }


    useEffect(() => {
        if (posisi) {
            setForm({
                // ...form,
                jobTitle: posisi,
                jumlah: 100
            })
            onSearch()
        }
    }, [onSearch, posisi])





    return (
        <>
            <Box>
                {/* <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          value={form.greeting}
          onChange={(e, value) => {
            handleChange({
              target: {
                name: 'greeting',
                value
              }
            })
          }}
          id="pilih-sapaan"
          options={greetings}
          renderInput={(params) => (
            <InputCustom
              containerRef={params.InputProps.ref}
              placeholder="Sapaan"
              title="Sapaan"
              inputProps={params.inputProps}
              fullWidth
              error={errors?.greeting ? true : false}
              helperText={errors?.greeting}
            />
          )}
        /> */}
                <InputCustom
                    id="job-title"
                    name="jobTitle"
                    title="Posisi / Pekerjaan yang akan dilamar"
                    placeholder="Contoh: Staff Admin"
                    value={form.jobTitle}
                    onChange={handleChange}
                    variant="outlined"
                    error={errors?.jobTitle ? true : false}
                    helperText={errors?.jobTitle}
                />
                <InputCustom
                    id="jumlah"
                    name="jumlah"
                    title="Jumlah Pencarian (max. 1000)"
                    placeholder="Contoh: 100"
                    value={form.jumlah}
                    onChange={handleChange}
                    variant="outlined"
                    error={errors?.jumlah ? true : false}
                    helperText={errors?.jumlah}
                />
                <div>
                    <LoadingButton
                        loading={loadingSearch}
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        endIcon={<Search style={{ marginLeft: '-6px', marginTop: '4px' }} />}
                        disableElevation
                        sx={{
                            textTransform: "none",
                            marginTop: "30px",
                            fontWeight: "bold",
                        }}
                        onClick={onSearch}
                    >
                        Cari
                    </LoadingButton>
                </div>
                {
                    dataSearch &&
                    <>
                        <Grid container >
                            <Grid item xs={8} sx={{}}>
                                <Typography sx={{
                                    marginTop: '20px',
                                    marginLeft: '10px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: theme.palette.neutrals.n60
                                }}>Hasil Pencarian ({dataSearch?.length})</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', paddingRight: '25px' }}>
                                <Typography sx={{
                                    marginTop: '20px',
                                    // marginLeft: '10px',
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    color: theme.palette.neutrals.n60
                                }}>
                                    Pilih Semua
                                </Typography>
                                <Checkbox
                                    sx={{
                                        float: 'right', marginTop: '7px',
                                    }}
                                    size="large"
                                    edge="end"
                                    // name={search?.nama}
                                    checked={checkAll}
                                    onChange={handleCheckAll}
                                    disableRipple
                                // inputProps={{ 'aria-labelledby': search?.id }}
                                // defaultChecked
                                />
                            </Grid>
                        </Grid>
                        {
                            dataSearch?.map?.((search) => {
                                // console.log(search.company_email?.split('@')?.[0]?.length)
                                // var c = 'haaasu@gamil.com'
                                var a = search.company_email?.split("@");
                                var b = a[0];
                                var newstr = "";
                                for (var i in b) {
                                    if (i > b.length / 2) newstr += "*";
                                    else newstr += b[i];
                                }
                                // console.log(newstr + "@" + a[1]);
                                return <Card
                                    key={search?.id}
                                    sx={{ padding: '15px', margin: '10px' }}
                                // secondaryAction={
                                //   <IconButton edge="end" aria-label="comments">
                                //     <CommentIcon />
                                //   </IconButton>
                                // }
                                // disablePadding
                                >
                                    <Grid container >
                                        <Grid item xs={11} sx={{}}>
                                            <div style={{ display: 'flex' }}>
                                                <Typography sx={{
                                                    color: theme.palette.primary.main,
                                                    marginRight: '5px',
                                                }}>Nama Perusahaan </Typography>
                                                <Typography sx={{
                                                    color: theme.palette.primary.main,
                                                    marginRight: '5px',
                                                    // maxWidth: '400px'
                                                }}>:</Typography>
                                                <Typography sx={{
                                                    color: theme.palette.neutrals.n60
                                                }}>  {search?.company_name}</Typography>
                                            </div>
                                            <div style={{ display: 'flex' }}>

                                                <Typography sx={{
                                                    color: theme.palette.primary.main,
                                                    marginRight: '5px',
                                                }}>Email  </Typography>
                                                <Typography sx={{
                                                    color: theme.palette.primary.main,
                                                    marginRight: '5px',
                                                    // maxWidth: '400px'
                                                }}>:</Typography>
                                                <Typography sx={{
                                                    color: theme.palette.neutrals.n60,
                                                    // maxWidth: '10px'
                                                }}>{newstr + "@" + a[1]}</Typography>
                                            </div>
                                            {/* <div style={{ display: 'flex' }}> */}

                                            <Typography sx={{
                                                color: theme.palette.primary.main,
                                                marginRight: '5px',
                                                // maxWidth: '400px'
                                            }}>Lowongan :</Typography>
                                            {/* <Typography sx={{
                                                color: theme.palette.primary.main,
                                                marginRight: '5px',
                                                // maxWidth: '400px'
                                            }}>:</Typography> */}
                                            {/* <div style={{ display: 'flex' }}> */}
                                            {/* <Typography sx={{
                                                color: theme.palette.neutrals.n60
                                            }}>{search?.text?.substring(0, 100)}</Typography> */}
                                            <Collapse sx={{
                                                color: theme.palette.neutrals.n60
                                            }} in={showMore?.indexOf?.(search?.text) !== -1} collapsedSize={50}>{search?.text}</Collapse>
                                            <Link component="button" underline="none" onClick={handleShow(search?.text)}>{showMore?.indexOf?.(search?.text) !== -1 ? 'Show Less' : 'Show More'}</Link>

                                            {/* </div> */}
                                        </Grid>
                                        {/* <Grid item xs={7.5}>
                                           
                                           
                                           
                                        </Grid> */}
                                        <Grid item xs={1}>
                                            <Checkbox
                                                // sx={{ float: 'right' }}
                                                // size="medium"
                                                edge="end"
                                                // name={search?.nama}
                                                checked={checkList.indexOf(search) !== -1}
                                                onChange={handeCheckList(search)}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': search?.id }}
                                                defaultChecked
                                            />
                                        </Grid>
                                    </Grid>


                                </Card>
                            })
                        }
                        <div style={{ display: "flex", gap: "20px" }}>

                            <LoadingButton
                                // disabled
                                loading={loadScheduleAutoLamar}
                                fullWidth
                                color="primary"
                                size="large"
                                variant="contained"
                                startIcon={<SendOutlined />}
                                disableElevation
                                sx={{
                                    textTransform: "none",
                                    marginTop: "30px",
                                    fontWeight: "bold",
                                    marginBottom: '80px'
                                }}
                                onClick={handleSubmit}
                            >
                                SIMPAN DAN JADWALKAN
                            </LoadingButton>
                        </div>
                    </>
                }

            </Box>

            <AlertDialog
                title={txt}
                type="ok"
                open={open}
                handleClose={() => setOpen(false)}
                handleYes={() => {
                    setOpen(false);
                    navigate("/application");
                }}
            />
            <AlertDialog
                title="Untuk mengirim email atas nama kamu, karir.ai memerlukan autentikasi ke akun email kamu."
                type="ask"
                open={openAuth && !credential?.google?.refresh_token}
                handleClose={() => setOpenAuth(false)}
                handleYes={() => {
                    if (extension) {
                        window.open(`${window.location.origin}/settings/gmail`, "_blank");
                    } else {
                        navigate("/settings/gmail");
                    }
                }}
            />
        </>
    );
}

export default ApplicationCreateAuto;
