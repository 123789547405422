import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect, useState } from "react";
import { firestore, storage, useFirebase } from "../../contexts/FirebaseProvider";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { addDoc, collection, FieldValue, serverTimestamp } from "firebase/firestore";
import { useSnackbar } from "notistack";
import axios from "axios";

export default function DialogAttachment({ dialog: { open, mode, data }, handleClose }) {

    const { user, userData } = useFirebase();
    // console.log(mode)
    const [form, setForm] = useState({
        nama: '',
        url: '',
        nama_file_bawaan: '',
        type: ''
    });
    const [error, setError] = useState({
        nama: '',
        url: '',
        nama_file_bawaan: '',
        type: ''
    })
    // console.log(data)

    useEffect(() => {
        const defaultForm = {
            nama: '',
            url: '',
            nama_file_bawaan: '',
            type: ''
        }
        if (mode === 'edit') {
            setForm({
                ...data,
                nama_file_bawaan: data?.nama + data?.type
            })
        } else if (mode === 'tambah') {
            setForm(defaultForm)
            setError(defaultForm)
        }
    }, [data, mode])
    // const handleChange = (e) => {
    //     setForm({
    //         ...form,
    //         [e.target.name]: e.target.value
    //     });

    //     setError({
    //         ...form,
    //         [e.target.name]: ''
    //     })
    // }

    const [isSubmitting, setSubmitting] = useState(false);

    const handleUploadFile = async (e) => {
        const file = e.target.files[0];

        // if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {

        //     setError(errorDua => ({
        //         ...errorDua,
        //         url: `Tipe file tidak didukung: ${file.type}`
        //     }))
        // }
        // else if (file.size >= 512000) {
        //     setError(errorDua => ({
        //         ...errorDua,
        //         url: `Ukuran file terlalu besar > 500KB`
        //     }))
        // } else {

        const reader = new FileReader();

        reader.onabort = () => {

            setError(errorDua => ({
                ...errorDua,
                url: `Proses pembacaan file dibatalkan`
            }))
        }

        reader.onerrorDua = () => {

            setError(errorDua => ({
                ...errorDua,
                url: 'File tidak bisa dibaca'
            }))
        }

        reader.onload = async () => {
            setError(errorDua => ({
                ...errorDua,
                url: ''
            }))
            setSubmitting(true);
            try {
                // console.log(file.type)
                const bannerStorageRef = ref(storage, `attachment/${user?.uid}`);

                const urlExt = file.name.substring(file.name.lastIndexOf('.'));
                const namaFile = form?.nama ? `${form?.nama}${urlExt}` : file.name
                const urlRef = ref(bannerStorageRef, namaFile);
                const uploadTask = await uploadBytesResumable(urlRef, file);

                const url = await getDownloadURL(uploadTask.ref)
                // console.log(url)

                // const urlSnapshot = await urlRef.putString(reader.result, 'data_url');


                // const urlUrl = await urlSnapshot.ref.getDownloadURL();

                setForm(currentForm => ({
                    ...currentForm,
                    url: url,
                    nama_file_bawaan: file.name,
                    type: urlExt
                }));

                // setSomethingChange(true);
            } catch (e) {
                console.log(e.message)
                setError(errorDua => ({
                    ...errorDua,
                    url: e.message
                }))


            }

            setSubmitting(false);

        }

        reader.readAsDataURL(file);

        // }
    }
    // console.log(form)
    const { enqueueSnackbar } = useSnackbar();

    const validate = async () => {
        const newError = { ...error };



        // if (!form.nama) {
        //     newError.nama = 'nama harus diisi';
        // }
        if (!form.url) {
            newError.url = 'pilih file terlebih dahulu';
        }


        return newError;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();



        const findError = await validate();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            try {
                setSubmitting(true);
                await addDoc(collection(firestore, "users", `${user?.uid}`, "email_attachment"), {
                    nama: form?.nama_file_bawaan,
                    url: form?.url,
                    type: form?.type,
                    createdAt: serverTimestamp(FieldValue)
                });

                // pixel
                await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=karirhack_settingsGmailAttachment&email=${userData?.email}`);

                enqueueSnackbar('File attachment berhasil ditambahkan', { variant: 'success' });
                setSubmitting(false)
                handleClose()
            } catch (e) {
                console.log(e.message)
                enqueueSnackbar(`File attachment gagal ditambahkan,${e.message}`, { variant: 'error' })

            }
        }



    }

    return <Dialog
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>Tambah Attachment</DialogTitle>
        <DialogContent>
            {/* <TextField
                fullWidth
                id="nama"
                name="nama"
                label="Nama File"
                variant="outlined"
                value={form?.nama}
                onChange={handleChange}
                error={error?.nama ? true : false}
                helperText={error?.nama}
            /> */}
            <input
                style={{ display: 'none' }}
                id="upload-url-file"
                type='file'
                onChange={handleUploadFile}
            ></input>
            <label htmlFor="upload-url-file">
                <Button
                    sx={{
                        display: 'flex',
                        marginTop: '10px',
                        justifyContent: 'center',
                        // alignItems: 'center'
                    }}
                    disabled={isSubmitting}
                    name="url"
                    endIcon={<DriveFolderUploadIcon />}
                    variant="outlined"
                    component="span"
                >
                    Pilih File
                </Button>
            </label>
            {
                form?.nama_file_bawaan &&
                <Typography sx={{ textAlign: 'center' }} variant="subtitle2">{form?.nama_file_bawaan}</Typography>
            }
            {
                error?.url &&
                <Typography sx={{ textAlign: 'center', color: 'red' }} variant="subtitle2">{error?.url}</Typography>
            }


        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
            >Batal</Button>

            <Button
                onClick={handleSubmit}
            >Tambah</Button>
        </DialogActions>
    </Dialog>
}