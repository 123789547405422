// React
import React, { useEffect, useRef, useState } from "react";

// Components
import {
  InputCustom,
  DateRangeCustom,
  // RichTextEditorCustom,
  SavedItem,
  SwitchCustom,
} from "../../components";

// React Router Dom
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

// Context
import { subCollectionRef } from "../../contexts/FirebaseProvider";

// React Query
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
  useFirestoreDocumentData,
} from "@react-query-firebase/firestore";

// Draft JS
// import { EditorState, convertToRaw, ContentState } from "draft-js";

// // Draft To HTML
// import draftToHtml from "draftjs-to-html";

// // HTML To Draft
// import htmlToDraft from "html-to-draftjs";

// Firebase
import { deleteDoc, serverTimestamp } from "firebase/firestore";

// Bottom Sheet
import { BottomSheet } from "react-spring-bottom-sheet";

// MUI
import {
  Button,
  Container,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";

// Initial State
let initialState = {
  jobTitle: "",
  companyName: "",
  startDate: null,
  endDate: null,
  // workingLocation: "",
  jobDescription: "",// EditorState.createEmpty(),
  isOnGoing: false,
};

let subCollection = "workExperiences";
const title = "Pengalaman Bekerja";
function WorkExperiencePage() {
  // Context
  const { docRef } = useOutletContext();

  // Params
  const params = useParams();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") ?? "default";

  // Navigate
  const navigate = useNavigate();

  // Ref
  const ref = subCollectionRef(params.cvId, subCollection);

  // Mutate
  const { mutate: addWorkExperience, isLoading: addLoading } =
    useFirestoreCollectionMutation(ref);

  const { mutate: updateWorkExperience, isLoading: updateLoading } =
    useFirestoreDocumentMutation(docRef(ref, id), {
      merge: true,
    });

  // Get Data
  const { data: list } = useFirestoreQueryData(subCollection, ref, {
    subscribe: true,
    idField: "id",
  });

  const { data: item } = useFirestoreDocumentData(
    [subCollection, id],
    docRef(ref, id),
    {
      subscribe: true,
      idField: "id",
    }
  );

  // State
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("create");

  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);

  // Ref
  const bottomsheetRef = useRef();

  // snack
  const { enqueueSnackbar } = useSnackbar();

  // Handler
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
    setErrors(errors => ({
      ...errors,
      [name]: ''
    }))
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setForm((form) => ({
        ...form,
        startDate: value,
      }));
      setErrors(errors => ({
        ...errors,
        startDate: ''
      }))
    } else {
      setForm((form) => ({
        ...form,
        endDate: value,
      }));
      setErrors(errors => ({
        ...errors,
        endDate: ''
      }))
    }
  };

  const handleDismissButtomsheet = () => {
    navigate({
      search: "",
    });
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }
    navigate({
      search: `?id=new`,
    });

  };

  const handleUpdate = () => {
    if (updateLoading) {
      return;
    }

    const {
      jobTitle,
      companyName,
      startDate,
      endDate,
      // workingLocation,
      jobDescription,
      isOnGoing,
    } = form;

    let findErrors = {};
    if (!jobTitle) {
      findErrors.jobTitle = "Jabatan wajib diisi."
    }

    if (!companyName) {
      findErrors.companyName = "Nama perusahaan wajib diisi."
    }

    if (!startDate) {
      findErrors.startDate = "Tanggal mulai wajib diisi.";
    }

    if (!isOnGoing && !endDate) {
      findErrors.endDate = "Tanggal berhenti wajib diisi."
    }

    if (!jobDescription) {
      findErrors.jobDescription = "Deskripsi pekerjaan wajib diisi."
    }

    if (Object.values(findErrors).some(message => message !== "")) {
      setErrors(findErrors);
    } else {

      if (mode === 'create') {

        addWorkExperience(
          {
            jobTitle,
            companyName,
            startDate,
            endDate,
            // workingLocation,
            jobDescription,
            isOnGoing,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess(data) {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, { variant: 'success' })
            },
          }
        );
      } else {
        updateWorkExperience(
          {
            jobTitle,
            companyName,
            startDate,
            endDate,
            // workingLocation,
            jobDescription,
            isOnGoing,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, { variant: 'success' })
            },
          }
        );
      }
    }
  };

  const handleSwitchChange = (e) => {
    const { checked, name } = e.target;
    setForm((form) => ({
      ...form,
      [name]: checked,
    }));
    if (name === 'isOnGoing') {
      setErrors(errors => ({
        ...errors,
        endDate: ''
      }))
    }
  };

  // UseEffect
  useEffect(() => {
    if (item) {
      const { startDate, endDate, ...prev } = item;

      // const contentBlock = htmlToDraft(jobDescription || "");
      // let savedEditorState = EditorState.createEmpty();
      // if (contentBlock) {
      //   const contentState = ContentState.createFromBlockArray(
      //     contentBlock.contentBlocks
      //   );
      //   savedEditorState = EditorState.createWithContent(contentState);
      // }

      setForm({
        ...initialState,
        ...prev,
        startDate: startDate?.toDate?.() ?? null,
        endDate: endDate?.toDate?.() ?? null,
      });
    }
  }, [item]);

  useEffect(() => {
    if (id === 'new') {
      setMode('create');
    } else if (id !== 'default') {
      setMode('update');
    }
  }, [id]);

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  const handleDelete = async (selectedId) => {

    return await deleteDoc(docRef(ref, selectedId));
  }

  return (
    <>
      {/* Saved Item */}
      <SavedItem
        type={title}
        list={list}
        titleField="jobTitle"
        subtitleField="companyName"
        // subtitleField={[["companyName", "string", ", "], ["startDate", "year", " - "], ["endDate", "year", ""]]}
        activeId={id}
        handleDelete={handleDelete}
        handleAdd={handleAdd}
        addLoading={addLoading}
      />
      {/* <div style={{ paddingTop: '56.25%', position: 'relative', }}>
        <iframe title="pengalaman kerja" src="https://player.vdocipher.com/v2/?otp=20160313versASE323KQl0K0jWbz6azug7Q5lq5pFORdRFLTuWq050i5mIVwM5Xw&playbackInfo=eyJ2aWRlb0lkIjoiYjFiNDE1NzhmYTk2NGM4YjhjMjE5MTBhODU0ZmRhZjgifQ==" style={{ border: 0, maxWidth: '100%', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', }} allowFullScreen="true" allow="encrypted-media"></iframe>
      </div> */}
      {/* Bottom Sheet */}
      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs">
          {/* Input Jabatan */}
          <InputCustom
            title="Apakah Jabatan anda pada perusahaan?"
            placeholder="Web Designer"
            name="jobTitle"
            onChange={handleChange}
            value={form.jobTitle}
            required
            error={errors?.jobTitle ? true : false}
            helperText={errors?.jobTitle}
          />

          {/* Input Perusahaan */}
          <InputCustom
            title="Apa Nama perusahaan tempat anda bekerja?"
            placeholder="Google"
            name="companyName"
            onChange={handleChange}
            value={form.companyName}
            required
            error={errors?.companyName ? true : false}
            helperText={errors?.companyName}
          />

          {/* Input Range */}
          <DateRangeCustom
            title="Kapan anda mulai bekerja?"
            onChangeStart={(value) => handleDateChange(value, "start")}
            valueStart={form.startDate}
            onChangeEnd={(value) => handleDateChange(value, "end")}
            valueEnd={form.endDate}
            disabledEnd={form.isOnGoing}
            placeholderEnd={form.isOnGoing ? "Sekarang" : "End Date"}
            errorStart={errors?.startDate ? true : false}
            errorEnd={errors?.endDate ? true : false}
            helperTextStart={errors?.startDate}
            helperTextEnd={errors?.endDate}
          />

          {/* Switch isOnGoin */}
          <Box sx={{ marginTop: 1 }}>
            <FormControlLabel
              label="Saya masih bekerja di perusahaan ini."
              control={
                <SwitchCustom
                  sx={{ m: 1 }}
                  name="isOnGoing"
                  checked={form.isOnGoing}
                  onChange={handleSwitchChange}
                />
              }
            />
          </Box>

          {/* Input Alamat */}
          {/* <InputCustom
            title="Dimana alamat perusahaan anda?"
            placeholder="Jakarta"
            name="workingLocation"
            onChange={handleChange}
            value={form.workingLocation}
          /> */}

          {/* Input Rich Text */}
          {/* <RichTextEditorCustom
            title="Jelaskan apa saja yang anda kerjakan"
            editorState={form.jobDescription}
            onEditorStateChange={(value) => {
              setForm((form) => ({
                ...form,
                jobDescription: value,
              }));
            }}
          /> */}

          <InputCustom
            title="Jelaskan apa saja yang anda kerjakan!"
            placeholder="Membuat UI aplikasi..."
            name="jobDescription"
            onChange={handleChange}
            value={form.jobDescription}
            multiline
            rows={5}
            error={errors?.jobDescription ? true : false}
            helperText={errors?.jobDescription}
          />
        </Container>
      </BottomSheet>
    </>
  );
}

export default WorkExperiencePage;
