import logo from "../../assets/icon/logo-google.svg";
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useFirestoreDocumentData, useFirestoreDocumentDeletion, useFirestoreQueryData, } from "@react-query-firebase/firestore";
import { collection, deleteDoc, doc, FieldValue, getDoc, orderBy, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import AlertDialog from "../../components/AlertDialog";
import AppLoading from "../../components/AppLoading";
import Title from "../../components/Title";
import { cvsCollectionRef, firestore, storage, useFirebase } from "../../contexts/FirebaseProvider";
import DialogAttachment from "./dialogAttachment";
import { Delete, Edit, SendOutlined, Visibility } from "@mui/icons-material";
import { deleteObject, ref } from "firebase/storage";
import { InputCustom } from "../../components";
import formatDate from "../../utils/formatDate";
import { LoadingButton } from "@mui/lab";
import axios from "axios";

function GmailSetting() {
    const [confirm, setConfirm] = useState(false);
    const { handleConnectGmailAccount, user, userData } = useFirebase();
    const { enqueueSnackbar } = useSnackbar()
    const credRef = doc(firestore, "credentials", user?.uid);
    const { mutate: deleteCredential } = useFirestoreDocumentDeletion(credRef);
    const { data: credential, isLoading: credentialLoading } = useFirestoreDocumentData(
        ["credentials", user?.uid],
        credRef,
        {
            subscribe: true,
            idField: 'id'
        }
    );

    const deleteAccessSendEmail = () => {
        setConfirm(false);
        deleteCredential(null, {
            onSuccess() {
                enqueueSnackbar("Akses kirim email berhasil dihapus.", { variant: 'success' })
            },
            onError() {
                enqueueSnackbar("Gagal menghapus akses.", { variant: "error" })
            }
        })
    }

    // Get Data
    const refAtt = collection(firestore, 'users', `${user?.uid}`, 'email_attachment')
    const { data: list } = useFirestoreQueryData('email_attachment', refAtt, {
        subscribe: true,
        idField: "id",
    });
    // console.log(user)

    // dialog attachment
    const [dialogAttch, setDialogAttch] = useState({
        open: false,
        data: {},
        mode: ''
    });

    // form default
    const [form, setForm] = useState({
        jobTitle: '',
        subject: '',
        cvId: '',
        message: `Dengan hormat Bpk/Ibu Manager HRD {nama_perusahaan},

Berdasarkan informasi lowongan yang saya dapatkan dari portal lowongan kerja, saya ingin menyampaikan ketertarikan saya untuk melamar di perusahaan Anda dengan posisi {posisi_pekerjaan}.

Saya adalah lulusan S1 jurusan Ilmu Komunikasi dari Universitas Indonesia tahun 2017. Dengan gelar sebagai S.Ikom, saya yakin bahwa saya cocok dengan kualifikasi yang dibutuhkan untuk posisi ini. Sebagai mahasiswa, saya telah belajar mengenai pengetahuan dan keterampilan yang diperlukan untuk membantu mengembangkan dan mendorong strategi pemasaran yang efektif.

Untuk pengalaman kerja, saya memiliki pengalaman magang di salah satu start-up ternama, Gojek, pada divisi marketing.  Di sana, saya belajar mengenai bagaimana perusahaan menentukan produk atau layanan apa yang akan dijual, bagaimana mencapai demografi target, dan bagaimana menanggapi tuntutan pesaing.

Selain pengalaman magang, saya juga memiliki pengalaman berorganisasi aktif di mana saya menjadi anggota BEM Universitas di divisi Humas. Pengalaman tersebut terbukti membantu saya dalam meningkatkan kemampuan komunikasi dan pemecahan masalah. Saya juga merupakan pribadi terampil yang memungkinkan saya berbaur dengan budaya kelompok atau tim dan terus berusaha mencapai tujuan bersama di tengah kegagalan dan kemunduran.

Jika Bapak/Ibu membutuhkan detail lebih lanjut mengenai portofolio atau informasi lainnya, silakan hubungi saya di +6281****

Saya berharap dapat bertemu langsung dengan Anda pada sesi wawancara untuk membahas kualifikasi saya lebih lanjut dan bagaimana saya akan menjadi aset bagi [nama Perusahaan].

Hormat saya,

 

Nama Pelamar`
    })

    const [errors, setError] = useState({
        jobTitle: '',
        subject: '',
        cvId: '',
        message: ''
    })

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

        setError({
            [e.target.name]: ''
        })
    }

    // get data email settings
    const settEmailDoc = doc(firestore, "users", `${user?.uid}`, "settings", "default")
    const { data: item } = useFirestoreDocumentData(
        ['settings', 'default'],
        settEmailDoc,
        {
            subscribe: true,
            idField: "id",
        }
    );

    useEffect(() => {
        async function getDataSett() {
            if (item) {
                if (item.cvId) {
                    const docRef = doc(firestore, "cvs", item?.cvId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        // console.log('docSnap.data()')
                        setForm({
                            ...item,
                            cvId: {
                                id: item?.cvId,
                                label: `${docSnap.data()?.title ?? "Untitled CV "} (${formatDate(
                                    docSnap.data().createdAt?.toDate?.()
                                )})`
                            }
                        })

                        setCheckCV(item?.CVManual)
                    }
                } else {
                    console.log('false')
                    setForm({
                        ...item
                    })
                    setCheckCV(item?.CVManual)
                }

            }
        }
        getDataSett()
    }, [item])
    // console.log(form)
    // get cv

    let q = query(
        cvsCollectionRef,
        where("tempUID", "==", user?.tempUID),
        where("userId", "==", user?.uid),
        orderBy("updatedAt", "desc")
    );
    const { data: cvList, cvListLoading } = useFirestoreQueryData(
        [
            "cvs",
            {
                tempUID: user?.tempUID,
                userId: user?.uid,
            },
        ],
        q,
        {
            subscribe: true,
            idField: "id"
        }
    );
    const transformCvList =
        cvList?.map?.((cv, index) => ({
            id: cv.id,
            label: `${cv?.title ?? "Untitled CV "} (${formatDate(
                cv.createdAt?.toDate?.()
            )})`,
        })) ?? [];

    // checkbox cv manual
    const [checkCV, setCheckCV] = useState(false);

    const handleCheckCV = (e) => {
        setCheckCV(e.target.checked)
    }

    useEffect(() => {
        if (!form.cvId) {
            setCheckCV(true)
        } else {
            setCheckCV(false)
        }
    }, [form.cvId])
    const [loading, setLoading] = useState(false)
    const handleSubmit = async (e) => {
        setLoading(true)
        try {
            await setDoc(settEmailDoc, {
                ...form,
                cvId: checkCV ? '' : form?.cvId?.id || '',
                CVManual: checkCV,
                createdAt: serverTimestamp(FieldValue)
            });
            // pixel
            await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=karirhack_settingsGmail&email=${userData?.email}`);

            enqueueSnackbar('Field email default berhasil ditambahkan', { variant: 'success' });

        } catch (e) {
            console.log(e.message)
            enqueueSnackbar(`Field email default gagal ditambahkan,${e.message}`, { variant: 'error' });

        }
        setLoading(false)
    }

    if (credentialLoading) {
        return <AppLoading />
    }
    return <><Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: "column",
            padding: '20px',
            // paddingBottom: '1000px'
        }}
    >
        {/* Title */}
        <Title content="Email Settings"
        />
        <div className='signinButton'
        >
            {credential?.google?.refresh_token
                ?
                <Box
                >
                    <Typography>Hapus Akses Kirim Email</Typography>
                    <Button
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            backgroundColor: '#fff',
                            color: '#757575',
                            fontSize: 14,
                            padding: '12px 16px',
                            border: 'none',
                            borderRadius: '3px !important',
                            boxShadow: '0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25)',
                            transition: 'background-color .3s, box-shadow .3s',
                            lineHeight: 'normal',
                            marginTop: '15px',
                            '&:hover': {
                                backgroundColor: '#fff',
                                boxShadow: '0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25)',
                            }

                        }}
                        startIcon={<img src={logo} alt="" />}
                        onClick={() => setConfirm(true)}
                    >
                        Sign out({credential?.google?.email})
                    </Button>
                </Box>
                :
                <Box
                    className='signinButtondwed'
                >
                    <Typography>Hubungkan akun Gmail Anda</Typography>
                    <Button

                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            backgroundColor: '#fff',
                            color: '#757575',
                            fontSize: 14,
                            padding: '12px 16px',
                            border: 'none',
                            borderRadius: '3px !important',
                            boxShadow: '0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25)',
                            transition: 'background-color .3s, box-shadow .3s',
                            lineHeight: 'normal',
                            marginTop: '15px',
                            '&:hover': {
                                backgroundColor: '#fff',
                                boxShadow: '0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25)',
                            }
                        }}
                        startIcon={<img src={logo} alt="" />}
                        onClick={handleConnectGmailAccount}
                    >
                        Sign in with Google
                    </Button></Box>}
        </div>
        {/* <div style={{ marginTop: '20px', border: '1px solid', padding: '5px', borderRadius: '5px' }}> */}
        <Paper sx={{ padding: '10px', marginTop: '20px', width: "100%", }}>
            <Typography sx={{
                fontSize: 20,
                fontWeight: "bold",
            }}>Default field formulir kirim email</Typography>
            <div>
                <InputCustom
                    id="job-title"
                    name="jobTitle"
                    title="Posisi / Pekerjaan yang akan dilamar"
                    placeholder="Contoh: Staff Admin"
                    value={form.jobTitle}
                    onChange={handleChange}
                    variant="outlined"
                    error={errors?.jobTitle ? true : false}
                    helperText={errors?.jobTitle}
                    className='posisi'
                />
                <InputCustom
                    id="subject"
                    placeholder="Contoh: Lamaran Staff Admin"
                    title="Subjek Email"
                    name="subject"
                    value={form.subject}
                    onChange={handleChange}
                    variant="outlined"
                    error={errors?.subject ? true : false}
                    helperText={errors?.subject}
                    className='subject'
                />


                <InputCustom
                    placeholder="Pesan"
                    title="Isi Pesan Email / Whatsapp"
                    id="message"
                    name="message"
                    multiline
                    value={form.message}
                    onChange={handleChange}
                    variant="outlined"
                    rows={8}
                    className='isiEmail' />

                <Autocomplete
                    fullWidth
                    disablePortal
                    loading={cvListLoading}
                    value={form.cvId}
                    onChange={(e, value) => {
                        // console.log(value);
                        handleChange({
                            target: {
                                name: "cvId",
                                value,
                            },
                        });
                    }}
                    id="pilih-cv"
                    options={transformCvList}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    noOptionsText={
                        <Button
                            onClick={() => { }}
                            to="/"
                            sx={{ textTransform: "none", padding: 0, color: "inherit" }}
                        >
                            Kamu belum punya CV, buat CV baru disini
                            {/* <span
                                style={{
                                    // color: theme.palette.primary.main, 
                                    marginLeft: 4
                                }}
                            > */}

                            {/* </span> */}
                        </Button>
                    }
                    renderInput={(params) => (
                        <InputCustom
                            containerRef={params.InputProps.ref}
                            placeholder="CV Keren"
                            title="Pilih CV"
                            inputProps={params.inputProps}
                            fullWidth
                            error={errors?.cvId ? true : false}
                            helperText={errors?.cvId}
                        />
                    )}
                    className='pilihCV'
                />
                <FormControlLabel control={<Checkbox checked={checkCV} onChange={handleCheckCV} />} label="Gunakan CV manual attachment" />

            </div>
        </Paper>
        {/* </div> */}
        <div className='attachment' style={{ width: '100%' }}>
            <TableContainer sx={{ marginTop: '30px' }} component={Paper}
            >
                <Typography sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    margin: '20px 0 0 15px'
                }}>List Attachment</Typography>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell align="center">Nama File</TableCell>
                            <TableCell align="center">Tipe</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map?.((row, i) => (
                            <TableRow
                                // key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {i + 1}
                                </TableCell>
                                <TableCell align="center">{row?.nama}</TableCell>
                                <TableCell align="center">{row?.type}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        onClick={() => {
                                            window.open(row?.url)
                                        }}
                                    ><Visibility fontSize="small" /></Button>
                                    <Button
                                        onClick={() => {
                                            setDialogAttch(dialog => ({
                                                ...dialog,
                                                open: true,
                                                data: row,
                                                mode: 'edit'
                                            }))
                                        }}
                                    ><Edit fontSize="small" /></Button>
                                    <Button
                                        onClick={async () => {

                                            if (
                                                window.confirm(`Yakin ingin menghapus  ${row?.nama}?`)
                                            ) {
                                                try {
                                                    await deleteObject(ref(storage, row?.url))
                                                    await deleteDoc(doc(refAtt, row?.id));
                                                    enqueueSnackbar('File attachment berhasil dihapus', { variant: 'success' })

                                                } catch (e) {
                                                    console.log(e.message)
                                                }
                                            }



                                        }}
                                    ><Delete fontSize="small" /></Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                sx={{
                    // position: 'absolute',
                    // bottom: -40,
                    // right: 36,
                    // zIndex: 1000
                    margin: '10px 0 10px 0',
                    borderRadius: '15px',
                    float: 'right'

                }}
                variant="outlined"
                onClick={() => {
                    setDialogAttch({
                        open: true,
                        mode: 'tambah',
                        data: {}
                    })
                }}
            >
                Tambah Attachment

            </Button>
        </div>
        <LoadingButton
            loading={loading}
            className='simpan'
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            startIcon={<SendOutlined />}
            disableElevation
            sx={{
                textTransform: "none",
                marginTop: "30px",
                fontWeight: "bold",
            }}
            onClick={handleSubmit}
        >
            SIMPAN
        </LoadingButton>

        <AlertDialog
            title="Anda yakin ingin menghapus akses kirim email?"
            open={confirm}
            handleClose={() => setConfirm(false)}
            handleYes={deleteAccessSendEmail}
        />
        <DialogAttachment
            dialog={dialogAttch}
            handleClose={() => {
                setDialogAttch({
                    open: false,
                    data: {}
                })
            }}
        />
    </Box>


    </>
}

export default GmailSetting;