import { CircularProgress, Box } from "@mui/material";

import logo from "../assets/logo-full.svg";

function CustomLoading(props) {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <CircularProgress
                variant="determinate"
                sx={{
                    color: "#d4d4d4",
                }}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: "#F2A254",
                    animationDuration: "550ms",
                    position: "absolute",
                    "& .MuiCircularProgress-circle": {
                        strokeLinecap: "round",
                    },
                }}
                size={40}
                thickness={4}
                {...props}
            />
        </Box>
    );
}

export default function AppLoading() {
    return (
        <Box
            sx={{
                height: "55vh",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    margin: "auto",
                    width: "135px",
                    height: "250px",
                }}
            >
                <img
                    style={{
                        width: 135,
                        margin: "20px auto",
                    }}
                    src={logo}
                    alt=""
                />
                <Box
                    sx={{
                        width: "100%",
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CustomLoading />
                    <p
                        style={{
                            marginTop: "15px",
                            fontSize: 15,
                            fontStyle: "italic",
                            fontFamily: "glacial_indifferenceregular",
                        }}
                    >
                        loading ...
                    </p>
                </Box>
            </Box>
        </Box>
    );
}
