import localforage from "localforage"
import { useEffect, useState } from "react";

export default function useTempUID() {
    const [isLoading, setIsLoading] = useState(false);
    const [tempUID, setTempUID] = useState(null);
    useEffect(() => {
        async function setLocalTempUID() {
            setIsLoading(true);
            try {
                let localTempUID = await localforage.getItem("tempUID");
                if (!localTempUID) {
                    localTempUID = crypto.randomUUID();
                    await localforage.setItem("tempUID", localTempUID);
                }
                setTempUID(localTempUID);
            }
            catch (e) {
                const localTempUID = crypto.randomUUID();
                await localforage.setItem("tempUID", localTempUID);
                setTempUID(localTempUID);
            }
            setIsLoading(false);
        }
        setLocalTempUID();
    }, [])

    return { tempUID, isLoading };
}