import { Autocomplete, Typography } from "@mui/material";
import { useState } from "react";
import {
  InstantSearch,
  useHits,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import createTypesenseISAdapter from "../config/typesense";
import InputCustom from "./InputCustom";
const typsesenseAdapter = createTypesenseISAdapter({
  query_by: "name",
  typo_tokens_threshold: 3,
});

const searchClient = typsesenseAdapter.searchClient;

const transformItems = (items, { results }) => {
  return items.map(({ objectID, id, name }) => ({
    objectID,
    id,
    name,
  }));
};

function CustomSearch({
  value,
  onChange,
  inputCustomProps,
  switchProps,
  ...restProps
}) {
  const [inputValue, setInputValue] = useState("");
  const { refine } = useSearchBox();
  const { hits } = useHits({ transformItems });
  return (
    <Autocomplete
      options={hits}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      disableClearable
      freeSolo
      value={value}
      onChange={(e, newValue) => {
        if (typeof newValue === 'string') {
          onChange({
            name: newValue
          })
        } else {
          onChange(newValue);
        }
      }}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        onChange({
          name: newInputValue
        });
        setInputValue(newInputValue);
        refine(newInputValue);
      }}
      isOptionEqualToValue={(option, value) =>
        option.objectID === value.objectID
      }
      renderInput={(params) => {
        return (
          <InputCustom
            containerRef={params.InputProps.ref}
            placeholder="Universitas Padjadjaran"
            title="Nama Universitas"
            inputProps={params.inputProps}
            {...inputCustomProps}
          />
        );
      }}
      getOptionLabel={(option) => option.name ?? ""}
      renderOption={(props, option, state) => {
        return (
          <li {...props} key={option.objectID}>
            <Typography
              sx={
                !state.selected
                  ? { fontFamily: "glacial_indifferenceregular" }
                  : {}
              }
            >
              {option.name}
            </Typography>
          </li>
        );
      }}
      {...restProps}
    />
  );
}

export default function InputUniversitySearch({
  onChange,
  value,
  ...restProps
}) {
  return (
    <InstantSearch indexName="universities" searchClient={searchClient}>
      <CustomSearch onChange={onChange} value={value} {...restProps} />
    </InstantSearch>
  );
}
