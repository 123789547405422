
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

export default function createTypesenseISAdapter(additionalSearchParameters) {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: "t2w3Y0nvVtHruON7bQgje6O17XIMG7ZO", // Be sure to use the search-only-api-key
            nodes: [
                {
                    host: 'typesense.karir.ai',
                    port: "443",
                    protocol: "https"
                }
            ]
        },
        // The following parameters are directly passed to Typesense's search API endpoint.
        //  So you can pass any parameters supported by the search endpoint below.
        //  queryBy is required.
        additionalSearchParameters
    });

    return typesenseInstantsearchAdapter;

}