import { Autocomplete, Typography } from "@mui/material";
import { useState } from "react";
import {
  InstantSearch,
  useHits,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import createTypesenseISAdapter from "../config/typesense";
import InputCustom from "./InputCustom";
const typsesenseAdapter = createTypesenseISAdapter({
  query_by: "alternative_skill_names",
  typo_tokens_threshold: 3,
  per_page: 250,
});

const searchClient = typsesenseAdapter.searchClient;

const transformItems = (items, { results }) => {
  return items.map(({ objectID, id, skill_name: name }) => ({
    objectID,
    id,
    name,
  }));
};

function CustomSearch({
  value,
  onChange,
  inputCustomProps,
  switchProps,
  listSkills,
  onDeleteChip,
  error,
  helperText,
  onSelected,
  ...restProps
}) {
  const [inputValue, setInputValue] = useState("");
  const { refine } = useSearchBox();
  const { hits } = useHits({ transformItems });
  // const { hits, isLastPage, showMore } = useInfiniteHits({ transformItems })

  return (
    <Autocomplete
      options={hits}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      disableClearable
      disablePortal
      freeSolo
      value={value}
      onChange={(e, newValue) => {
        onChange(newValue);
        onSelected(newValue?.name ?? newValue);
      }}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
        refine(newInputValue);
      }}
      isOptionEqualToValue={(option, value) =>
        option.objectID === value.objectID
      }
      renderInput={(params) => {
        return (
          <InputCustom
            containerRef={params.InputProps.ref}
            title="Apa saja Skill yang anda miliki?"
            placeholder="Software: Php, Javascript, Ruby, Node Js, Jquery"
            inputProps={params.inputProps}
            isChip
            listChip={listSkills}
            onDeleteChip={onDeleteChip}
            error={error}
            helperText={helperText}
            {...inputCustomProps}
          />
        );
      }}
      getOptionLabel={(option) => option.name ?? ""}
      renderOption={(props, option, state) => {
        return (
          <li {...props} key={option.objectID}>
            <Typography
              sx={
                !state.selected
                  ? { fontFamily: "glacial_indifferenceregular" }
                  : {}
              }
            >
              {option.name}
            </Typography>
          </li>
        );
      }}
      {...restProps}
    />
  );
}

export default function InputChipSearch({
  onChange,
  onSelected,
  value,
  ...restProps
}) {
  return (
    <InstantSearch indexName="skills" searchClient={searchClient}>
      <CustomSearch
        onSelected={onSelected}
        onChange={onChange}
        value={value}
        {...restProps}
      />
    </InstantSearch>
  );
}
