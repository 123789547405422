import { AttachFile } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { functions } from "../contexts/FirebaseProvider";
import axios from "axios";
import { useMutation } from "react-query";


export default function UploadFile({ directory, id, onSuccess, onError }) {
    const { mutateAsync: generateUploadUrl, isLoading: isLoadingGenerateUploadUrl } = useFunctionsCall(functions, "upload-generateUploadUrl");
    const { mutateAsync: uploadToS3, isLoading: isLoadingUploadToS3 } = useMutation(({
        uploadUrl,
        file,
        fileType

    }) => {

        return axios.put(uploadUrl, file, {
            headers: {
                "Content-Type": fileType,
            },
        })
    })
    const theme = useTheme();
    const accept = {
        'image/jpeg': [],
        'image/png': []
    };
    const maxSize = '20971520';

    const onDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            const reader = new FileReader();

            reader.onabort = () => onError?.("file reading was aborted");

            reader.onerror = () => onError?.("file reading has failed");

            reader.onload = async () => {

                try {

                    const result = await generateUploadUrl?.({
                        filename: file.name,
                        directory,
                        contentType: file.type,
                        id,
                    });



                    await uploadToS3?.({
                        uploadUrl: result.uploadUrl,
                        file,
                        fileType: file.type
                    });
                    onSuccess?.(result.photoUrl?.split?.("?")?.[0]);


                } catch (err) {
                    console.log(err.message)
                    onError?.(err.message)
                }
            };

            reader.readAsArrayBuffer(file);
        },
        [directory, id, onSuccess, onError, generateUploadUrl, uploadToS3]
    );
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        maxSize,
        onDrop,
        disabled: isLoadingGenerateUploadUrl || isLoadingUploadToS3
    })

    return <Box sx={{
        boxSizing: 'border-box',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        gap: 2,
        border: `1px dashed ${theme.palette.primary.main}`,
        borderRadius: '4px',
        background: theme.surface.background
    }} component="div" {...getRootProps()}>
        <input {...getInputProps()} />
        <AttachFile color="primary" />
        <Typography sx={{
            fontFamily: 'glacial_indifferenceregular',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '160%'
        }}>Attach File</Typography>
    </Box>

}