// React
import React, { useState, useEffect, useRef } from "react";

// Components
import {
  InputCustom,
  InputDegreeSearch,
  InputFieldOfStudySearch,
  InputUniversitySearch,
  SavedItem,
} from "../../components";

// React Router Dom
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

// Context
import { subCollectionRef } from "../../contexts/FirebaseProvider";

// React Query
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
  useFirestoreDocumentData,
} from "@react-query-firebase/firestore";

// Firebase
import { deleteDoc, serverTimestamp } from "firebase/firestore";

// Bottom Sheet
import { BottomSheet } from "react-spring-bottom-sheet";

// MUI
import { Autocomplete, Button, Container, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

// Initial State
let initialState = {
  degree: null,
  schoolName: null,
  fieldOfStudy: null,
  graduate: "",
  grade: "",
  level: null,
};

let subCollection = "educations";
const title = "Pendidikan";
const options = ['SMA/SMK', 'D1', 'D2', 'D3', 'D4', 'S1', 'S2', 'S3'];
function Education() {
  // Context
  const { docRef, basicInfo } = useOutletContext();

  // Params
  const params = useParams();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") ?? "default";

  // Navigate
  const navigate = useNavigate();

  // Ref
  const ref = subCollectionRef(params.cvId, subCollection);

  // Mutate
  const { mutate: addEducation, isLoading: addLoading } =
    useFirestoreCollectionMutation(ref);

  const { mutate: updateEducation, isLoading: updateLoading } =
    useFirestoreDocumentMutation(docRef(ref, id), {
      merge: true,
    });

  // Get Data
  const { data: list } = useFirestoreQueryData(subCollection, ref, {
    subscribe: true,
    idField: "id",
  });

  const { data: item } = useFirestoreDocumentData(
    [subCollection, id],
    docRef(ref, id),
    {
      subscribe: true,
      idField: "id",
    }
  );

  // State
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [mode, setMode] = useState("create");

  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);

  // Ref
  const bottomsheetRef = useRef();

  // snack
  const { enqueueSnackbar } = useSnackbar();

  // Handler
  const handleChange = (e) => {
    const { name, value } = e.target;



    if (name === 'level') {
      setForm((form) => ({
        ...initialState,
        [name]: value,
      }));
      setErrors({});
    } else {
      setForm((form) => ({
        ...form,
        [name]: value,
      }));
      setErrors((errors) => ({
        ...errors,
        [name]: "",
      }));
    }
  };

  const handleDismissButtomsheet = () => {
    navigate({
      search: "",
    });
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }
    navigate({
      search: `?id=new`,
    });


  };

  const handleUpdate = () => {
    if (updateLoading) {
      return;
    }

    const { degree, schoolName, fieldOfStudy, graduate, grade, level } = form;

    let findErrors = {};
    if (!level) {
      findErrors.level = 'Level wajib diisi.';
    } else {
      if (level !== 'SMA/SMK' && !degree) {
        findErrors.degree = 'Gelar wajib diisi.';
      }

      if (!schoolName) {

        findErrors.schoolName = `${level === 'SMA/SMK' ? 'Nama Sekolah' : 'Universitas'} wajib diisi.`;
      }

      if (!fieldOfStudy) {
        findErrors.fieldOfStudy = "Jurusan wajib diisi.";
      }

      if (!graduate) {
        findErrors.graduate = "Tahun lulus wajib diisi."
      }

      // if (!grade) {
      //   findErrors.grade = `${level === 'SMA/SMK' ? 'Nilai' : 'IPK'} wajib diisi.`;
      // }

    }

    if (Object.values(findErrors).some(message => message !== "")) {
      setErrors(findErrors);
    } else {
      if (mode === 'create') {
        addEducation(
          {
            degree,
            schoolName,
            fieldOfStudy,
            graduate,
            grade,
            level,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, { variant: 'success' })
            },
            onError(e) {
              console.log(e)
            }
          }
        );
      } else {
        updateEducation(
          {
            degree,
            schoolName,
            fieldOfStudy,
            graduate,
            grade,
            level,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, { variant: 'success' })
            },
            onError(e) {
              console.log(e)
            }

          }
        );
      }
    }
  };

  // UseEffect
  useEffect(() => {
    if (item) {
      setForm({
        ...initialState,
        ...item,
      });
    }
  }, [item]);

  useEffect(() => {

    if (id === 'new') {
      setMode('create');
    } else if (id !== 'default') {
      setMode('update');
    }
  }, [id]);

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  const handleDelete = async (selectedId) => {
    return await deleteDoc(docRef(ref, selectedId));
  };

  return (
    <>
      {/* Saved Item */}
      <SavedItem
        type={title}
        list={list}
        titleField={[["level", "string", " "], ["degree.name", "string", ""]]}
        subtitleField={"schoolName.name"}
        activeId={id}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        addLoading={addLoading}
      />

      {/* Bottom Sheet */}
      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs">

          {/* Input Level */}
          <Autocomplete
            fullWidth
            disablePortal
            value={form.level}
            onChange={(e, value) => {
              handleChange({
                target: {
                  name: 'level',
                  value
                }
              })

            }}
            id="pilih-level-education"
            options={options}
            renderInput={(params) => <InputCustom
              containerRef={params.InputProps.ref}
              placeholder="SMA/SMK"
              title="Pilih Level Pendidikan"
              inputProps={params.inputProps}
              fullWidth
              error={errors?.level ? true : false}
              helperText={errors?.level}
            />}
          />
          {/* Input Gelar */}
          {form.level === 'SMA/SMK' &&
            <InputCustom
              title="Sekolah"
              placeholder="SMK Negeri 2 Kota Tasikmalaya"
              name="schoolName"
              onChange={(e) => {
                handleChange({
                  target: {
                    name: 'schoolName',
                    value: {
                      name: e.target.value
                    }
                  }
                })
              }}
              value={form.schoolName?.name}
              error={errors?.schoolName ? true : false}
              helperText={errors?.schoolName}
            />
          }
          {form.level && form.level !== 'SMA/SMK' &&
            <><InputDegreeSearch
              onChange={(newValue) => {
                handleChange({
                  target: {
                    name: "degree",
                    value: newValue,
                  },
                });
              }}
              value={form.degree}
              lang={basicInfo?.lang?.value ?? 'ID'}

              level={form.level ?? 'S1'}
              inputCustomProps={{
                required: true,
                error: errors?.degree ? true : false,
                helperText: errors?.degree
              }

              }
            />

              {/* Input Universitas */}
              <InputUniversitySearch
                onChange={(newValue) => {
                  handleChange({
                    target: {
                      name: "schoolName",
                      value: newValue,
                    },
                  });
                }}
                value={form.schoolName}
                inputCustomProps={{
                  required: true,
                  error: errors?.schoolName ? true : false,
                  helperText: errors?.schoolName
                }

                }
              />
            </>
          }

          {/* Input Universitas */}
          <InputFieldOfStudySearch
            onChange={(newValue) => {
              handleChange({
                target: {
                  name: "fieldOfStudy",
                  value: newValue,
                },
              });
            }}
            lang={basicInfo?.lang?.value ?? 'ID'}
            value={form.fieldOfStudy}
            inputCustomProps={{
              required: true,
              error: errors?.fieldOfStudy ? true : false,
              helperText: errors?.fieldOfStudy
            }}
          />

          {/* Input Lulusan */}
          <InputCustom
            title="Lulusan"
            placeholder="2015"
            name="graduate"
            onChange={handleChange}
            value={form.graduate}
            error={errors?.graduate ? true : false}
            helperText={errors?.graduate}
          />

          {/* Input IPK */}
          <InputCustom
            title={form?.level !== 'SMA/SMK' ? "IPK" : "Nilai"}
            placeholder="4"
            name="grade"
            onChange={handleChange}
            value={form.grade}
            error={errors?.grade ? true : false}
            helperText={errors?.grade}
          />
        </Container>
      </BottomSheet>
    </>
  );
}

export default Education;
