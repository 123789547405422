import { Typography, useTheme } from "@mui/material"


export default function Title({ content }) {
    const theme = useTheme();
    return <Typography
        sx={{
            fontFamily: 'glacial_indifferenceregular',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '120%',
            /* or 38px */
            textAlign: "center",
            /* Neutrals/60 */
            color: theme.palette.textColor.neutral60,
            marginBottom: '13px',
        }}
    >
        {content}
    </Typography>
}