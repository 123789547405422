import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useAuthSignInWithRedirect,
  useAuthSignOut,
  useAuthUser,
  useAuthSignInWithEmailAndPassword,
} from "@react-query-firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage"
import firebaseConfig from "../config/firebaseConfig";
import { getFunctions } from "firebase/functions";
import AppLoading from "../components/AppLoading";
import useTempUID from "../hooks/useTempUID";
import SignInDialog from "../components/SignInDialog";
import trackClickEvent from "../utils/trackClickEvent";
import localforage from "localforage";
import { googleOAuthClientId } from "../config/google";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app)
export const functions = getFunctions(app, "asia-southeast2");

export const FirebaseContext = createContext();

// collection references
export const cvsCollectionRef = collection(firestore, "cvs");
export const cvAccessColectionRef = collection(firestore, "cvAccess");
export const jobTrendCollectionRef = collection(firestore, "jobtrend");
export const optimizerCollectionRef = collection(firestore, "optimizer");
export const subCollectionRef = (docId, subCollection) =>
  collection(firestore, `cvs/${docId}/${subCollection}`);
export const applicationCollection = (userId) =>
  collection(firestore, `users/${userId}/applications`);

export function useFirebase() {
  return useContext(FirebaseContext);
}
export default function FirebaseProvider(props) {
  const [isOpenSignInDialog, setOpenSignInDialog] = useState(false);
  const [isEmailLogin, setIsEmailLogin] = useState(false);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };
  const googleOAuthClient = useRef(null);
  const user = useAuthUser(["user"], auth);
  const authMutation = useAuthSignInWithRedirect(auth);
  const { mutate: emailmutation, isLoading } =
    useAuthSignInWithEmailAndPassword(auth);

  const signoutMutation = useAuthSignOut(auth);
  const temp = useTempUID();
  const handleCloseSignInDialog = () => {
    setOpenSignInDialog(false);
    setIsEmailLogin(false);
  };
  const openSignInDialog = useCallback(() => {
    setOpenSignInDialog(true);
    trackClickEvent("SignIn Dialog Opened");
  }, []);

  useEffect(() => {
    if (user?.data?.uid && temp?.tempUID) {
      async function claimTempCVs() {
        const q = query(
          cvsCollectionRef,
          where("userId", "==", null),
          where("tempUID", "==", temp?.tempUID)
        );
        const snapshots = await getDocs(q);
        snapshots.forEach((doc) => {
          setDoc(
            doc.ref,
            {
              userId: user?.data?.uid,
              tempUID: null,
            },
            { merge: true }
          );
        });
      }

      claimTempCVs();
    }
  }, [user?.data?.uid, temp?.tempUID]);

  useEffect(() => {
    if (user?.data?.uid) {
      window.mixpanel?.identify(user?.data?.uid);
    }
  }, [user?.data?.uid]);

  useEffect(() => {
    window.onload = () => {
      googleOAuthClient.current =
        window.google?.accounts?.oauth2?.initCodeClient({
          client_id: googleOAuthClientId,
          scope: "https://www.googleapis.com/auth/gmail.send",
          ux_mode: "redirect",
          redirect_uri: `${window.location.origin}/__/auth/handler`,
          state: window.location.pathname,
        });
    };
  }, []);

  const setLockedParamsToFirestore = useCallback(() => {
    if (user?.data?.uid) {
      localforage.getItem("lockedParams").then((lockedParams) => {
        if (Object.keys(lockedParams).length > 0) {
          setDoc(
            doc(firestore, `users/${user?.data?.uid}`),
            { ...lockedParams },
            { merge: true }
          );
        }
      });
    }
  }, [user?.data?.uid]);
  const handleSignInWithGoogle = () => {
    authMutation.mutate(
      {
        provider: new GoogleAuthProvider(),
      },
      {
        onSuccess() {
          setOpenSignInDialog(false);
        },
      }
    );
  };

  const handleSignInWithEmailPassword = () => {
    emailmutation(
      {
        email: form?.email,
        password: form?.password,
      },
      {
        onSuccess() {
          setOpenSignInDialog(false);
          setForm({
            email: "",
            password: "",
          });
        },
      }
    );
  };

  const handleSignInWIthEmail = useCallback(() => {
    setOpenSignInDialog(true);
    setIsEmailLogin(true);
    trackClickEvent("SignIn Dialog Opened");
  }, []);

  const handleConnectGmailAccount = () => {
    console.log(googleOAuthClient.current);
    googleOAuthClient.current?.requestCode?.();
  };

  const handleSignOut = () => {
    signoutMutation.mutate();
  };

  // user doc
  const usersDoc = doc(firestore, `users/${user?.data?.uid}`);
  const { data: userData, isLoading: loadingUser } = useFirestoreDocumentData(['users', user?.data?.uid], usersDoc, {
    subscribe: true,
    idField: "id",
  });
  // user doc
  const usersSettingsDoc = doc(firestore, `users/${user?.data?.uid}/settings/default`);
  const { data: userDataSett, isLoading: loadingUserSett } = useFirestoreDocumentData(['users', user?.data?.uid, "settings", "default"], usersSettingsDoc, {
    subscribe: true,
    idField: "id",
  });


  // console.log(userDataSett)
  if (user.isLoading || temp.isLoading || loadingUserSett) {
    return <AppLoading />;
  }

  if (user?.data?.uid) {
    return (
      <FirebaseContext.Provider
        value={{
          user: { tempUID: null, ...user.data },
          openSignInDialog,
          handleSignOut,
          setLockedParamsToFirestore,
          handleConnectGmailAccount,
          handleSignInWithGoogle,
          userData,
          loadingUser,
          userDataSett
        }}
      >
        {props.children}
      </FirebaseContext.Provider>
    );
  }

  return (
    <FirebaseContext.Provider
      value={{
        user: { uid: null, tempUID: temp.tempUID },
        openSignInDialog,
        handleSignInWithGoogle,
        handleSignInWIthEmail,
      }}
    >
      {props.children}
      <SignInDialog
        open={isOpenSignInDialog}
        onClose={handleCloseSignInDialog}
        handleSignInWithGoogle={handleSignInWithGoogle}
        isEmailLogin={isEmailLogin}
        onLoginWithEmail={() => setIsEmailLogin(true)}
        form={form}
        errors={errors}
        handleChange={handleChange}
        handleSignInWithEmailPassword={handleSignInWithEmailPassword}
        loading={isLoading}
      />
    </FirebaseContext.Provider>
  );
}
