import React from "react";

import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Add from "@mui/icons-material/Add";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import FileCopyOutlined from "@mui/icons-material/FileCopyOutlined";
import SaveAlt from "@mui/icons-material/SaveAlt";

function CoverLetterList({ history }) {
  const theme = useTheme();

  return (
    <>
      <Box>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "22px",
            textAlign: "center",
          }}
        >
          Surat Lamaran Saya
        </p>
        {/* NO DATA */}
        {/* <div
          styles={{
            margin: "150px 0px",
          }}
        >
          <p
            style={{
              fontSize: "26px",
              color: theme.palette.neutrals.n60,
            }}
          >
            Belum ada surat
          </p>
        </div> */}

        {/* DATA */}
        <div>
          {/* ITEM */}
          <Box
            sx={{
              // "@media(max-width: 630px)": {
              //   backgroundColor: theme.palette.neutrals.nf,
              // },
              backgroundColor: theme.palette.neutrals.n0,
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "30px",
            }}
          >
            <p
              style={{
                marginTop: 0,
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "left",
              }}
            >
              Microsoft Indonesia
            </p>
            <p
              style={{
                fontSize: "15px",
                textAlign: "left",
                marginBottom: "5px",
                color: theme.palette.neutrals.n60,
              }}
            >
              Diedit : (tanggal)
            </p>
            <p
              style={{
                marginTop: 0,
                fontSize: "15px",
                textAlign: "left",
                color: theme.palette.neutrals.n60,
              }}
            >
              Dibuat : (tanggal)
            </p>
            <Grid container justifyContent="space-between">
              <Grid item>
                <IconButton color="primary" sx={{ padding: "5px" }}>
                  <EditOutlined />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="primary" sx={{ padding: "5px" }}>
                  <FileCopyOutlined />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="primary" sx={{ padding: "5px" }}>
                  <SaveAlt />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="primary" sx={{ padding: "5px" }}>
                  <CloseOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "72px",
          left: 0,
          right: 0,
          padding: "16px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          color="primary"
          size="large"
          variant="contained"
          startIcon={<Add />}
          component={Link}
          to="/cover-letter/create"
          disableElevation
          style={{
            textTransform: "none",
            fontWeight: "bold",
            maxWidth: "598px",
          }}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            // window?.mixpanel?.track("Lowongan kerja home cover letter Button Clicked", {
            //   userId: user?.uid,
            //   tempUID: user?.tempUID,
            // })
          }}
        >
          Buat Surat Baru
        </Button>
      </div>
    </>
  );
}

export default CoverLetterList;
