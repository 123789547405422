import { Button, Grid } from "@mui/material";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { functions } from "../../contexts/FirebaseProvider";
import ExcelJS from "exceljs";
import xlsxIcon from "../../assets/icon/xlsx.svg"
import Template from './template.xlsx'

export default function Upload() {
    const { enqueueSnackbar } = useSnackbar();

    // upload
    const { mutateAsync: scheduleAutoLamar, isLoading: loadScheduleAutoLamar } = useFunctionsCall(
        functions,
        "job-scheduleAutoLamar"
    );
    const mapColumnToField = [
        "",
        "company_email",
        "company_name",
    ];

    const [isSubmitting, setSubmitting] = useState(false);

    const pickerOpts = {
        types: [
            {
                description: "Text Files",
                accept: {
                    "text/plain": [".xlsx"],
                },
            },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
    };
    const handleUpload = async () => {
        try {
            const [file] = await window.showOpenFilePicker(pickerOpts);
            const fileData = await file.getFile();
            setSubmitting(true)

            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(fileData)

            const worksheet = workbook.getWorksheet('data')

            const totalRows = worksheet.rowCount;
            const totalColumn = worksheet.columnCount;

            let rawData = [];
            for (let i = 2; i <= totalRows; i++) {
                const row = worksheet.getRow(i);

                let rowData = {};
                for (let j = 1; j <= totalColumn; j++) {
                    const cell = row.getCell(j);

                    if (cell.value)
                        rowData[mapColumnToField[j]] = cell?.value;
                }
                if (Object.keys(rowData).length > 0) {
                    rawData.push(rowData);
                }
            }

            const mapData = [];

            for (let i = 0; i < rawData.length; i++) {
                let data = rawData[i];
                if (data.company_email) {
                    mapData.push({
                        company_email: data.company_email?.text ? data.company_email?.text : data.company_email,
                        company_name: data.company_name || '',
                        text: ''
                    });
                }


            }
            console.log(rawData)

            await scheduleAutoLamar({
                jobs: [...mapData]
            });

            // handleClose();
            enqueueSnackbar("Upload berhasil", { variant: "success" });
            setSubmitting(false);
        } catch (e) {
            console.log(e.message);
            // handleClose();
            enqueueSnackbar(`Upload gagal ,${e.message}`, {
                variant: "error",
            });
            setSubmitting(false);
            // return <Typography>{e.message}</Typography>;
        }
    };
    return (
        <Grid container >

            <Grid item lg={4} xs={12} sx={{ backgroundColor: '#FAFAFA', height: '200px', border: '1px solid ', borderColor: '#DDD', }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '40px'
                }}>
                    <img
                        alt=""
                        src={xlsxIcon}

                    />
                </div>

                {/* <div style={{
                    width: '206px',
                    height: '250px',
                    backgroundColor: '#DDD'
                }}> */}
                <Button
                    fullWidth
                    size="small"
                    color="primary"
                    variant="contained"
                    // startIcon={<Add />}
                    disableElevation
                    // disabled={isSubmitting || loadScheduleAutoLamar}
                    className="newApp"
                    sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        maxWidth: "598px",
                        marginRight: '10px',
                        marginTop: '55px',
                        borderRadius: '0px'
                    }}
                    onClick={handleUpload}
                >
                    {isSubmitting || loadScheduleAutoLamar ? 'loading...' : 'Upload'}
                </Button>
            </Grid>
            <Grid item lg={8} xs={12}>

                <div style={{
                    marginLeft: '10px'
                }}>
                    <p style={{
                        marginTop: '0px',
                        fontSize: '20px'
                    }}>Instruksi dan Ketentuan</p>
                    <p style={{
                        margin: '0px',
                        fontSize: '13px'
                    }}>1. Download terlebih dahulu template excel  untuk lebih mudah  memasukkan data email (wajib) dan nama perusahaan (opsional) dalam banyak jumlah sekaligus.
                    </p>
                    <p style={{
                        margin: '0px',
                        fontSize: '13px'
                    }}>2. Template hanya bisa diisi dengan MS. Excel 2007 keatas atau LibreOffice. <a href={Template} download='Template' color="#F26722" style={{ color: '#F26722' }}>Klik disini untuk Download file template</a></p>
                    <p style={{
                        margin: '0px',
                        fontSize: '13px'
                    }}>3. Setelah download template silakan dibuka lalu masukkan data email (wajib) dan nama perusahaan (opsional) .

                    </p>
                    <p style={{
                        margin: '0px',
                        fontSize: '13px'
                    }}>4. Save lalu upload file .xlsx (Excel) tersebut pada kolom upload disamping.</p>
                </div>
            </Grid>
        </Grid>
    )
}