// React
import React from "react";

// MUI
import { Box, Typography, useTheme } from "@mui/material";

// React Draft WYSIWYG
import { Editor } from "react-draft-wysiwyg";

// Assetes
import { ic_bold, ic_dot, ic_emoji, ic_italic, ic_number } from "../assets";

// Styling WYSIWYG
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function RichTextEditorCustom({ title, editorState, onEditorStateChange }) {
  const theme = useTheme();

  return (
    <Box sx={{ marginTop: "20px" }}>
      {/* Title */}
      <Typography
        sx={{
          color: "textColor.neutral80",
          fontWeight: "600",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        {title}
      </Typography>

      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperStyle={{
          display: "flex",
          flexDirection: "column",
        }}
        editorStyle={{
          border: `1px solid ${theme.neutral.n20}`,
          order: 1,
          minHeight: 152,
          maxHeight: 152,
          padding: "0px 16px",
        }}
        toolbarStyle={{
          border: `1px solid ${theme.neutral.n20}`,
          order: 2,
        }}
        toolbar={{
          options: ["emoji", "inline", "list"],
          emoji: {
            icon: ic_emoji,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
          },
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic"],
            bold: {
              icon: ic_bold,
            },
            italic: {
              icon: ic_italic,
            },
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["ordered", "unordered"],
            ordered: {
              icon: ic_number,
            },
            unordered: {
              icon: ic_dot,
            },
          },
        }}
      />
    </Box>
  );
}

export default RichTextEditorCustom;
