// React
import React from "react";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";

const CustomTitle = styled(DialogTitle)({
  paddingBottom: 0,
});

const CustomContent = styled(DialogContent)({
  paddingBottom: 0,
});

function DialogSave({
  title,
  open,
  id,
  onSelect,
  onEdit,
  onDelete,
  onHide,
  content = [],
}) {
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      {/* Title */}
      <CustomTitle sx={{ textAlign: "center" }}>{title}</CustomTitle>

      {/* Content */}
      <CustomContent>
        {content?.length > 0 ? (
          <List>
            {content?.map((item, idx) => {
              return (
                <ListItemButton
                  selected={item?.id === id}
                  onClick={() => {
                    onSelect(item?.id);
                  }}
                  key={idx}
                >
                  <ListItemText
                    primary={item?.title}
                    secondary={item?.subtitle}
                  />
                </ListItemButton>
              );
            })}
          </List>
        ) : (
          <div
            style={{
              minHeight: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography>Belum Ada Data</Typography>
          </div>
        )}
      </CustomContent>

      {/* Actions */}
      <DialogActions
        sx={{ justifyContent: "flex-start", padding: "18px 40px" }}
      >
        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={onEdit}
          disabled={!id}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          fullWidth
          onClick={onDelete}
          disabled={!id}
        >
          Delete
        </Button>
        <Button variant="outlined" size="small" fullWidth onClick={onHide}>
          Sembunyikan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogSave;
