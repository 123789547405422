import format from "date-fns/format";
import formatRelative from "date-fns/formatRelative";
import id from "date-fns/locale/id";

export default function formatDate(date) {

    return format(date || new Date(), 'dd/MM/yyyy kk:mm');
}

export function TimestampToDate(date) {

    return format(date || new Date(), 'yyyy-MM-dd');
}

export function formatDateToYear(date) {
    return format(date || new Date(), 'yyyy')
}

export function formatFromNow(date) {
    return formatRelative(date || new Date(), new Date(), {
        locale: {
            ...id
        }
    })
}