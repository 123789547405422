import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Cancel from "@mui/icons-material/Cancel";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { functions } from "../contexts/FirebaseProvider";
import { dashboardBaseUrl } from "../config/baseUrls";

export default function TopupDialog({ open, handleClose }) {
    const { mutateAsync: reqCustomToken, isLoading: isLoadingCustomToken } = useFunctionsCall(functions, "user-reqCustomToken");

    const handleClickTopup = async () => {
        const response = await reqCustomToken()
        if (response?.customToken) {
            window.location.href = `${dashboardBaseUrl}/login-by-token/${response?.customToken}/myCredit`;
        }
    }

    return (
        <React.Fragment>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: 8,
                    },
                }}
                open={open}
                maxWidth="xs"
                fullWidth
            >
                <DialogContent sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <Cancel color="primary" fontSize="large" />
                    <p style={{
                        fontSize: 16,
                        textAlign: "center",
                        fontWeight: "bold",
                    }}>Jumlah kredit anda tidak mencukupi untuk membeli akses ini</p>
                    <span style={{
                        fontSize: 16,
                        textAlign: "center",
                    }}>lakukan topup untuk menambah kredit anda.</span>
                    <br />
                </DialogContent>
                <DialogActions sx={{ padding: "20px" }} >
                    <Button
                        sx={{

                            fontSize: 14,
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            border: (theme) => "1px solid" + theme.palette.primary.main,
                            "&:hover": {
                                border: (theme) => "1px solid" + theme.palette.primary.main,
                            },

                        }}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                        disableElevation
                        fullWidth
                        disabled={isLoadingCustomToken}
                    >
                        Kembali
                    </Button>
                    <Button
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            textTransform: "capitalize",
                        }}
                        // onClick={() => history.push("/myCoin")}
                        onClick={handleClickTopup}
                        color="primary"
                        variant="contained"
                        disabled={isLoadingCustomToken}
                        disableElevation
                        fullWidth
                    >
                        Topup
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
