import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

// import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import SaveOutlined from "@mui/icons-material/SaveOutlined";

import { InputCustom } from "../../components";
import AlertDialog from "../../components/AlertDialog";

function CoverLetterCreate({ history }) {
  // const theme = useTheme();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
  ];

  return (
    <>
      <Box>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "22px",
            textAlign: "center",
          }}
        >
          Surat Lamaran Baru
        </p>
        <Autocomplete
          fullWidth
          disablePortal
          // value={lang}
          onChange={(e, value) => {
            // handleChange({
            //     target: {
            //         name: 'lang',
            //         value
            //     }
            // })
          }}
          id="pilih-level-education"
          options={top100Films}
          renderInput={(params) => (
            <InputCustom
              containerRef={params.InputProps.ref}
              placeholder="Sesuaikan Dengan CV Saya"
              title="Sesuaikan Dengan CV Saya"
              inputProps={params.inputProps}
              fullWidth
              // error={errors?.level ? true : false}
              // helperText={errors?.schoolName}
            />
          )}
        />
        <InputCustom
          id="select-page"
          title="Judul/Nama Perusahaan"
          placeholder="Judul/Nama Perusahaan"
          // value={page}
          // onChange={handleChange}
          variant="outlined"
          // SelectProps={{
          //   renderValue: (value) => {
          //     console.log(value)
          //     return value;
          //   }
          // }}
        />
        <Autocomplete
          fullWidth
          disablePortal
          // value={lang}
          onChange={(e, value) => {
            // handleChange({
            //     target: {
            //         name: 'lang',
            //         value
            //     }
            // })
          }}
          id="pilih-level-education"
          options={top100Films}
          renderInput={(params) => (
            <InputCustom
              containerRef={params.InputProps.ref}
              placeholder="Pilih Template"
              title="Pilih Template"
              inputProps={params.inputProps}
              fullWidth
              // error={errors?.level ? true : false}
              // helperText={errors?.schoolName}
            />
          )}
        />
        <Autocomplete
          fullWidth
          disablePortal
          // value={lang}
          onChange={(e, value) => {
            // handleChange({
            //     target: {
            //         name: 'lang',
            //         value
            //     }
            // })
          }}
          id="pilih-level-education"
          options={top100Films}
          renderInput={(params) => (
            <InputCustom
              containerRef={params.InputProps.ref}
              placeholder="Jenis Lamaran"
              title="Jenis Lamaran"
              inputProps={params.inputProps}
              fullWidth
              // error={errors?.level ? true : false}
              // helperText={errors?.schoolName}
            />
          )}
        />
        <InputCustom
          title="Pesan"
          placeholder="Pesan"
          id="select-page"
          multiline
          // value={page}
          // onChange={handleChange}
          variant="outlined"
          // SelectProps={{
          //   renderValue: (value) => {
          //     console.log(value)
          //     return value;
          //   }
          // }}
          style={{
            minHeight: "155px",
          }}
        />
        <Button
          fullWidth
          color="primary"
          size="large"
          variant="contained"
          startIcon={<SaveOutlined />}
          disableElevation
          sx={{ textTransform: "none", marginTop: "30px", fontWeight: "bold" }}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            // window?.mixpanel?.track("Lowongan kerja home cover letter Button Clicked", {
            //   userId: user?.uid,
            //   tempUID: user?.tempUID,
            // })
            // history.push("/cover-letter");
            setOpen(true);
          }}
        >
          SIMPAN
        </Button>
      </Box>

      <AlertDialog
        title="Data Telah Tersimpan"
        type="ok"
        open={open}
        handleClose={() => setOpen(false)}
        handleYes={() => {
          setOpen(false);
          navigate("/cover-letter");
        }}
      />
    </>
  );
}

export default CoverLetterCreate;
