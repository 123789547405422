import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react"
import { firestore, storage } from "../../contexts/FirebaseProvider";
import "./styles/style.css";

export default function Preview({ jobId, }) {
    const [preview, setPreview] = useState(false);

    // get data email settings
    const settEmailDoc = doc(firestore, "jobs", jobId ? jobId : 'default')
    const { data: item } = useFirestoreDocumentData(
        ['jobs', jobId],
        settEmailDoc,
        {
            subscribe: true,
            idField: "id",
        }
    );
    const [urlPhoto, setUrlPhoto] = useState()
    useEffect(() => {
        async function getFile() {
            try {
                if (item?.name_file) {
                    const pathReference = await getDownloadURL(ref(storage, item?.name_file));
                    // console.log(pathReference)
                    setUrlPhoto(pathReference)
                }
            } catch (e) {
                console.log(e.message)
            }
        }
        getFile()
    }, [item?.name_file])
    // console.log(window.devicePixelRatio)
    
    return (
        <>
            {/* <Dialog maxWidth="md" open={preview} PaperProps={{ sx: { backgroundColor: "transparent", boxShadow: "none  !important", overflowY: "unset !important" } }}> */}

            <Box
                className="box-position"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: preview ? '-370px' : '0px',
                    "@media(max-width: 1280px)": {
                        marginLeft: preview ? '0px' : '0px',
                    },
                }}
            >

                <Box
                    className="inner-box"
                    sx={{
                        background: "#F3F3F3",
                        borderRadius: "20px 0px 0px 20px",
                        width: preview ? '400px' : '40px',
                        height: '70vh',
                        overflow: 'hidden',
                        flex: 'none',
                        position: "relative",
                        "@media(max-width: 1280px)": {
                            background: preview ? "#F3F3F3" : "#ffffff",
                            width: preview ? '100%' : '100%',
                            height: preview ? "50vh": "60px",
                            borderRadius: "0px",
                        },
                        // display: 'flex',
                        // flexDirection: 'column',
                        // justifyContent: 'center',
                        // alignItems: 'center'
                    }}
                >
                    <Box sx={{
                        padding: '20px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        height: '100%',

                    }}>
                        {preview ? <>
                            {item?.name_file && urlPhoto &&
                                // <Box
                                //     sx={{
                                //         maxHeight: "80vh"
                                //     }}
                                // >
                                <img
                                    className="source-img"
                                    src={urlPhoto}
                                    alt="raw foto"
                                    style={{
                                        maxWidth: "100%",
                                        display: "block",
                                        marginBottom: "20px",
                                        height: 'auto',
                                    }}
                                />
                                // </Box>
                            }
                            {item?.text_caption &&
                                <Typography style={{ wordWrap: "break-word", padding: "15px"}} dangerouslySetInnerHTML={{ __html: item?.text_caption?.replace?.(/\n/g, "<br />") }} component="div" />
                            }
                            <Box 
                            className="close-btn-wrap"
                            sx={{
                                position: "absolute",
                                marginTop: "auto",
                                marginBottom: "auto",
                                height: "auto",
                                left: 0,
                                top: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'

                            }}>
                                <Box 
                                 className="close-btn"
                                sx={{
                                    width: "31px",
                                    height: "85px",
                                    background: "#f3f3f378",
                                    borderRadius: "0px 10px 10px 0px",
                                    left: '16px',
                                    position: 'absolute',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}>
                                    
                                    <IconButton className="white" onClick={() => {

                                        setPreview(false)
                                    }} >
                                        <Typography className="close-text mobile-only">Tutup </Typography>
                                        <KeyboardArrowRight className="desktop-only" />
                                        <KeyboardArrowUp className="mobile-only" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </> :
                            <Box 
                            className="open-btn"
                            sx={{
                                position: "absolute",
                                marginTop: "auto",
                                marginBottom: "auto",
                                height: "auto",
                                left: 0,
                                top: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                
                                <IconButton
                                    onClick={() => {
                                        setPreview(true)
                                    }} >
                                    <Typography className="open-text mobile-only">Lihat Detail Pekerjaan</Typography>
                                    <KeyboardArrowLeft className="desktop-only" />
                                    <KeyboardArrowDown className="mobile-only" />
                                </IconButton>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            {/* </Dialog> */}
        </>
    )
}